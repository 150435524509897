import React, { useState, useEffect } from "react";
import logoImage from '../images/Logo-Base-basic.png';
import Sidebar from '../components/SideBar';
import { Dropdown } from 'react-bootstrap';
import { getAdminOrders, updateOrderStatus } from './orderViewHelper';
import './orderHistory.css';

const AdminNavbar = () => {
  return (
    <div className="navWrapper">
      <div className="AdminNav">
        <div>
          <a href="/" className="NavLink">
            <div className="AdminLogo d-flex justify-content-end">
              <img src={logoImage} alt='logo' className="NavLogo" />
            </div>
            <div className="AdminLogoText font-2 ps-2 fontLogo">mycustomframe</div>
          </a>
        </div>
      </div>
    </div>
  );
};

const OrderHistory = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [statusUpdating, setStatusUpdating] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
    
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await getAdminOrders(selectedStatus, fromDate, toDate);
        
        // Process response based on its structure
        if (response && response.success && Array.isArray(response.data)) {
          setOrders(processOrders(response.data));
        } else if (response && response.data) {
          if (Array.isArray(response.data)) {
            setOrders(processOrders(response.data));
          } else {
            setOrders([processOrder(response.data)]);
          }
        } else if (Array.isArray(response)) {
          setOrders(processOrders(response));
        } else if (response && (response.id || response.finalProductDoc)) {
          setOrders([processOrder(response)]);
        } else {
          setOrders([]);
          setError("Received data is in an unexpected format");
        }
      } catch (err) {
        setError(`An error occurred while fetching orders: ${err.message}`);
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [selectedStatus, fromDate, toDate]);

  // Helper functions
  const processOrders = (ordersArray) => {
    return ordersArray.map(order => processOrder(order));
  };

  const processOrder = (order) => {
    return {
      ...order,
      userAddress: order.userAddress
    };
  };

  const handleResetStatus = () => setSelectedStatus("");
  const handleResetDates = () => {
    setFromDate("");
    setToDate("");
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Not Started": return "status-pending";
      case "Shipped": return "status-shipped";
      case "Delivered": return "status-delivered";
      case "Cancelled": return "status-cancelled";
      default: return "";
    }
  };

  // Handle status updates
  const handleStatusChange = async (orderId, newStatus) => {
    try {
      setStatusUpdating(orderId);
      
      // Call API to update status
      await updateOrderStatus(orderId, newStatus);
      
      // Update the status in local state
      setOrders(orders.map(order => {
        if ((order.id || order.finalProductID) === orderId) {
          return { ...order, status: newStatus };
        }
        return order;
      }));
      
      // Display success feedback
      alert(`Order ${orderId} status updated to ${newStatus}`);
    } catch (error) {
      alert(`Failed to update status: ${error.message}`);
    } finally {
      setStatusUpdating(null);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'No date';
    
    try {
      if (timestamp._seconds) {
        const date = new Date(timestamp._seconds * 1000);
        return date.toLocaleDateString('en-US', {
          year: 'numeric', month: 'short', day: 'numeric'
        });
      } else if (timestamp instanceof Date) {
        return timestamp.toLocaleDateString('en-US', {
          year: 'numeric', month: 'short', day: 'numeric'
        });
      } else if (typeof timestamp === 'string') {
        return new Date(timestamp).toLocaleDateString('en-US', {
          year: 'numeric', month: 'short', day: 'numeric'
        });
      }
      return 'Invalid date format';
    } catch (error) {
      return 'Date error';
    }
  };

  // Calculate total for a single product
  const calculateTotal = (product) => {
    if (!product) return { total: '0.00', breakdown: {} };
    
    try {
      let total = 0;
      const breakdown = {};
      
      if (product.framePrice && parseFloat(product.framePrice) > 0) {
        const framePrice = parseFloat(product.framePrice);
        total += framePrice;
        // Add frame thickness to the frame details
        breakdown.frame = {
          price: framePrice.toFixed(2),
          details: `${product.displayName || product.frameName || product.frameType || 'Custom Frame'} (${product.frameThickness || '-'}″ thick)`
        };
      }
      
      if (product.glassPrice && parseFloat(product.glassPrice) > 0) {
        const glassPrice = parseFloat(product.glassPrice);
        total += glassPrice;
        breakdown.glass = {
          price: glassPrice.toFixed(2),
          details: product.glassType || 'Standard Glass'
        };
      }
      
      if (product.hangPrice && parseFloat(product.hangPrice) > 0) {
        const hangPrice = parseFloat(product.hangPrice);
        total += hangPrice;
        breakdown.hanging = {
          price: hangPrice.toFixed(2),
          details: product.hangingStyle || product.hangingOption || 'Standard Hanging'
        };
      }
      
      if (product.mediumPrice && parseFloat(product.mediumPrice) > 0) {
        const mediumPrice = parseFloat(product.mediumPrice);
        total += mediumPrice;
        breakdown.medium = {
          price: mediumPrice.toFixed(2),
          details: product.medium || 'Photo Print'
        };
      }
      
      // Updated matboard handling to create separate entries for each matboard
      if (product.matboardPrices && Array.isArray(product.matboardPrices)) {
        // Get matboard thickness to add to details
        const matThickness = product.matboardThickness ? `${product.matboardThickness}″  thick` : '';
        
        if (product.selectedMatboards && Array.isArray(product.selectedMatboards)) {
          // Create individual entries for each matboard using the selectedMatboards array
          product.matboardPrices.forEach((price, index) => {
            if ((typeof price === 'number' || typeof price === 'string') && parseFloat(price) > 0) {
              const matPrice = typeof price === 'number' ? price : parseFloat(price);
              total += matPrice;
              
              const matColor = product.selectedMatboards[index]?.displayName || 'Custom Color';
              const matStyle = product.selectedMatboards[index]?.style || '';
              const matNumber = index + 1;
              
              breakdown[`matboard${matNumber}`] = {
                price: matPrice.toFixed(2),
                details: `${matColor} (${matStyle}${matThickness ? ', ' + matThickness : ''})`
              };
            }
          });
        } else if (product.matboardColors && Array.isArray(product.matboardColors)) {
          // Fallback to using matboardColors if available
          product.matboardPrices.forEach((price, index) => {
            if ((typeof price === 'number' || typeof price === 'string') && parseFloat(price) > 0) {
              const matPrice = typeof price === 'number' ? price : parseFloat(price);
              total += matPrice;
              
              const matColor = product.matboardColors[index] || 'Custom Color';
              const matNumber = index + 1;
              
              breakdown[`matboard${matNumber}`] = {
                price: matPrice.toFixed(2),
                details: `${matColor}${matThickness ? ' (' + matThickness + ')' : ''}`
              };
            }
          });
        } else {
          // Fallback if we have prices but no colors
          let matboardTotal = 0;
          product.matboardPrices.forEach(price => {
            if (typeof price === 'number') matboardTotal += price;
            else if (typeof price === 'string') matboardTotal += parseFloat(price);
          });
          
          if (matboardTotal > 0) {
            total += matboardTotal;
            breakdown.matboard = {
              price: matboardTotal.toFixed(2),
              details: `Multiple Matboards (${product.matboardPrices.length})${matThickness ? ' (' + matThickness + ')' : ''}`
            };
          }
        }
      }
      
      return { 
        total: total.toFixed(2),
        breakdown
      };
    } catch (error) {
      console.error("Error calculating product total:", error);
      return { total: '0.00', breakdown: {} };
    }
  };

  // Calculate order total with multiple products
  const calculateOrderTotal = (order) => {
    if (!order) return { total: '0.00', products: [] };
    
    try {
      // Check if order has finalProducts array
      if (order.finalProducts && Array.isArray(order.finalProducts)) {
        let orderTotal = 0;
        const productDetails = [];
        
        // Process each final product
        order.finalProducts.forEach((product, index) => {
          // Get quantity (default to 1 if not specified)
          const quantity = product.quantity || 1;
          
          // Calculate product price
          const priceData = calculateTotal(product.finalProductDoc);
          const productTotal = parseFloat(priceData.total) * quantity;
          orderTotal += productTotal;
          
          // Get the image URL - prioritize uploadedImage
          let imageUrl = null;
          if (product.finalProductDoc) {
            imageUrl = product.finalProductDoc.uploadedImage || 
                      product.finalProductDoc.frameImage ||
                      product.finalProductDoc.imageUrl || 
                      product.finalProductDoc.originalImageUrl;
                      
            // For debugging
            if (imageUrl) {
              //console.log(`Found image URL for product ${index}:`, imageUrl);
            } else {
              console.log(`No image URL found for product ${index}. Available keys:`, 
                Object.keys(product.finalProductDoc));
            }
          }
          
          // Save product details
          productDetails.push({
            id: product.finalProductID || `Product-${index}`,
            title: product.finalProductDoc?.title || 'Custom Frame',
            quantity: quantity,
            priceData: priceData,
            productTotal: productTotal.toFixed(2),
            imageUrl: imageUrl,
            dimensions: {
              width: product.finalProductDoc?.frameSize?.width || 
                     product.finalProductDoc?.width || 
                     product.finalProductDoc?.frameWidth || 'N/A',
              height: product.finalProductDoc?.frameSize?.height || 
                      product.finalProductDoc?.height || 
                      product.finalProductDoc?.frameHeight || 'N/A'
            }
          });
        });
        
        return {
          total: orderTotal.toFixed(2),
          products: productDetails
        };
      } else if (order.finalProductDoc) {
        // Handle single product case
        const priceData = calculateTotal(order.finalProductDoc);
        
        // Get the image URL - prioritize uploadedImage
        let imageUrl = order.finalProductDoc.uploadedImage || 
                       order.finalProductDoc.frameImage ||
                       order.finalProductDoc.imageUrl || 
                       order.finalProductDoc.originalImageUrl;
        
        // For debugging
        if (imageUrl) {
          console.log(`Found image URL for single product:`, imageUrl);
        } else {
          console.log(`No image URL found for single product. Available keys:`, 
            Object.keys(order.finalProductDoc));
        }
        
        return {
          total: priceData.total,
          products: [{
            id: order.finalProductID || 'Single-Product',
            title: order.finalProductDoc?.title || 'Custom Frame',
            quantity: 1,
            priceData: priceData,
            productTotal: priceData.total,
            imageUrl: imageUrl,
            dimensions: {
              width: order.finalProductDoc?.frameSize?.width || 
                     order.finalProductDoc?.width || 
                     order.finalProductDoc?.frameWidth || 'N/A',
              height: order.finalProductDoc?.frameSize?.height || 
                      order.finalProductDoc?.height || 
                      order.finalProductDoc?.frameHeight || 'N/A'
            }
          }]
        };
      }
      
      return { total: '0.00', products: [] };
    } catch (error) {
      console.error("Error calculating order total:", error);
      return { total: '0.00', products: [] };
    }
  };

  // Helper function to handle image download
const handleDownloadImage = (imageUrl, productTitle) => {
  if (!imageUrl) {
    alert('No image available for this product');
    return;
  }
  
  console.log("Attempting to open image in new tab:", imageUrl);
  
  // Check if image URL is valid
  const img = new Image();
  img.onerror = () => {
    console.error("Image failed to load:", imageUrl);
    alert('Unable to open image: The image URL appears to be invalid');
  };
  img.onload = () => {
    try {
      // Open the image in a new tab
      window.open(imageUrl, '_blank');
      console.log("Image opened in new tab:", imageUrl);
    } catch (err) {
      console.error("Open image error:", err);
      alert('Failed to open image in new tab. Please try again.');
    }
  };
  img.src = imageUrl;
};

  const toggleOrderDetails = (orderId, event) => {
    // Prevent triggering when clicking other elements
    if (event.target.classList.contains('expand-arrow') || 
        event.target.classList.contains('expand-icon')) {
      if (expandedOrder === orderId) {
        setExpandedOrder(null);
      } else {
        setExpandedOrder(orderId);
      }
    }
  };

  // Filter orders by email if search query exists
  const filteredOrders = orders.filter(order => 
    searchQuery ? 
      (order.userEmail || '').toLowerCase().includes(searchQuery.toLowerCase()) : 
      true
  );

  // Calculate total pages based on filtered orders
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  // Get current page orders
  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <AdminNavbar />
      <hr className="admin-divider" />
      <div className="order-history-page">
        <Sidebar />
        <div className="order-history-content">
          <div className="header">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="status-dropdown">
                {selectedStatus || "Order Status"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSelectedStatus("Not Started")}>Not Started</Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedStatus("Shipped")}>Shipped</Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedStatus("Delivered")}>Delivered</Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedStatus("Cancelled")}>Cancelled</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {selectedStatus && (
              <button className="reset-btn" onClick={handleResetStatus} aria-label="Reset Status">
                &#x2715;
              </button>
            )}

            <div className="date-filters">
              <label htmlFor="fromDate" className="date-label">From</label>
              <input
                type="date"
                id="fromDate"
                className="date-filter"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <label htmlFor="toDate" className="date-label">To</label>
              <input
                type="date"
                id="toDate"
                className="date-filter"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              {(fromDate || toDate) && (
                <button className="reset-btn" onClick={handleResetDates} aria-label="Reset Dates">
                  &#x2715;
                </button>
              )}
            </div>

            <input
              type="text"
              className="search-box"
              placeholder="Search Email..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="order-table-container">
            {loading ? (
              <div className="loading-text">Loading orders...</div>
            ) : error ? (
              <div className="error-text">{error}</div>
            ) : orders.length === 0 ? (
              <div className="no-data-text">No orders found.</div>
            ) : (
              <table className="order-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>ORDER ID</th>
                    <th>EMAIL</th>
                    <th>ADDRESS</th>
                    <th>PRODUCTS</th>
                    <th>STATUS</th>
                    <th>TOTAL</th>
                    <th>PURCHASE DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedOrders.length > 0 ? (
                    paginatedOrders.map((order, index) => {
                      const orderId = order.id || `Order-${index}`;
                      const orderData = calculateOrderTotal(order);
                      const isExpanded = expandedOrder === orderId;
                      const productCount = order.finalProducts?.length || 1;
                      
                      return (
                        <React.Fragment key={orderId}>
                          <tr className={isExpanded ? "expanded-row" : ""}>
                            <td className="expand-arrow" onClick={(e) => toggleOrderDetails(orderId, e)}>
                              <span className="expand-icon">
                                {isExpanded ? '▼' : '▶'}
                              </span>
                            </td>
                            <td>{orderId}</td>
                            <td>{order.userEmail || 'N/A'}</td>
                            <td>{order.userAddress || 'N/A'}</td>
                            <td>{productCount} item{productCount !== 1 ? 's' : ''}</td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle 
                                  className={`status-badge ${getStatusClass(order.status)}`} 
                                  variant="link"
                                  id={`status-dropdown-${orderId}`}
                                  disabled={statusUpdating === orderId}
                                >
                                  {order.status || 'Unknown'} 
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item 
                                    onClick={() => handleStatusChange(orderId, "Not Started")}
                                    className={order.status === "Not Started" ? "active" : ""}
                                  >
                                    Not Started
                                  </Dropdown.Item>
                                  <Dropdown.Item 
                                    onClick={() => handleStatusChange(orderId, "Shipped")}
                                    className={order.status === "Shipped" ? "active" : ""}
                                  >
                                    Shipped
                                  </Dropdown.Item>
                                  <Dropdown.Item 
                                    onClick={() => handleStatusChange(orderId, "Delivered")}
                                    className={order.status === "Delivered" ? "active" : ""}
                                  >
                                    Delivered
                                  </Dropdown.Item>
                                  <Dropdown.Item 
                                    onClick={() => handleStatusChange(orderId, "Cancelled")}
                                    className={order.status === "Cancelled" ? "active" : ""}
                                  >
                                    Cancelled
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>${orderData.total}</td>
                            <td>{formatDate(order.timeStamp)}</td>
                          </tr>
                          
                          {isExpanded && (
                            <tr className="price-breakdown-row">
                              <td></td>
                              <td colSpan="7">
                                <div className="order-breakdown">
                                  {orderData.products.map((product, productIndex) => (
                                    <div key={product.id} className="product-breakdown">
                                      <div className="product-header">
                                        <h4>Product {productIndex + 1}: {product.title}</h4>
                                        {product.quantity > 1 && (
                                          <p className="quantity-info">Quantity: {product.quantity}</p>
                                        )}
                                      </div>
                                      
                                      <div className="product-details-container">
                                        <div className="product-image-container">
                                          {product.imageUrl ? (
                                            <div className="image-section">
                                              <img 
                                                src={product.imageUrl} 
                                                alt={product.title} 
                                                className="product-image" 
                                                onError={(e) => {
                                                  console.log("Image failed to load:", product.imageUrl);
                                                  e.target.onerror = null; 
                                                  e.target.src = "/placeholder-image.png";
                                                  e.target.style.opacity = "0.5";
                                                }}
                                              />
                                              <button 
                                                className="download-btn"
                                                onClick={() => handleDownloadImage(product.imageUrl, product.title)}
                                              >
                                                Download Image
                                              </button>
                                            </div>
                                          ) : (
                                            <div className="no-image">
                                              <p>No image available</p>
                                            </div>
                                          )}
                                        </div>
                                        
                                        <div className="pricing-section">
                                          <table className="breakdown-table">
                                            <thead>
                                              <tr className="header-row">
                                                <th>Component</th>
                                                <th>Details</th>
                                                <th>Price</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Object.entries(product.priceData.breakdown).map(([component, data], idx) => {
                                                // Format the component name for display
                                                let displayComponent = component;
                                                if (component.startsWith('matboard')) {
                                                  displayComponent = 'Matboard ' + component.replace('matboard', '');
                                                } else {
                                                  displayComponent = component.charAt(0).toUpperCase() + component.slice(1);
                                                }
                                                
                                                return (
                                                  <tr key={idx}>
                                                    <td>{displayComponent}</td>
                                                    <td>{data.details}</td>
                                                    <td>${data.price}</td>
                                                  </tr>
                                                );
                                              })}
                                              <tr className="subtotal-row">
                                                <td>
                                                  <strong style={{ color: '#ff4172' }}>
                                                    {product.quantity > 1 || orderData.products.length > 1 ? 'Product Subtotal' : 'Total'}
                                                  </strong>
                                                </td>
                                                <td></td>
                                                <td>
                                                  <strong style={{ color: '#ff4172' }}>${product.priceData.total}</strong>
                                                </td>
                                              </tr>
                                              {product.quantity > 1 && (
                                                <tr className="product-total-row">
                                                  <td><strong>Quantity × {product.quantity}</strong></td>
                                                  <td></td>
                                                  <td><strong>${product.productTotal}</strong></td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      
                                      {productIndex < orderData.products.length - 1 && (
                                        <hr className="product-divider" />
                                      )}
                                    </div>
                                  ))}
                                  
                                  {orderData.products.length > 1 && (
                                    <div className="order-total">
                                      <h4 style={{ color: '#ff4172' }}>Order Total: ${orderData.total}</h4>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">No orders match your filter criteria</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            {!loading && !error && filteredOrders.length > 0 && (
              <div className="pagination">
                <button
                  className="page-btn"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <span>
                  {`Showing ${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
                    currentPage * itemsPerPage,
                    filteredOrders.length
                  )} of ${filteredOrders.length}`}
                </span>
                <button
                  className="page-btn"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
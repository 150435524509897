import React, { useState, useEffect } from 'react';
import Sidebar from '../components/SideBar';
import '../App.css';
import './artistPage.css';
import { editArtist, deleteArtist, registerArtist } from './artistHelper.js';
import { getCategories, addCategory, getAllArt, delArt, addArt, editArt } from './artHelper.js';
import logoImage from '../images/Logo-Base-basic.png';
import { Table, Modal, Button } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ENDPOINT_PATH } from '../config';




const AdminNavbar = () => {
  return (
    <div className="navWrapper">
    <div className="AdminNav">
        <div>
            <a href = "/" className="NavLink">
                <div className="AdminLogo d-flex justify-content-end">
                    <img src={logoImage} alt='logo' className="NavLogo" />
                </div>
                    <div className="AdminLogoText font-2 ps-2 fontLogo">mycustomframe</div>
                </a>
        </div>
        </div>
        </div>
  );
};


const ArtistPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  
  const [showArtworkProfile, setShowArtworkProfile] = useState(false);

  const itemsPerPage = 10; // Number of items to display per page

  const [artists, setArtists] = useState([]);  
  const [artworks, setArtworks] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch artists data
    const fetchArtists = async () => {
        try {
          const response = await fetch(`${ENDPOINT_PATH}/api/get-artists`);
          const data = await response.json();
          setArtists(data.data); 
        } catch (error) {
          console.error("Error fetching artists:", error);
        }
      };

    // Fetch artwork data
    const fetchArtworks = async () => {
        const data =  await getAllArt();
        if(data && data.data){
          setArtworks(data.data);
        }
      };
    
    // Fetch categories 
    const fetchCategories = async () => {
      const categories = await getCategories();
      if (categories && categories.data)
        {
          setCategories(categories.data);
          console.log("categories",categories.data);
        }
    };

    fetchArtists();
    fetchArtworks();
    fetchCategories();

  }, []); // Empty dependency array ensures this runs only once when the component is mounted
  
  const [editingArtist, setEditingArtist] = useState(null); // Track artist being edited
  const [editingArtwork, setEditingArtwork] = useState(null); // Track artwork being edited

  const filteredArtists = artists.filter(artist =>
    `${artist.firstName} ${artist.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredArtworks = artworks.filter((artwork) =>
    artwork.artworkName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  // Calculate total pages
  const totalPages = Math.ceil(
    (showArtworkProfile ? filteredArtworks.length : filteredArtists.length) / itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleEditArtist = (artist) => {
    setEditingArtist(artist); // Set the artist being edited
    setShowArtistModal(true); // Open the modal
  };

  const handleDeleteArtist = (artist) => {
    if (window.confirm(`Are you sure you want to delete ${artist.firstName} ${artist.lastName}?`)) {
      deleteArtist(artist.firstName, artist.lastName);
      setArtists((prevArtists) => prevArtists.filter((a) => a.id !== artist.id));
    }
  }

  const handleDeleteArtwork = (artwork) => {
    if (window.confirm(`Are you sure you want to delete ${artwork.artworkName}?`)) {
      delArt(artwork.artistDocID,artwork.artDocID);  
      setArtworks((prevArtworks) => prevArtworks.filter((a) => a.artDocID !== artwork.artDocID));
    }
  };

  const handleEditArtwork = (artwork) => {
    setEditingArtwork(artwork); // Set the artwork being edited
    setShowArtworkModal(true); // Open the modal
  };

  const handleImageUpload = (e, setImageState, setFileState) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageState(reader.result);
      };
      reader.readAsDataURL(file);
      setFileState(file);
    }
  };

  const [showArtistModal, setShowArtistModal] = useState(false);

  const ArtistProfileModal = ({handleImageUpload}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [bio, setBio] = useState('');
    // image url
    const [uploadedImage, setUploadedImage] = useState(null);
    // file
    const [uploadedFile, setUploadedFile] = useState(null);
    const [initalArtist, setInitalArtist] = useState({});

    const handlePhoneChange = (e) => {
      let rawValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      if (rawValue.length > 10) rawValue = rawValue.slice(0, 10); // Limit to 10 digits
    
      // Format to (XXX)XXX-XXXX
      const formattedValue = rawValue.replace(
        /^(\d{3})(\d{0,3})(\d{0,4})$/,
        (_, areaCode, prefix, lineNumber) =>
          `(${areaCode}) ${prefix}${lineNumber ? `-${lineNumber}` : ''}`
      );
    
      setPhoneNumber(formattedValue);
    };

    useEffect(() => {

      if (editingArtist) {
        setFirstName(editingArtist.firstName || '');
        setLastName(editingArtist.lastName || '');
        setEmail(editingArtist.email || '');
        setPhoneNumber(editingArtist.phoneNumber || '');
        setBio(editingArtist.artistBio || ''); 
        setUploadedImage(editingArtist.artistPictureURL || null);
        setInitalArtist(editingArtist);
      }
      
    }, []);

    const detectChanges = (inital, updated) => {
        let changes = {};
        let profilePictureFile = null;

        if (inital.artistBio !== updated.artistBio) changes.artistBio = updated.artistBio;
        if (inital.email !== updated.email) changes.email = updated.email;
        if (inital.firstName !== updated.firstName) changes.firstName = updated.firstName;
        if (inital.lastName !== updated.lastName) changes.lastName = updated.lastName;
        if (inital.phoneNumber !== updated.phoneNumber) changes.phoneNumber = updated.phoneNumber;
        if(inital.artistPictureURL !== updated.artistPictureURL)
          {
            profilePictureFile = uploadedFile;
          }
        return ({changes, profilePictureFile});
      };

    const handleClose = () => {
      setShowArtistModal(false);
      setUploadedImage(null); // Reset uploaded image
      setUploadedFile(null);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setBio('');
    };

    const handleSave = (e) => {
      e.preventDefault();

      if (!firstName || !lastName || !email || !phoneNumber) {
        alert('Please fill out all fields.');
        return;
      }

      const form = document.getElementById('artistForm');
      // Check form validity
      if (!form.checkValidity()) {
        form.reportValidity(); 
        return;
      }

      const updatedArtist = {
        id: editingArtist ? editingArtist.id : Date.now(),
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        artistBio: bio,
        artistPictureURL: uploadedImage
      };

      if (editingArtist) {
        const {changes, profilePictureFile} = detectChanges(initalArtist, updatedArtist);
      
        //API CALL
        editArtist(updatedArtist.id,changes,profilePictureFile);

        // update ui
        setArtists((prevArtists) =>
          prevArtists.map((artist) =>
            artist.id === updatedArtist.id ? updatedArtist : artist
          )
        );
        setEditingArtist(null); // Clear editing state 
      } else {
        //API CALL
        registerArtist(bio, uploadedFile, email, firstName, lastName, phoneNumber);

        setArtists((prevArtists) => [...prevArtists, updatedArtist]);  
      }
      setShowArtistModal(false);
    };

    return (
      <Modal show={showArtistModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{editingArtist ? 'Artist Profile' : 'New Artist Profile'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* Left Side: Form */}
            <div className="col-md-6">
              <form id="artistForm">
                <div className="form-row">
                  {/*First Name */}
                  <div className="form-group col-md-6">
                    <label>First Name <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>

                   {/*Last Name */}
                  <div className="form-group col-md-6">
                    <label>Last Name <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                
                {/*Email*/}
                </div>
                <div className="form-group">
                  <label>Email <span className="text-danger">*</span></label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                
                {/*Phone Number*/}
                <div className="form-group">
                  <label>Phone Number <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="(123)123-1234"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    pattern="^\(\d{3}\) \d{3}-\d{4}$"
                    required
                  />
                </div>

                {/*Bio*/}
                <div className="form-group">
                  <label>Artist Bio</label>
                  <textarea
                    className="form-control"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </div>
              </form>
            </div>
    
            {/* Right Side: Upload Profile Picture */}
            <div className="col-md-6 d-flex flex-column align-items-center">
              <label>Artist Profile Picture</label>
              <div className="placeholder-pfp">
              {uploadedImage ? (
                <img
                  src={uploadedImage}
                  alt="Uploaded"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  />
                ) : ( <span ></span> )}
              </div>
              <label className="btn btn-primary mb-2" style={{ cursor: 'pointer' }}>
              Upload Picture
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, setUploadedImage, setUploadedFile)}
                style={{ display: 'none' }} 
              />
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
    
  };

  const [showArtworkModal, setShowArtworkModal] = useState(false);

  const ArtworkModal = ({handleImageUpload}) => {
    const [artworkName, setArtworkName] = useState('');
    const [selectedArtist, setSelectedArtist] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    // image url
    const [uploadedArtwork, setUploadedArtwork] = useState(null);
    // file
    const [uploadedFile, setUploadedFile] = useState(null);

    const [initialArtwork, setInitalArtwork] = useState({});

    const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
    const [newCategory, setNewCategory] = useState("");

    const handleCategoryChange = (e) => {
      const value = e.target.value;
      if (value === "New Category") {
        setSelectedCategory(value);
        setShowNewCategoryInput(true);
      } else {
        setShowNewCategoryInput(false);
        setSelectedCategory(value);
      }
    };

    useEffect(()=>{
      if (editingArtwork) {
        setArtworkName(editingArtwork.artworkName || ''); 
        setSelectedArtist(editingArtwork.artistName || ''); 
        setSelectedCategory(editingArtwork.category || ''); 
        setPrice(editingArtwork.price || ''); 
        setDescription(editingArtwork.artDescription || ''); 
        setUploadedArtwork(editingArtwork.artImageUrl || null);

        setInitalArtwork(editingArtwork);
    
      }
    }, []);

    const detectChanges = (initial, updated) => {
      let changes = {};
      let imageFile = null;
      let selectedArtist = null;
    
      if (initial.artworkName !== updated.artworkName) changes.artworkName = updated.artworkName;
      if (initial.price !== updated.price) changes.price = updated.price;
      if (initial.category !== updated.category) changes.category = updated.category;
      if (initial.artDescription !== updated.artDescription) changes.artDescription = updated.artDescription;

      // check if artist changed
      if (initial.artistName !== updated.artistName) 
        {
          selectedArtist = updated.artistName
        }
      
      // Check if the image has changed
      if (initial.artImageUrl !== updated.artImageUrl) {
        imageFile = uploadedFile; // Assuming uploadedArtwork contains the new image file or URL
      }
    
      return {changes, selectedArtist, imageFile};
    };


    const handleSaveArtwork = (e) => {
      e.preventDefault();

      if (!artworkName || !selectedArtist || !price || !uploadedArtwork) {
        alert('Please fill out all fields.');
        return;
      }

      const form = document.getElementById('artworkForm');
      // Check form validity
      if (!form.checkValidity()) {
        form.reportValidity(); 
        return;
      }

      if (newCategory && showNewCategoryInput)
        {
          // API Call 
          addCategory(newCategory);
          setSelectedCategory(newCategory);
          setCategories((prevCategories) => [...prevCategories,{category: newCategory }]); 
        }
      
     // Create updated artwork object
     const updatedArtwork = {
      artDocID: editingArtwork ? editingArtwork.artDocID : Date.now(),
      artistDocID: editingArtwork ? editingArtwork.artistDocID : Date.now(),
      artworkName: artworkName,
      artistName: selectedArtist, 
      price: price,
      category: selectedCategory,
      artDescription: description,
      artImageUrl: uploadedArtwork, // Assuming `uploadedArtwork` contains image URL or file path
    };

      if (editingArtwork) {
        const {changes, selectedArtist, imageFile} = detectChanges(initialArtwork, updatedArtwork);
        console.log(updatedArtwork);
        //API CALL
        editArt(updatedArtwork.artDocID,updatedArtwork.artistDocID,selectedArtist, imageFile, changes);

        // update ui
        setArtworks((prevArtwork) =>
          prevArtwork.map((artwork) =>
            artwork.artDocID === updatedArtwork.artDocID ? updatedArtwork : artwork
          )
        );
        setEditingArtwork(null); // Clear editing state 
      } else {
        //API CALL
        addArt(artworkName, selectedArtist, selectedCategory, price, description, uploadedFile);

        setArtworks((prevArtwork) => [...prevArtwork, updatedArtwork]);  
        console.log(updatedArtwork);
      }
      setShowArtworkModal(false);
    };
    
    
    const handleCloseArtworkModal = () => {
      setShowArtworkModal(false);
      setUploadedArtwork(null); 
      setUploadedFile(null);
      setArtworkName('');
      setSelectedArtist('');
      setSelectedCategory('');
      setPrice('');
      setDescription('');
      setShowNewCategoryInput(false);
      setNewCategory("");
    };

    

    return(
      <Modal show={showArtworkModal} onHide={handleCloseArtworkModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{editingArtwork ? 'Artwork' : 'New Artwork'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* Left Side */}
            <div className="col-md-6">
              <form id="artworkForm">
                {/*Artwork Name*/}
                <div className="form-group">
                  <label>Artwork Name <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className ="form-control"
                    value={artworkName}
                    onChange={(e)=> setArtworkName(e.target.value)}
                    required
                  />
                </div>

                {/*Select Artist*/}
                <div className="form-group">
                  <label>Select Artist <span className="text-danger">*</span></label>
                  <select
                  className="form-select"
                  value={selectedArtist}
                  onChange={(e) => setSelectedArtist(e.target.value)}
                  required
                  >
                    <option></option>
                    {artists.map((artist) => (
                    <option key={artist.id} value={`${artist.firstName} ${artist.lastName}`}>
                      {`${artist.firstName} ${artist.lastName}`}
                    </option>  
                    ))}
                  </select>
                </div>
                
                {/*Select Category*/}
                <div className="form-group">
                  <label>Select Category</label>
                  <select
                  className="form-select"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  >
                    <option value="" disabled>Select a category</option>
                    <option value="New Category">New Category</option>
                    {categories.map((item,index) => (
                    <option key={index} value={item.category}>
                      {item.category}
                    </option>
                    ))}
                  </select>
                </div>

                {/*If New Category was selected...*/}
                {showNewCategoryInput && (
                  <div className="form-group">
                    <label>New Category <span className="text-danger">*</span></label>
                    <input
                    type="text"
                    className ="form-control"
                    value={newCategory}
                    onChange={(e)=> {setNewCategory(e.target.value); setSelectedCategory(e.target.value)}}
                    required
                  />
                  </div>
                )}

                {/*Price*/}
                <div className="col-5">
                <label>Price <span className="text-danger">*</span></label>
                  <div className="input-group mb-3">
                    <span className="input-group-text ">$</span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="0.00"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    />
                  </div>
                </div>

                {/*Description*/}
                <div className="form-group">
                  <label>Artwork Description</label>
                  <textarea
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </form>
            </div>

            {/* Right Side: Upload Artwork */}
            <div className="col-md-6 d-flex flex-column align-items-center">
            <label> Artwork <span className="text-danger">*</span></label>
              <div className="placeholder-artwork">
              {uploadedArtwork ? (
                <img
                  src={uploadedArtwork}
                  alt="Uploaded"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  />
                ) : ( <span ></span> )}
              </div>
              <label className="btn btn-primary mb-2" style={{ cursor: 'pointer' }}>
              Upload Artwork
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, setUploadedArtwork, setUploadedFile)}
                style={{ display: 'none' }} 
                required
              />
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseArtworkModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveArtwork}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

 
  return (
    <div>
      <AdminNavbar />
      <hr className="admin-divider" />
      <div className="artist-page">
        <Sidebar />
        <div className="artist-content">
          <div className="header">
            
            <button className= {`btn ${showArtworkProfile === false ? 'active' : ''}`} 
            onClick={() => { setShowArtworkProfile(false); }}>Artist Profile</button>
            <button className= {`btn ${showArtworkProfile === true  ? 'active' : ''}`}
            onClick={() => { setShowArtworkProfile(true); }}>Artwork Profile</button>
          
            <input
              type="text"
              className="search-box"
              placeholder="Search Artist or Artwork..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />

            <button className= "btn" onClick={() => { setEditingArtist(null); setShowArtistModal(true); }}>Add New Artist</button>
            <button className="btn" onClick={() => { setEditingArtwork(null); setShowArtworkModal(true); }}>Add New Artwork</button>
    
        </div>

          {/* Conditional Rendering: Show either Artist Profile or Artwork Profile */}
          {showArtworkProfile ? (
          <div className="artwork-profile-content">
            <Table striped bordered hover className='artist-table'>
              <thead>
                <tr>
                  <th style={{ width: '200px', textAlign: 'center' }}>IMAGE</th>
                  <th>ARTWORK NAME</th>
                  <th>ARTIST</th>
                  <th>PRICE</th>
                  <th>CATEGORY</th>
                  <th>DESCRIPTION</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {filteredArtworks
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    .map((artwork) =>  (
                  <tr key={artwork.artDocID}>
                    <td style={{ width: '200px', textAlign: 'center' }}>
                      <img src={artwork.artImageUrl} alt={artwork.artworkName} className="artwork-image" />
                    </td>
                    <td>{artwork.artworkName}</td>
                    <td>{artwork.artistName}</td>
                    <td>${artwork.price}</td>
                    <td>{artwork.category}</td>
                    <td style={{ maxWidth: '250px', whiteSpace: 'normal', wordWrap: 'break-word' }}>{artwork.artDescription}</td>
                    <td>
                    <div className="d-flex align-items-center gap-2">
                            <button className="icon-button" onClick={() => handleEditArtwork(artwork)}>
                            <EditIcon style={{ color: 'black' }} />
                             </button>
                            <button className="icon-button" onClick={() => handleDeleteArtwork(artwork)}>
                              <DeleteIcon className="delete-icon" />
                            </button>
                          </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
            <div className="artist-table-container">
              <Table striped bordered hover className='artist-table'>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>PHONE NUMBER</th>
                    <th>EMAIL</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredArtists
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    .map((artist) => (
                      <tr key={artist.id}>
                        <td>{`${artist.firstName} ${artist.lastName}`}</td>
                        <td>{artist.phoneNumber}</td>
                        <td>{artist.email}</td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="icon-button" onClick={() => handleEditArtist(artist)}>
                            <EditIcon style={{ color: 'black' }} />                            </button>
                            <button className="icon-button" onClick={() => handleDeleteArtist(artist)}>
                              <DeleteIcon className="delete-icon" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}

          {/* Pagination Controls */}
          <div className="pagination">
            <button
              className="page-btn"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <span>
              {`Showing ${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
                currentPage * itemsPerPage,
                showArtworkProfile ? filteredArtworks.length : filteredArtists.length
              )} of ${showArtworkProfile ? filteredArtworks.length : filteredArtists.length}`}
            </span>
            <button
              className="page-btn"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Modal for New Artist Profile */}
      <ArtistProfileModal handleImageUpload={handleImageUpload} />
      {/* Modal for New Artwork */}
      <ArtworkModal handleImageUpload={handleImageUpload} />
    </div>
  );

};
export default ArtistPage;

import { ENDPOINT_PATH } from '../config';

/**
 * Helper function for registerArtist()
 * @param {*} artistProfilePicture - Image file
 * @returns URL of the image file gotten by api/upload-image
 */
const uploadArtistProfilePicture = async (artistProfilePicture) => {
    if (!(artistProfilePicture instanceof File)) {
        console.error("Invalid file provided.");
        return null;
    }
    try {
        const formData = new FormData();
        formData.append('file', artistProfilePicture);
        formData.append('folder', 'artistProfilePics/');

        const response = await fetch(ENDPOINT_PATH + '/api/upload-image', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error uploading image: ${response.statusText}`);
        }

        // Read the response as plain text which is the URL
        const imageUrl = await response.text(); 
        return imageUrl;
    } catch (error) {
        console.error("Image upload failed:", error);
        return null;
    }
}

/**
 * @param {*} artistBio - String description of artist 
 * @param {*} artistProfilePicture - File object of artist profile picture
 * @param {*} email - String of artist email
 * @param {*} firstName - String of artist first name
 * @param {*} lastName - String of artist last name
 * @param {*} phoneNumber - String of artist phone number
 */
const registerArtist = async (artistBio, artistProfilePicture, email, firstName, lastName, phoneNumber) => {
    const artistPictureURL = artistProfilePicture != null ? JSON.parse(await uploadArtistProfilePicture(artistProfilePicture)) : null;
    fetch(ENDPOINT_PATH + `/api/register-artist`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',  
        },
        body: JSON.stringify({
           artistBio: artistBio ?? "",
           artistPictureURL: artistPictureURL ?? "",
           email: email,
           firstName: firstName,
           lastName: lastName,
           phoneNumber: phoneNumber
        }),
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Artist registered: " + data);
    })
    .catch(e => { console.error(e); });        
}

/**
 * @param {*} artistDocId - String of an artist-doc's id
 */
const getArtistData = async(artistDocId) => {
    return fetch(ENDPOINT_PATH + `/api/get-artist-data?artistDocId=${artistDocId}`)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error status ${response.status}`);
            }
            return response.json();
        })
        .catch (error => {
            console.error("Error fetching artist data: ", error);
            return null;
        });
}

/**
 * @param {*} firstName - String of artist first name
 * @param {*} lastName - String of artist last name
 */
const deleteArtist = async (firstName, lastName) => {

    fetch(ENDPOINT_PATH + `/api/del-artist`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
        }),
    })
    .then(response => response.json())
    .then(data => {
        console.log('Successfully deleted artist:', data);
    })
    .catch(e => { console.error(e); })
};

/**
 * @param {*} id - User id for firestore db document
 * @param {*} update - An object that must contain a subset of fields of the exact name as the firestore db document. The exception is that if the profile picture of the artist 
 *                     is to change, the file must be passed in separately and named uploadedFile
 *                      - artistBio : ""
 *                      - email: ""
 *                      - firstName: ""
 *                      - lastName: ""
 *                      - phoneNumber: ""
 *                      - uploadedFile: File object
 */
const editArtist = async (id, update, uploadedFile) => {

    const formData = new FormData();
        formData.append('id', id);
        formData.append('update', JSON.stringify(update));
        formData.append('uploadedFile', uploadedFile);

    fetch(ENDPOINT_PATH + `/api/edit-artist`, {
        method: 'PATCH',
        body: formData,
    })
    .then(response => response.json())
    .then(data => {
        console.log('Successfully edited artist:', data);
    })
    .catch(e => { console.error(e); })
};

export { registerArtist, getArtistData, deleteArtist, editArtist };
import { useState, useEffect, React } from 'react';
import Footer from '../components/Footer';
import '../App.css';
import './account.css';
import { getUserOrderHistory } from './orderViewHelper';
import ToolPreview from '../components/visualizationTool/toolPreview';

import { 
    updateProfile,
    updateEmail,
    getAuth,
    updatePassword,
    sendPasswordResetEmail,
    reauthenticateWithCredential,
    EmailAuthProvider
} from "firebase/auth";
import { ENDPOINT_PATH } from '../config';

// Material UI components
import TextField from '@mui/material/TextField';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Account = (props) => {

     const [value, setValue] = useState('1'); // To control the active tab
     const [orders, setOrders] = useState([]);

    const user = JSON.parse(localStorage.getItem('user'));

    if (user == null) {
        window.location.href = './';
    }
    
    const [currentSection, setCurrentStep] = useState("Dashboard");
    const [userProfileInfo, setUserProfileInfo] = useState({firstName: "", lastName: "", email: ""});

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState(' ');
    const [newPassword, setNewPassword] = useState(' ');
    const [confirmNewPassword, setConfirmNewPassword] = useState(' ');

    // Format checkers
    const [goodEmail, setGoodEmail] = useState(true);
    const [goodFirstName, setGoodFirstName] = useState(true);
    const [goodLastName, setGoodLastName] = useState(true);
    const [goodCurrentPassword, setGoodCurrentPassword] = useState(true);
    const [goodNewPassword, setGoodNewPassword] = useState(true);
    const [goodConfirmNewPassword, setGoodConfirmNewPassword] = useState(true);

    const [storedUser, setStoredUser] = useState(null);

    // Get the user info to fill in the default values of the forms
    useEffect(() => {
        const getUserInformation = () => {
            fetch(ENDPOINT_PATH + `/api/get-user-info?user=` + encodeURIComponent(user.uid))
            .then(response => response.json())
            .then(data => {
                setUserProfileInfo({ firstName: data.firstName, lastName: data.lastName, email: data.email });
            })
            .catch(e => { console.error(e); });
        }

        getUserInformation();

        setStoredUser(getAuth().currentUser);
        //console.log(storedUserest);

        console.log("--------------------------");
        console.log(storedUser);
        console.log("--------------------------");
        console.log(user);
    }, []);


    // Get user order history
    useEffect(() => {
        const getOrderHistory = async () => {
            
            const data = await getUserOrderHistory(user.uid);
            if ( data && data.orderHistory)
                {
                    const orderList = await Promise.all(
                        data.orderHistory.map(async (order) => {
                            const finalProducts = await Promise.all(
                                order.finalProducts.map(async (product) => {
                                    const finalProduct = await getFinalProduct(product.finalProductID);
                                    return {
                                        ...product, 
                                        finalProduct
                                    };
                                })
                            );
                            return {
                                ...order,
                                finalProducts
                            };
                        })
                    );
                    // reverse to show latest orders first
                    setOrders(orderList.reverse());
                }
        }

        getOrderHistory();
    },[]);


    const ordersPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(orders.length / ordersPerPage);

     const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
          setCurrentPage(pageNumber);
        }
      };

    const currentOrders = orders.length > 0 ? orders.slice(
        (currentPage - 1) * ordersPerPage,
        currentPage * ordersPerPage
      ) : [];

      
    useEffect(() => {
        if(hasNumbers(firstName)) {
            // invalid email
            console.log("bad first name");
            setGoodFirstName(false);
        } 
        else {
            // valid email
            console.log("good first name");
            setGoodFirstName(true);
        }
    }, [firstName]);

    useEffect(() => {
        if(hasNumbers(lastName)) {
            // invalid email
            console.log("bad last name");
            setGoodLastName(false);
        } 
        else {
            // valid email
            console.log("good last name");
            setGoodLastName(true);
        }
    }, [lastName]);

    useEffect(() => {
        if(newPassword === confirmNewPassword) {
            // valid email
            console.log("New passwords do match");
            setGoodNewPassword(true);
            setGoodConfirmNewPassword(true);
        } 
        else {
            // invalid email
            console.log("New passwords don't match");
            setGoodNewPassword(false);
            setGoodConfirmNewPassword(false);
        }
    }, [newPassword, confirmNewPassword]);


    // Profile Update Functions

    const updateUserEmail = async() => {
        console.log("test")
        // // Update the email in the user side - (This is different from the collection)
        // await updateEmail(user, email).then(() => {
        //     console.log("Email updated");
        //     // Email updated!
        //     // ...
        // }).catch((error) => {
        //     console.error("Error on updating Email");
        //     // An error occurred
        //     // ...
        // });
        // // Update the email on the collection side
        // fetch(ENDPOINT_PATH + `/api/user-update-name?email=` + encodeURIComponent(email) + `&user=` + encodeURIComponent(user.uid),{
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': "application/json; charset=UTF-8", // <-- Specifying the Content-Type
        //     },
        // })
        // .then(response => response.json()).then(data => { console.log(data); })
        // .catch(e => { console.error(e); });
    }
    
    const updateUserPassword = async () => {
        
        // If check if the current password matches the one in the database
        const credential = EmailAuthProvider.credential(user.email, currentPassword);

        const correctPasswordCheck = await reauthenticateWithCredential(storedUser, credential)
        .then(() => { 
            console.log("Correct Password"); 
            return true;
        })
        .catch((error) => { 
            console.error("Incorrect Password: " + error); 
            return false; 
        });

        if(correctPasswordCheck === true) {
            // Check the new password
            // If it fits the correct formating
            setGoodCurrentPassword(true);
            if(passwordFormatCheck()) {
                setGoodConfirmNewPassword(true);
                // Update the password
                //await updatePassword(storedUser, newPassword)
                //.then(() => { console.log("Password has been changed") })
                //.catch((error) => { console.error("Error on password change" + error) });

                // Clear the entries in the forms
                setCurrentPassword("");
                setNewPassword("");
                setConfirmNewPassword("");
            }
            else {
                // If it doesn't meet the correct formatting
                setGoodNewPassword(false);
            }
        }
        else {
            // If it doesn't send an error to the current password text field
            console.log("Wrong password");
            setGoodCurrentPassword(false);

        }
    }

    const updateUserName = async () => {
        // console.log(storedUser);
        // Update the name in the user side - (This is different from the collection)
        await updateProfile(storedUser, { displayName: firstName.concat(" ", lastName) })
        .then(() => { 
            //console.log("Name updated") 
        })
        .catch((error) => { 
            //console.error("Error on name change: " + error) 
        });

        // Update the name on the collection side
        fetch(ENDPOINT_PATH + `/api/user-update-name?firstName=` + encodeURIComponent(firstName) + `&lastName=` 
        + encodeURIComponent(lastName) + `&user=` + encodeURIComponent(user.uid),
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json; charset=UTF-8", // <-- Specifying the Content-Type
            },
        })
        .then(response => response.json()).then(data => { 
            //console.log(data); 
        })
        .catch(e => { console.error(e); });
        // Either reload the page or reload the text fields
        //window.location.reload();
    }

    // Helper Functions
    const hasNumbers = (str) => {
        return /\d/.test(str);
    };

    const handleChange = (event, newValue) => {
        setCurrentStep(newValue);
    };

    const emailFormatCheck = () => {
        let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        if(!emailRegex.test(email)) {
            // invalid email
            console.log("bad email");
            setGoodEmail(false);
        } 
        else {
            // valid email
            console.log("good email");
            setGoodEmail(true);
        }
    }

    const hasUpperCase = (str) => {
        return /[A-Z]/.test(str);
    }
    const hasSpecialCharacter = (str) => {
        return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
    }

    const passwordFormatCheck = () => {
        // Rules for password -
        // Must be at least 10 characters long
        // Must contain one uppercase letter
        // Must contain at least one number and special character
        return (hasUpperCase(newPassword) && hasSpecialCharacter(newPassword) && hasNumbers(newPassword));

        // if(hasUpperCase(newPassword) && hasSpecialCharacter(newPassword) && hasNumbers(newPassword)) {
        //     console.log("Password contains all the necessary parts");
        //     return true;
        // }
        // else {
        //     console.log("Password contains all the necessary parts");

        //     return false;
        // }
    }

    const getFinalProduct = async (finalProductID) => 
        {
            try {
                const response = await fetch(`${ENDPOINT_PATH}/api/final-product?finalProductID=${encodeURIComponent(finalProductID)}`)
                const data = await response.json();
                return data;
            }
            catch (error) {
                console.error("Error fetching final product:", error);
            }
        }

    const getSubtotal = (finalProduct) =>
        {
            if (!finalProduct) return 0;
            const price = finalProduct.framePrice + finalProduct.glassPrice 
            + finalProduct.hangPrice + finalProduct.matboardPrices[0] + finalProduct.matboardPrices[1] 
            + finalProduct.matboardPrices[2] + finalProduct.mediumPrice

            return price;
        }

        function getOrderSubtotal(order) {
            return order.finalProducts.reduce((total, product) => {
                return total + getSubtotal(product.finalProduct);
            }, 0); 
        }


    return (
        <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
            <div className="d-flex align-items-center SubHeaderImage SmallestHeader vstack mb-4" />
            <div className="d-flex justify-content-center pt-4 sectionTitle-Light">account settings</div>

            <Box className="pt-5" sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 150 }} >
                <TabContext value={currentSection} >
                    <div className="wrapperPadding">
                        <TabList
                            orientation="vertical"
                            value={currentSection}
                            onChange={handleChange}
                            sx={{ borderRight: 1, borderColor: 'divider', width:200 }}
                            >
                            <Tab label="Dashboard" value="Dashboard" className="py-4"/>
                            <Tab label="Account Settings" value="Account Settings" className="py-4"/>
                        </TabList>
                    </div>

                    <div className="ps-5">

                    <TabPanel value="Dashboard">
                            {/* Dashboard content with Accordion */}
                            <div>
                                <h3>Your Orders</h3>
                                {currentOrders.length === 0 && 
                                (<p style={{marginRight: '30px', marginTop: '20px'}}>
                                    No order information is available to display.</p>)}
                                {console.log("orders",currentOrders)}   
                                {currentOrders.length > 0 && currentOrders.map((order, index) => (
                                    <Accordion key={index}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel-${index}-content`}
                                            id={`panel-${index}-header`}
                                        >
                                            <div className="accordion-summary">
                                                <div className="frame-id">
                                                    <span>{order.finalProducts.length === 1 ? 
                                                    order.finalProducts[0].finalProduct.title 
                                                    : `Order with ${order.finalProducts.length} Custom Frames`}</span>
                                                </div>
                                                <div className="order-placed">
                                                    <div>ORDER PLACED:</div>
                                                    <div>{order.timeStamp}</div>
                                                </div>
                                                <div className="total">
                                                    <div>Total:</div>
                                                    <div>${order.totalPayment}</div>
                                                </div>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                        <div className="orderDetails">
                                            {order.finalProducts.map((product, index) => (
                                                <div key={index} className="productRow">
                                                    <div className="orderImage">
                                                        <ToolPreview
                                                            uploadedImage={product.finalProduct.uploadedImage}
                                                            frameOrientation={product.finalProduct.frameOrientation}
                                                            frame={product.finalProduct.frameImage}
                                                            matboards={{
                                                                thickness: product.finalProduct.matboardThickness, 
                                                                colors: [
                                                                    product.finalProduct.selectedMatboards[0].hexColor,
                                                                    product.finalProduct.selectedMatboards[1].hexColor,
                                                                    product.finalProduct.selectedMatboards[2].hexColor
                                                                ]
                                                            }}
                                                            frameThickness={product.finalProduct.frameThickness}
                                                            frameSize={product.finalProduct.frameSize}
                                                            frameImageSize={product.finalProduct.frameImageSize}
                                                            matboardNum={product.finalProduct.matboardNum}
                                                            sameSize={false}
                                                            scaleTool={false}
                                                            frameName={product.finalProduct.selectedFrame}
                                                        />
                                                    </div>
                                                            
                                                    <div className='orderInfo'>
                                                        <p>{product.finalProduct.imageSize.width} x {product.finalProduct.imageSize.height} {" "}
                                                            {product.finalProduct.medium}</p>
                                                        <p>{`${product.finalProduct.displayName} Frame (${product.finalProduct.selectedFrame})`}</p>
                                                        {product.finalProduct.glassType !== "None" && (
                                                            <p>{product.finalProduct.glassType} Glass</p>
                                                        )}
                                                        {product.finalProduct.hangingStyle !== "None" && (
                                                            <p>{product.finalProduct.hangingStyle} Hanging Style</p>
                                                        )}
                                                        {product.finalProduct.matboardPrices[0] > 0 && (
                                                            <p>{product.finalProduct.selectedMatboards[0].displayName} Matboard</p>
                                                        )}
                                                        {product.finalProduct.matboardPrices[1] > 0 && (
                                                            <p>{product.finalProduct.selectedMatboards[1].displayName} Matboard</p>
                                                        )}
                                                        {product.finalProduct.matboardPrices[2] > 0 && (
                                                            <p>{product.finalProduct.selectedMatboards[2].displayName} Matboard</p>
                                                        )}
                                                        <hr className='total-divider'></hr>
                                                    </div>

                                                    <div className="orderPrice">
                                                        <p>${product.finalProduct.mediumPrice.toFixed(2)}</p> 
                                                        <p>${product.finalProduct.framePrice.toFixed(2)}</p> 
                                                        {product.finalProduct.glassType !== "None" && (
                                                            <p>${product.finalProduct.glassPrice.toFixed(2)}</p>
                                                        )}
                                                        {product.finalProduct.hangingStyle !== "None" && (
                                                            <p>${product.finalProduct.hangPrice.toFixed(2)}</p>
                                                        )}
                                                        {product.finalProduct.matboardPrices[0] > 0 && (
                                                            <p>${product.finalProduct.matboardPrices[0].toFixed(2)}</p>
                                                        )}
                                                        {product.finalProduct.matboardPrices[1] > 0 && (
                                                            <p>${product.finalProduct.matboardPrices[1].toFixed(2)}</p>
                                                        )}
                                                        {product.finalProduct.matboardPrices[2] > 0 && (
                                                            <p>${product.finalProduct.matboardPrices[2].toFixed(2)}</p>
                                                        )}
                                                        <hr className='total-divider'></hr>
                                                        <p><strong>${getSubtotal(product.finalProduct).toFixed(2)}</strong></p>
                                                    </div>
                                                    
                                                </div>
                                            ))}
                                        <div className='productRow'>
                                        <div className="orderImage"></div>
                                            <div className='orderInfo'>
                                            <p>Subtotal</p>
                                            <p>Cost of labor (40% of subtotal)</p> 
                                            <p>Sales tax (7% of subtotal)</p>
                                            <h4>Total</h4>
                                            </div>
                                            <div className='orderPrice'>
                                            <p><strong>${getOrderSubtotal(order).toFixed(2)}</strong></p>
                                             <p><strong>${(getOrderSubtotal(order) * 0.4).toFixed(2)}</strong></p> 
                                            <p><strong>${(getOrderSubtotal(order) * 0.07).toFixed(2)}</strong></p> 
                                            <h4>${order.totalPayment}</h4>
                                            </div>
                                        </div>
                                        </div>
                                </AccordionDetails>
                                </Accordion>
                                ))}
                            </div>

                        {/* Pagination Controls */}
                        {currentOrders.length > 0 && (
                        <div className="pagination">
                            <button
                            className="page-btn"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            >
                            Prev
                            </button>
                            <span>
                            {`Showing ${(currentPage - 1) * ordersPerPage + 1} 
                            - ${Math.min(currentPage * ordersPerPage, orders.length)} 
                            of ${orders.length}`}
                            </span>
                            <button
                            className="page-btn"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            >
                            Next
                            </button>
                        </div>)}     

                        </TabPanel>

                        <TabPanel value="Account Settings">
                            <div className="tabPaddin">
                                <div>
                                    <div className="font-4 bold pb-3">Update Account Information</div>

                                    <div className="font-2 bold py-3">Name</div>
                                    <div className="row">
                                        <div className="col-2">
                                            <TextField 
                                                id="firstName" 
                                                label="first name" 
                                                variant="outlined" 
                                                type="name"
                                                error={!goodFirstName}
                                                helperText={!goodFirstName ? "A name can't have numbers" : " " }
                                                onChange={(e) => { setFirstName(e.target.value); }}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <TextField 
                                                id="lastName" 
                                                label="last name" 
                                                variant="outlined" 
                                                type="name"
                                                error={!goodLastName}
                                                helperText={!goodLastName ? "A name can't have numbers" : " " }
                                                onChange={(e) => { setLastName(e.target.value); }}
                                            />
                                        </div>
                                        <div className="col-5 pt-1">
                                            <button 
                                                className="btn button-dark font-2" 
                                                disabled={((!goodFirstName || !goodLastName) || (firstName === "" || lastName === "")) ? true: false} 
                                                variant="outlined" 
                                                onClick={updateUserName}
                                            >
                                                update name
                                            </button>
                                        </div>
                                    </div>

                                    <div className="font-2 bold py-3">Email</div>
                                    <div className="row">
                                        <div className="col-3">
                                            <TextField 
                                                id="email" 
                                                label={user.email} 
                                                //error={emailFormatCheck ? true : false}
                                                variant="outlined" 
                                                type="email" 
                                                error={!goodEmail}
                                                helperText={!goodEmail ? "Enter a vaild email format" : " " }
                                                onChange={(e) => {
                                                    setEmail(e.target.value); 
                                                    emailFormatCheck();
                                                }}
                                            />
                                        </div>
                                        <div className="col pt-1">
                                            <button className="btn button-dark font-2" variant="outlined" disabled={!goodEmail || email === ""} onClick={updateUserEmail}>update email</button>
                                        </div>

                                    </div>
                                  
                                    <div className="font-2 bold py-3"> Password </div>
                                    <div className="row pb-2">
                                        <div className="col-3">
                                            <TextField 
                                                id="currentPassword" 
                                                label="Current Password" 
                                                variant="outlined" 
                                                type="password"
                                                value={currentPassword}
                                                error={!goodCurrentPassword}
                                                helperText={!goodCurrentPassword ? "Wrong password" : " " }
                                                onChange={(e) => {
                                                    setCurrentPassword(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <TextField 
                                                id="newPassword" 
                                                label="New Password" 
                                                value={newPassword}
                                                variant="outlined" 
                                                type="password"
                                                error={!goodNewPassword}
                                                onChange={(e) => {
                                                    setNewPassword(e.target.value); 
                                                }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <TextField 
                                                id="confirmNewPassword" 
                                                label="Confirm New Password"
                                                value={confirmNewPassword}
                                                variant="outlined" 
                                                type="password" 
                                                error={!goodConfirmNewPassword}
                                                helperText={!goodConfirmNewPassword ? "New passwords must match" : " " }
                                                onChange={(e) => {
                                                    setConfirmNewPassword(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col">
                                            <button 
                                                className="btn button-dark font-2" 
                                                disabled={((!goodNewPassword || !goodConfirmNewPassword) || (newPassword === "" || confirmNewPassword === "")) ? true: false} 
                                                variant="outlined" 
                                                onClick={updateUserPassword}
                                            >
                                                update password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>


                    </div>
                </ TabContext >
            </Box>

            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            
            {currentSection === "Dashboard" && 
            (<div>
                <div className="py-5"/>
                <div className="py-5"/>
                <div className="py-5"/>
            </div>)}
            
            <Footer />
        </div>
    )
}

export default Account;

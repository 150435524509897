import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI components
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

// MUI icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Components
import Loading from '../loading';
import ToolPreview from '../visualizationTool/toolPreview';

import { ENDPOINT_PATH } from '../../config';
import './ShoppingCart.css';


const noFrameName = "noSelectedFrame";



const ShoppingCart = (props) => {
    const navigate = useNavigate();

    // Get user
    const user = JSON.parse(localStorage.getItem('user'));

    // Loading values
    const [loadingFinalProductsList, setLoadingFinalProductsList] = useState(true);
    const [loadingFetchedFinalProducts, setLoadingFetchedFinalProducts] = useState(true);
    let loading = loadingFinalProductsList || loadingFetchedFinalProducts;

    // Other values
    const [finalProductList, setFinalProductList] = useState([]);
    const [shoppingCartID, setShoppingCartID] = useState("");
    const [fetchedFinalProducts, setFetchedFinalProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);



    // UseEffect to get shopping cart contents
    useEffect(() => {
        const getShoppingCart = () => {
            fetch(ENDPOINT_PATH + `/api/shopping-cart?userID=` + encodeURIComponent(user.uid))
            .then(response => response.json())
            .then(data => {
                setShoppingCartID(data.shoppingCartID ?? "");
                setFinalProductList(data.finalProducts ?? []);
                setLoadingFinalProductsList(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingFinalProductsList(false);
            })
        }

        if (shoppingCartID === "" && user) {
            getShoppingCart();
        }
        else {
            setLoadingFinalProductsList(false);
        }
        
    }, [shoppingCartID, user]);


    // UseEffect to fetch the data of all the final products
    useEffect(() => {
        const fetchFinalProductsData = async () => {
            let priceRunningTotal = 0;
            const FinalProducts = await Promise.all(
                // Map through the list of final products and query each one, since right now we
                //     only have a reference to each one, and not the actual values they contain
                finalProductList.map(async (finalProductObject) => {
                    const quantity = finalProductObject.quantity;
                    const finalProduct = finalProductObject.finalProduct;
                    const finalProductID = finalProduct._path.segments[1];

                    // Query to find the data of the final product with the given ID
                    try {
                        const finalProductRes = await fetch(ENDPOINT_PATH + `/api/final-product?finalProductID=${encodeURIComponent(finalProductID)}`);
                        const data = await finalProductRes.json();

                        // Add price of finalProduct to running total
                        const finalProductTotalPrice = data.mediumPrice + data.framePrice + data.matboardPrices[0] + data.matboardPrices[1] + data.matboardPrices[2] + data.glassPrice + data.hangPrice;
                        priceRunningTotal += finalProductTotalPrice * quantity;
                        const finalProduct = {
                            finalProductID: finalProductID,
                            quantity: quantity,
                            finalProduct: data,
                            price: finalProductTotalPrice
                        }
                        return finalProduct;
                    } catch (error) {
                        console.error("Error fetching final product data:", error);
                        return null;
                    }
                })
            );
            // Filter out the ones that we failed to fetch
            setFetchedFinalProducts(FinalProducts.filter(item => item !== null));

            setTotalPrice(priceRunningTotal);

            setLoadingFetchedFinalProducts(false);
        };

        fetchFinalProductsData();

    }, [finalProductList]);

    
    const handleCheckout = () => {
        props.handleClose();
        navigate('/checkout');
    };


    // Method to delete final product from shopping cart
    const deleteFinalProduct = async (finalProductID) => {
        // TODO: include an "are you sure" popup
        try {
            const deleteFinalProductRes = await fetch(ENDPOINT_PATH + '/api/delete-final-product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    shoppingCartID: shoppingCartID,
                    finalProductID: finalProductID
                }),
            });
            if (deleteFinalProductRes.ok) {
                console.log("Successfully removed final product from shopping cart");

                // Update the finalProductList to the return value of the API call, which is the new state of the array in the database
                setFinalProductList(await deleteFinalProductRes.json());
            }
            else {
                console.error('Error deleting final product:', deleteFinalProductRes.statusText);
            }

        } catch (e) { console.log(e); }
    }


    // Method to edit the quantity of a final product in the shopping cart
    const changeFinalProductQuantity = async (finalProductID, newQuantity) => {
        try {
            const changeFinalProductQuantityRes = await fetch(ENDPOINT_PATH + '/api/change-final-product-quantity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    shoppingCartID: shoppingCartID,
                    finalProductID: finalProductID,
                    newQuantity: newQuantity
                }),
            });
            if (changeFinalProductQuantityRes.ok) {
                console.log("Successfully changed final product quantity in shopping cart");

                // Update the finalProductList to the return value of the API call, which is the new state of the array in the database
                setFinalProductList(await changeFinalProductQuantityRes.json());
            }
            else {
                console.error('Error changing final product quantity:', changeFinalProductQuantityRes.statusText);
            }

        } catch (e) { console.log(e); }
    }
    


    return (
        <div>
            <Drawer anchor={"right"} open={props.open} onClose={props.handleClose}>
                <Paper className="pt-2 cartHeader z-2" elevation={3} square>
                    <div className="d-flex justify-content-between p-3">
                        <div onClick={(e) => { props.handleClose(); } }>
                            <ArrowBackIcon className="materialIcon" fontSize='large'/>
                        </div>
                        <div className="font-3 pe-3">shopping cart</div>
                    </div>
                </Paper>
                <Box className="scrollableList z-1">
                    {/* If final products haven't finished fetching yet */}
                    {
                        (loading) &&
                        <div className="d-flex row justify-content-center p-5">
                            <Loading/>
                        </div>
                    }
                    {/* If final products have been fetched but shopping cart is empty */}
                    {
                        (!loading && fetchedFinalProducts.length === 0) &&
                        <>
                            <div className="d-flex row justify-content-center p-5">
                                <div className="font-3 subheader">There's nothing here!</div>
                            </div>
                            <div className="d-flex row justify-content-center p-5">
                                <div className="font-2 subheader pb-3">Want to make a frame?</div>
                                <br/>
                                <a href="/visualizationTool" className="subheader">
                                <button className="btn btn-outline-light btn-lg button-padding button-dark">
                                        <div className="font-2">start building</div>
                                    </button>
                                </a>
                            </div>
                        </>
                    }
                    {/* If shopping cart is not empty */}
                    {
                        (!loading && fetchedFinalProducts.length !== 0) &&
                        <div className="d-flex row justify-content-center">
                            {
                                fetchedFinalProducts.map((finalProductObject, index) => {
                                    return (
                                        <CartItem
                                            key={`shopping-cart-final-product ${index}`}
                                            index={index}
                                            finalProductID={finalProductObject.finalProductID}
                                            deleteFinalProduct={deleteFinalProduct}
                                            changeFinalProductQuantity={changeFinalProductQuantity}
                                            handleClose={props.handleClose}
                                            quantity={finalProductObject.quantity}
                                            medium={finalProductObject.finalProduct.medium}
                                            uploadedImage={finalProductObject.finalProduct.uploadedImage}
                                            uploadedImageFileName={finalProductObject.finalProduct.uploadedImageFileName}
                                            imageSize={finalProductObject.finalProduct.imageSize}
                                            frameOrientation={finalProductObject.finalProduct.frameOrientation}
                                            frameImage={finalProductObject.finalProduct.frameImage}
                                            frameThicknessAvailable={finalProductObject.finalProduct.frameThicknessAvailable}
                                            selectedFrame={finalProductObject.finalProduct.selectedFrame}
                                            displayName={finalProductObject.finalProduct.displayName}
                                            frameThickness={finalProductObject.finalProduct.frameThickness}
                                            frameSize={finalProductObject.finalProduct.frameSize}
                                            frameImageSize={finalProductObject.finalProduct.frameImageSize}
                                            matboardNum={finalProductObject.finalProduct.matboardNum}
                                            matboardThickness={finalProductObject.finalProduct.matboardThickness}
                                            frameType={finalProductObject.finalProduct.frameType}
                                            selectedMatboards={finalProductObject.finalProduct.selectedMatboards}
                                            glassType={finalProductObject.finalProduct.glassType}
                                            hangingStyle={finalProductObject.finalProduct.hangingStyle}
                                            mediumPrice={finalProductObject.finalProduct.mediumPrice}
                                            framePrice={finalProductObject.finalProduct.framePrice}
                                            matboardPrices={finalProductObject.finalProduct.matboardPrices}
                                            glassPrice={finalProductObject.finalProduct.glassPrice}
                                            hangPrice={finalProductObject.finalProduct.hangPrice}
                                            title={finalProductObject.finalProduct.title}
                                            finalProductPrice={finalProductObject.price}
                                        />
                                    );
                                })
                            }
                            <div className="d-flex justify-content-end pe-2 py-3">
                                <div className="bold font-3 pe-3">total: ${(totalPrice).toFixed(2)}</div>
                            </div>
                            <div className="py-2 d-flex justify-content-center" style={{ color: 'grey' }}>
                                *Shipping and taxes will be calculated at checkout
                            </div>
                            <div className="py-2 d-flex justify-content-center">
                                <button className="btn button-dark-checkout font-3 bold" onClick={handleCheckout}>Checkout</button>
                            </div>
                        </div>
                    }
                </Box>
            </Drawer>
        </div>
    );
}


const CartItem = (props) => {
    const navigate = useNavigate();

    const [quantity, setQuantity] = useState(props.quantity);



    // Helper method to handle changing the quantity of a final product
    const handleChangeQuantity = (newQuantity) => {
        // Don't let it go below 1
        if (newQuantity > 0) {
            props.changeFinalProductQuantity(props.finalProductID, newQuantity);
            setQuantity(newQuantity);
        }
    }

    // Helper method to handle deletion of a final product
    const handleDeleteFinalProduct = () => {
        props.deleteFinalProduct(props.finalProductID);
    }

    // Helper method to trigger edit
    const handleEditFinalProduct = () => {
        // Add final product ID to local storage so that when we get to the vis tool, it knows we're
        //     editing a finalProduct as opposed to creating a new one
        window.localStorage.setItem('FINAL_PRODUCT_ID', JSON.stringify(props.finalProductID));

        // Add all the other necessary cached values so the finalProduct loads correctly into the visTool
        window.localStorage.setItem('MEDIUM', JSON.stringify(props.medium));
        window.localStorage.setItem('UPLOADED_IMAGE', JSON.stringify(props.uploadedImage));
        window.localStorage.setItem('UPLOADED_IMAGE_FILE_NAME', JSON.stringify(props.uploadedImageFileName));
        window.localStorage.setItem('IMAGE_SIZE', JSON.stringify(props.imageSize));
        window.localStorage.setItem('FRAME_ORIENTATION', JSON.stringify(props.frameOrientation));
        window.localStorage.setItem('FRAME_IMAGE', JSON.stringify(props.frameImage));
        window.localStorage.setItem('FRAME_THICKNESS_AVAILABLE', JSON.stringify(props.frameThicknessAvailable));
        window.localStorage.setItem('SELECTED_FRAME', JSON.stringify(props.selectedFrame));
        window.localStorage.setItem('DISPLAY_NAME', JSON.stringify(props.displayName));
        window.localStorage.setItem('FRAME_THICKNESS', JSON.stringify(props.frameThickness));
        window.localStorage.setItem('FRAME_TYPE', JSON.stringify(props.frameType));
        window.localStorage.setItem('FRAME_SIZE', JSON.stringify(props.frameSize));
        window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify(props.frameImageSize));
        window.localStorage.setItem('MATBOARD_NUM', JSON.stringify(props.matboardNum));
        window.localStorage.setItem('MATBOARD_THICKNESS', JSON.stringify(props.matboardThickness));
        window.localStorage.setItem('SELECTED_MATBOARDS', JSON.stringify(props.selectedMatboards));
        window.localStorage.setItem('GLASS_TYPE', JSON.stringify(props.glassType));
        window.localStorage.setItem('HANGING_STYLE', JSON.stringify(props.hangingStyle));

        props.handleClose();

        navigate('/visualizationTool');
    }



    return (
        <Accordion className="pt-2 pb-2">
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <div className="row accordionSummary">

                    {/* Title */}
                    <div className="row ps-3">
                        <div className="font-2">{props.title}</div>
                    </div>

                    <div className="row ps-5 pt-2">
                        {/* Tool preview */}
                        <div className="col-6">
                            <ToolPreview
                                uploadedImage={props.uploadedImage}
                                frameOrientation={props.frameOrientation}
                                frame={props.frameImage}
                                matboards={{thickness: props.matboardThickness, colors: [props.selectedMatboards[0].hexColor,
                                                                                        props.selectedMatboards[1].hexColor,
                                                                                        props.selectedMatboards[2].hexColor]}}
                                frameThickness={props.frameThickness}
                                frameSize={props.frameSize}
                                frameImageSize={props.frameImageSize}
                                matboardNum={props.matboardNum}
                                sameSize={true}
                                scaleTool={false}
                                frameName={props.selectedFrame}
                            />
                        </div>
                        
                        <div className="col pt-2">
                            <div className="row justify-content-center">
                                {/* Quantity field */}
                                <div className="col d-flex align-items-center justify-content-center">
                                    <div className="pe-3">quantity</div>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        value={quantity}
                                        className="w40"
                                        size={"small"}
                                        onClick={(event) => {
                                                event.stopPropagation();
                                            }
                                        }
                                        onChange={(event) => {
                                                handleChangeQuantity(event.target.value);
                                                event.stopPropagation();
                                            }
                                        }
                                    />
                                </div>

                                <div className="col-9 pt-4">
                                    <div className="row">
                                        {/* Edit button */}
                                        <div className="col d-flex justify-content-start">
                                            <div onClick={(e) => {
                                                    handleEditFinalProduct();
                                                    e.stopPropagation();
                                                }
                                            }>
                                                <EditIcon className="materialIcon"/>
                                            </div>
                                        </div>

                                        {/* Delete button */}
                                        <div className="col d-flex justify-content-end">
                                            <div onClick={(e) => {
                                                    handleDeleteFinalProduct();
                                                    e.stopPropagation();
                                                }
                                            }>
                                                <DeleteIcon className="materialIcon"/>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {/* Price */}
                                <div className="d-flex column justify-content-end pt-5 pe-4">
                                    <div className="bold font-3">${(props.finalProductPrice).toFixed(2)}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Divider sx={{width: "100%", opacity: 1}}/>
                <div className="pt-2 ps-5">
                    {/* IMAGE */}
                    <div className="d-flex row">
                    {
                        props.medium !== "" &&
                        (
                            <div className="d-flex p-2">
                                <img className="col-3" src={props.uploadedImage} alt="uploaded print"/>
                                <div className="ps-3 d-flex align-items-center">{props.imageSize.width}" x {props.imageSize.height}" {props.medium}</div>
                                <div className="ps-4 d-flex align-items-center">${props.mediumPrice.toFixed(2)}</div>
                            </div>
                        )
                    }
                    </div>

                    {/* FRAME */}
                    <div className="d-flex row ">
                    {
                        props.selectedFrame !== noFrameName &&
                        (
                            <div className="d-flex p-2">
                                <img className="col-3" src={props.frameImage} alt="uploaded frame"/>
                                <div className="ps-3 d-flex align-items-center">{props.displayName} Frame</div>
                                <div className="ps-4 d-flex align-items-center">${props.framePrice.toFixed(2)}</div>
                            </div>
                        )
                    }
                    </div>

                    {/* MATBOARDS */}
                    <div className="d-flex row ">
                    {
                        (Array.from({ length: props.matboardNum }, (_, index) => index)).map((layerIndex) => {
                            return (
                                <div className="d-flex ps-2 py-2" key={`matboard-options-dropdown-shopping-cart ${layerIndex}`}>
                                    <div className="ps-2 col col-sm-auto">
                                        <svg width={50} height={50}>
                                            <circle stroke="black" strokeWidth="1" cx={25} cy={25} r={20} fill={props.selectedMatboards[layerIndex].hexColor} />
                                        </svg> 
                                    </div>
                                    <div className="ps-4 d-flex align-items-center">{props.selectedMatboards[layerIndex].displayName} Matboard</div>
                                    <div className="ps-4 d-flex align-items-center">${props.matboardPrices[layerIndex].toFixed(2)}</div>
                                </div>
                            );
                        })
                    }
                    </div>

                    {/* ACCESSORIES */}
                    <div className="d-flex row">
                    {
                        (props.glassType !== "None" && props.hangingStyle !== "None") &&
                        (
                            <>
                                {
                                    props.glassType !== "None" &&
                                    <div className="d-flex row py-3">
                                        <div className="d-flex">
                                            <div className="ps-3 pe-4">{props.glassType} Glass</div>
                                            <div className="ps-5">${props.glassPrice.toFixed(2)}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    props.hangingStyle !== "None" &&
                                    <div className="d-flex row pt-3 pb-4">
                                        <div className="d-flex">
                                            <div className="ps-3 pe-4">{props.hangingStyle} Hanging</div>
                                            <div className="ps-5">${props.hangPrice.toFixed(2)}</div>
                                        </div>
                                    </div>
                                }
                            </>
                        )
                    }
                    </div>
                </div>
                <Divider sx={{width: "100%", opacity: 1}}/>
                <div className="d-flex column justify-content-end pt-2 pe-4">
                    <div className="bold font-3">total: ${(props.finalProductPrice).toFixed(2)}</div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default ShoppingCart;

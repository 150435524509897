import { ENDPOINT_PATH } from "../config";

/**
 * @param {String} artworkName
 * @param {String} selectedArtist
 * @param {String} category
 * @param {String} price
 * @param {String} description
 * @param {File} artImage
 */
const addArt = async (artworkName, selectedArtist, category, price, description, artImage) => {
    
    const splitName = selectedArtist.split(' ');
    const firstName = splitName[0];
    const lastName = splitName[1];

    description = description ?? "";

    const artFormData = new FormData();
    artFormData.append('artworkName', artworkName);
    artFormData.append('artistFirstName', firstName);
    artFormData.append('artistLastName', lastName);
    artFormData.append('category', category);
    artFormData.append('price', price);
    artFormData.append('artDescription', description);
    artFormData.append('artImage', artImage);

    fetch(ENDPOINT_PATH + `/api/add-art`, {
        method: 'POST',
        body: artFormData
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Art added: ", data);
    })
    .catch(e => { console.error(e); });        
}
export { addArt };


/**
 * @param {String} artDocID
 * @param {String} artistDocID
 * @param {String} selectedArtist
 * @param {File} artImage
 */
const editArt = async(artDocID, artistDocID, selectedArtist, artImage, updates = {}) => {
    try {
        selectedArtist = selectedArtist ?? null; // Set to null if not passed for logic
        artImage = artImage ?? null;

        const formData = new FormData();

        // These variables are required for the logic
        formData.append('artDocID', artDocID);
        formData.append('artistDocID', artistDocID);
        if (selectedArtist != null)
            formData.append('selectedArtist', selectedArtist);
        if (artImage != null)   
            formData.append('artImage', artImage);

        // This adds in all of the optional variables
        for (const key in updates) {
            formData.append(key, updates[key]);
        }

        fetch(ENDPOINT_PATH + `/api/edit-art`, {
            method: 'PATCH',
            body: formData
        })
        .then(response => response.json)
        .then(data => {
            console.log('Art edited successfully');
        })
        .catch(e => { console.error(e)});

    } catch (error) {
        console.log("Error occured in editArt:", error);
    }
}
export { editArt };

/**
 * @param {String} artistDocID
 * @param {String} artDocID
 */
const delArt = async(artistDocID, artDocID) => {

    fetch(ENDPOINT_PATH + `/api/del-art`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            'artistDocID': artistDocID,
            'artDocID': artDocID
        })
    })
    .then(response => response.json())
    .then(data => {
        console.log("Art deleted successfully");
    })
    .catch(e => { console.error(e); })
}
export { delArt };


/**
 * @param {String} category
 */
const addCategory = async (category) => {

    fetch(ENDPOINT_PATH + `/api/add-category`, {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({ newCategory: category})
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Art category added");
    })
    .catch(e => { console.error(e); });        
}
export { addCategory };


const getCategories = async () => {

    return fetch(ENDPOINT_PATH + `/api/get-categories`, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Categories returned successfully: ", data);
        return data
    })
    .catch(e => { console.error(e); throw e; });        
}
export { getCategories };



const getAllArt = async () => {

    return fetch(ENDPOINT_PATH + `/api/get-all-art`, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => { 
        console.log("All art returned successfully: ", data);
        return data;
    })
    .catch(e => { console.error(e); throw e; });
    
}
export { getAllArt };


/**
 * @param {String} artistID
 */
const getArtistArt = async (artistID) => {
    return fetch(ENDPOINT_PATH + `/api/get-artists-art?artistID=${artistID}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => {
        console.log("Artists art successfully returned");
        return data;
    })
    .catch(e => {console.error(e); throw e});
}
export {getArtistArt};



/**
 * @param {String} category
 */
const getCategoryArt = async (category) => {

    return fetch(ENDPOINT_PATH + `/api/get-category-art?category=${category}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Category art returned successfully: ", data);
        return data;
    })
    .catch(e => { console.error(e); throw e});        
}
export { getCategoryArt };
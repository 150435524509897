import React, { useState, useEffect } from "react";
import logoImage from '../images/Logo-Base-basic.png';
import Sidebar from '../components/SideBar';
import { Button, Dropdown } from 'react-bootstrap';
import {getMediumReport, getGlassReport, getHangingReport, getMatboardReport, getFrameReport, getArtReport } from "./metricsHelper.js";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BarChart } from '@mui/x-charts/BarChart';
import { ENDPOINT_PATH } from '../config';
import './metrics.css';

const AdminNavbar = () => {
  return (
    <div className="navWrapper">
      <div className="AdminNav">
        <div>
          <a href="/" className="NavLink">
            <div className="AdminLogo d-flex justify-content-end">
              <img src={logoImage} alt='logo' className="NavLogo" />
            </div>
            <div className="AdminLogoText font-2 ps-2 fontLogo">mycustomframe</div>
          </a>
        </div>
      </div>
    </div>
  );
};

const Metrics = () => {
  const [errorMessage, setErrorMessage] = useState(""); 
  const [report, setReport] = useState(null);
  const [activeTab, setActiveTab] = useState("Products");
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [frames, setFrames] = useState ([]);
  const [mediums, setMediums] = useState ([]);
  const [matboards, setMatboards] = useState([]);
  const [hangingStyles, setHangingStyles] = useState([]);
  const [glassTypes, setGlassTypes] = useState ([]);

  const [productID, setSelectedProductID] = useState(null);
  const [thicknessList, setThicknessList] = useState([]);
  const [selectedThickness, setSelectedThickness] = useState([]);

  useEffect(() => {
    if (thicknessList.length > 0) {
      setSelectedThickness(thicknessList[0]); // Set default value once the list is populated
    }
  }, [thicknessList]); 

  useEffect(()=>{
    setErrorMessage("");
  }, [activeTab]);
  

  useEffect(() => {
    setSelectedProductID(null); // Reset selectedProductID when category changes
  }, [selectedCategory]); 

  
  useEffect(() => {
    setReport(null);

    // Get list of artists
    const getArtists = async () => {
      try {
        const response = await fetch(`${ENDPOINT_PATH}/api/get-artists`);
        const data = await response.json();
        const artistList = data.data.map(artist => ({
          name: `${artist.firstName} ${artist.lastName}`,
          ...artist,
        }));
        setArtists(artistList); 
      } catch (error) {
        console.error("Error fetching artists:", error);
      }
    };

    // Get list of valid frames
    const getFrames = () => {
      fetch(ENDPOINT_PATH + `/api/frames`)
      .then(response => response.json())
      .then(data => {
          const framesList = data.map(frame => ({
            productID: frame.frameName,
            ...frame,
          }));
          setFrames(framesList);
      })
      .catch(e => {
        console.log(e);
      });
    }

    // Get list of valid mediums
    const getMediums = () => {
      fetch(ENDPOINT_PATH + `/api/mediums`)
      .then(response => response.json())
      .then(data => {
          // Extract the mediums from the objects
          const mediumsArray = data.map(medium => medium.mediumName);
          setMediums(mediumsArray);
      })
      .catch(e => {
          console.log(e);
      });
    }

    // Get list of valid matboards
    const getMatboardList = () => {
      fetch(ENDPOINT_PATH + `/api/matboards`)
      .then(response => response.json())
      .then(data => {
        const matboardList = data.map(matboard => ({
          productID: matboard.internalName,
          ...matboard,
        }));
        setMatboards(matboardList);
      })
      .catch(e => {
          console.log(e);
      });
    }

    // Get list of valid hanging styles
    const getHangingStyles = () => {
      // First add the "None" option
      var hangingStylesList = ["None"];

      // Then fetch the rest of the styles
      fetch(ENDPOINT_PATH + `/api/hanging-styles`)
      .then(response => response.json())
      .then(data => {
          setHangingStyles(hangingStylesList.concat(data));
      })
      .catch(e => {
          console.log(e);
      });
    }

    // Get list of valid glass types
    const getGlassTypes = () => {
      // First add the "None" option
      var glassTypesList = ["None"];

      // Then fetch the rest of the types
      fetch(ENDPOINT_PATH + `/api/glass-types`)
      .then(response => response.json())
      .then(data => {
          // Add all the fetched glass types to the list of glass types
          setGlassTypes(glassTypesList.concat(data));
      })
      .catch(e => {
          console.log(e);
      });
    }

    getArtists();
    getFrames();
    getMediums();
    getMatboardList();
    getHangingStyles();
    getGlassTypes();
  }, []);


  // Initalize date range to span the last month by default
  const today = new Date();
  const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
  const [fromDate, setFromDate] = useState(lastMonth.toISOString().split("T")[0]);
  const [toDate, setToDate] = useState(today.toISOString().split("T")[0]);


  const formatDate = (dateString) => {
    const date = new Date(dateString + "T00:00:00"); // Create a Date object from the string

    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year
    
    return `${month}/${day}/${year}`;
  };

  const validateDates = () => {
    if (!fromDate || !toDate) throw new Error("Both dates must be selected.");
    if (toDate <= fromDate) throw new Error("End date must be after start date.");
    return true;
  };

  const handleResetCategory = () => {
    setSelectedCategory("");
    setSelectedProductID(null);
    setThicknessList([]);
  };

  const handleResetDates = () => {
    setFromDate("");
    setToDate("");
  };
  
  const GenerateReportForm = () =>
    {
      const getProductIDList = (category) =>
        {
          switch (category) {
            case "Frames":
              return frames;
            case "Mediums":
              return mediums; 
            case "Matboards":
              return matboards;
            case "Hanging Style":
              return  hangingStyles;
            case "Glass":
              return glassTypes;
            default:
              return [];  
          }
        };

        const handleProductIDChange = (newValue) =>
          {
            if (newValue)
              {
                setSelectedProductID(newValue);
                if (selectedCategory === "Frames")
                  {
                    setThicknessList(newValue.frameThicknessAvailable)
                  }
              }
          }

      return (
        <div>
          {/* Date Range Filters */}
          <label>Select Dates</label>
          <div className="date-filters">
              <label htmlFor="fromDate" className="date-label">Start</label>
              <input
                type="date"
                id="fromDate"
                className="date-filter"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <label htmlFor="toDate" className="date-label">End</label>
              <input
                type="date"
                id="toDate"
                className="date-filter"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              {/* Reset Button for Dates */}
              {(fromDate || toDate) && (
                <button
                  className="reset-btn"
                  onClick={handleResetDates}
                  aria-label="Reset Dates"
                >
                  &#x2715;
                </button>
              )}
            </div>

            {activeTab === "Products" && (
              <div>
                {/* Category Dropdown */}
                <label>Select Category</label>
                <div className="container">
                <Dropdown>
                  <Dropdown.Toggle id="category-dropdown">
                    {selectedCategory || "Categories"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item onClick={() => setSelectedCategory("Frames")}>Frames</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectedCategory("Mediums")}>Mediums</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectedCategory("Matboards")}>Matboards</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectedCategory("Hanging Style")}>Hanging Style</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectedCategory("Glass")}>Glass</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* Reset Icon for Category */}
                {selectedCategory && (
                  <button
                    className="reset-btn"
                    onClick={handleResetCategory}
                    aria-label="Reset Category"
                  >
                    &#x2715;
                  </button>
                )}
                </div>
              </div> )}


              {activeTab === 'Artists' ?
              (
                <div>
                {/* Select from Artists input box */}
                  <label>Select Artist</label>
                  <Autocomplete
                  disablePortal
                  className="autocomplete-box"
                  options = {artists}
                  freeSolo={true} 
                  forcePopupIcon={true} 
                  getOptionLabel={(option) => option.name}
                  value = {selectedArtist}
                  onChange={(event, newValue) => setSelectedArtist(newValue || null)} 
                  renderInput={(params) => <TextField {...params} placeholder="Artists" />}
                  />
                </div>
              ) : (
              
              <div>
              {/* ProductID input box */}
              <label>Enter the Product ID</label>
              <div>
                <Autocomplete
                  disablePortal
                  className="autocomplete-box"
                  options={getProductIDList(selectedCategory)}
                  noOptionsText="No Options Available"
                  freeSolo={true} 
                  forcePopupIcon={true} 
                  getOptionLabel={(option) => {
                    if (option && option.productID) {
                      return option.productID; // Return productID for objects
                    }
                    return option || '';  // Return the string itself for other options
                  }}
                  value={productID}
                  onChange={(event, newValue) => handleProductIDChange(newValue)} 
                  renderInput={(params) => {return(<TextField {...params} placeholder="Product ID"/>);}}
                  renderOption={(props, option) => {
                    if (option && option.iconUrl) {
                      return (
                        <li {...props} style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={option.iconUrl}  
                            alt={option.productID}
                            style={{ width: 40, height: 40, marginRight: 15 }}  
                          />
                          <span>{option.productID}</span> 
                        </li>
                      );
                    }
                    else if (option.hexColor)
                      {
                        return(
                          <li {...props} style={{ display: "flex", alignItems: "center" }}>
                          <div
                          style={{
                            width: 20,
                            height: 20,
                            backgroundColor: option.hexColor, // Use the hex color for the square
                            marginRight: 15,
                            border: "1px solid #000",
                            borderRadius: 4
                          }}
                        />
                          <span>{option.productID} ({option.displayName})</span> 
                        </li>
                        );
                      }
                    // If option is a string
                    else if (typeof option === 'string') {
                      return (
                        <li {...props}>
                          <span>{option}</span>
                        </li>
                      );
                    }
                    // For other data structures
                    return (
                      <li {...props}>
                        <span>{option.productID}</span> 
                      </li>
                    );
                  }}
                />
              </div>
              </div> )}

            
            {/* Frame Thickness Selection */}
            {activeTab === 'Products' && selectedCategory === 'Frames' && productID && (
            <div>
              <label>Select Frame Thickness</label>
              <div className="thickness-select">
                <div className="thickness-button-group">
                {thicknessList.map((thickness) => (
                  <Button
                    key={thickness}  
                    onClick={() => setSelectedThickness(thickness)}
                    className={`thickness-button ${selectedThickness === thickness ? 'selected' : ''}`}
                  >
                  {thickness}"
                  </Button>
                ))}
              </div>
            </div> 
            <br></br>
            </div>)}
            
          <Button onClick = {() => createReport(selectedCategory, activeTab)}>Generate</Button>
          {errorMessage && <p style={{color: "red", marginTop: "10px"}}>{errorMessage}</p>}
           
        </div>
      );
    };

    const reportFunctions = {
      Frames: async () => 
        getFrameReport(
          productID.productID,
          thicknessList.length > 1 ? selectedThickness : null,
          fromDate,
          toDate
        ),
      Mediums: async () => getMediumReport(productID, fromDate, toDate),
      Matboards: async () => getMatboardReport(productID.productID, fromDate, toDate),
      "Hanging Style": async () => getHangingReport(productID, fromDate, toDate),
      Glass: async () => getGlassReport(productID, fromDate, toDate),
      Art: async () => getArtReport(selectedArtist.id, fromDate, toDate)
    };


    const createReport =  async (category, activeTab) =>
      {
        try {
          if (!validateDates()) return;
          setErrorMessage(""); // Clear previous errors
          let reportFunction;

          if (activeTab === 'Artists' && selectedArtist)
            {
              reportFunction = reportFunctions["Art"];
            }
          else if ( activeTab === 'Products' && selectedCategory && productID) {
            reportFunction = reportFunctions[category]; 
          }
  
          // Check if we have a valid reportFunction
          if (reportFunction) {
            const report = await reportFunction();
            setReport(report);  // Set the generated report
          } 
          else {
            // Set specific error messages based on what is missing
            if (activeTab === 'Artists' && !selectedArtist) {
              setErrorMessage("Please select an artist.");
            } else if (activeTab === 'Products' && (!selectedCategory || !productID)) {
              setErrorMessage("Please fill out all fields.");
            } else {
              setErrorMessage("Invalid selection or missing required fields.");
            }
          }
        } catch (error) {
          setErrorMessage(error.message); // Store error in state
        }
      };

      const GenerateChart = () => 
      {
        const groupedData = {};

        Object.values(report.graphMap).forEach(item => {
          const fullDate = new Date(item.timeStamp._seconds * 1000);
          const dateKey = fullDate.toISOString().split('T')[0]; 

          if (!groupedData[dateKey]) {
            groupedData[dateKey] = { date: new Date(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getDate()), quantity: 0 };
          }

          groupedData[dateKey].quantity += item.quantity; 
        });

        // Convert grouped data back to an array
        const formattedData = Object.values(groupedData);

        console.log(formattedData);
      
          return (
            <BarChart
            dataset={formattedData}
            xAxis={[
            {
              scaleType: 'band',
              dataKey: 'date',
               valueFormatter: (date, context) =>{
                const month = date.toLocaleString('en-US', { month: 'short' }); 
                const day = date.getDate().toString().padStart(2, '0'); 
                if (context.location === 'tick') {
                  return `${month} ${day}`;
                }
                return date.toLocaleDateString();
              },
            },
            ]}
            series={[
              {
                dataKey:'quantity',
                color: '#ff4172',
                label:'Total Purchases per Day'
              },
            ]}
            yAxis={[
              {
                label: 'Units Sold',
              }
            ]}
            width={600}
            height={300}
            barLabel="value"
            sx={{
              '& .MuiBarLabel-root': {
                fill: 'white',
              },
            }}
            />
          );
        };

      const ViewReport = () =>
        {
          return (
          <div>
            {/* Metrics Table */}
            <div className="report-container">
              <div className="metrics-row">

                {/* Generate Products Metrics */}
                {report && report?.totalQuantity > 0 && selectedCategory && activeTab === 'Products' && (
                <div className="table-container">
                <table className="metrics-table">
                <thead>
                  <tr>
                    {selectedCategory === 'Hanging Style' ? 
                    (<th style={{ width: 'auto'}}></th>) : (
                    <th>SIZE</th> )}
                    <th>QUANTITY</th>
                    <th>REVENUE</th>
                  </tr>
                </thead>
                <tbody>
                {Object.entries(report).map(([key, sizeMap]) => {
                if (key.endsWith("SizeMap") && sizeMap && Object.keys(sizeMap).length > 0) {
                return Object.entries(sizeMap).map(([size, data]) => (
                  <tr key={size}>
                    <td>{size}</td>
                    <td>{data.totalQuantity}</td>
                    <td>${data.totalRevenue.toFixed(2)}</td>
                  </tr>));}
                return null; })}
                </tbody>
                </table></div>)}

              {/* Generate Artists Metrics */}
                {selectedArtist && activeTab === 'Artists' && (
                  <div className="table-container">
                    <table className="metrics-table">
                      <thead>
                    <tr>
                      <th>ARTWORK</th>
                      <th>NAME</th>
                      <th>UNITS SOLD</th>
                    </tr>
                  </thead>
                    <tbody>
                    {Object.entries(report.artMap).map(([key, artwork], index) => (
                      <tr key={index}>
                        <td>
                        <img src={artwork.artImageUrl} alt={""}
                        style={{ width: 100, height: 100, objectFit: 'cover' }} />
                        </td>
                        <td>{artwork.artworkName}</td>
                        <td>{artwork.totalQuantity}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                  </div>)}


                {/* Generate Chart */}
                {report.graphMap && Object.keys(report.graphMap).length > 0 && selectedCategory !== 'Hanging Style' 
                && (
                  <div className="chart-container">
                  <GenerateChart/>
                </div>)}

              </div>

              {/* Separate Total Table */}
              {(selectedCategory || selectedArtist) &&
              (
              <table className="total-table">
                <tbody>
                {report.totalQuantity > 0 ?
                (
                  <tr>
                    <td {...(activeTab === 'Artists' ? { colSpan: 2, style: { textAlign: 'center' } } : {})}>
                      <strong>Total</strong></td>
                    <td><strong>{report?.totalQuantity}</strong></td>
                    {activeTab === 'Products' && (
                    <td><strong>${report?.totalRevenue.toFixed(2)}</strong></td>)}
                  </tr>
                ) : (<tr> <td> No Data Available</td></tr>)}
                </tbody> 
              </table> 
              )}

            </div>
          </div>
          );
        };



  return (
    <div>
      <AdminNavbar />
      <hr className="admin-divider"/>
      <div className="metrics-page">
        <Sidebar/>
        <div className="metrics-content">
          <div className="header">
            {!report ?
             (<div>
                <h4>Generate a Report:</h4>
                <div className="report-tabs">
                  <button 
                  className={`tab-btn btn ${activeTab === "Products" ? "active" : ""}`}
                  onClick={() => setActiveTab("Products")}> Products</button>
                  <button
                  className={`tab-btn btn ${activeTab === "Artists" ? "active" : ""}`}
                  onClick={() => setActiveTab("Artists")}> Artists</button>
                </div>
              </div>
            ) : 
            (<div>
              <button className="icon-button" onClick={() => setReport(null)}>
                <ArrowBackIcon sx={{ fontSize: 20 }} />
                <h6>Back to Generate a Report </h6> 
              </button>
              <div className="report-header">
                {selectedCategory && productID && activeTab === 'Products' && (
                <h4>
                {productID.iconUrl ? (
                  <img src={productID.iconUrl} alt={productID.productID}
                  style={{ width: 40, height: 40, marginRight: 15 }} />) : ("")}
                {productID.hexColor && 
                (<span className="color-box" style={{ backgroundColor: productID.hexColor }} />)}
                {productID.productID ? `${productID.productID} (${productID.displayName})`: `${productID} ${selectedCategory} Report`}
                {" "} {selectedCategory === "Frames" ? `${selectedThickness}" ` : ""}
                from {formatDate(fromDate)} to {formatDate(toDate)}
                </h4> )}
                {selectedArtist && activeTab === 'Artists' && (
                <h4>
                  {selectedArtist.artistPictureURL ? (
                    <img src={selectedArtist.artistPictureURL} alt={selectedArtist.name}
                    style={{ width: 75, height: 75, marginRight: 15, objectFit: 'cover', borderRadius:'60%'}} />) : ("")}
                  {selectedArtist.name} Report from {formatDate(fromDate)} to {formatDate(toDate)} 
                
                </h4>)}
              </div> 
            </div>
          )}
          </div>
          {!report ? (<GenerateReportForm/>) : (<ViewReport/>)}
        </div>
      </div>
    </div>
  );
};

export default Metrics;

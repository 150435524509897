import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import './SideBar.css';

const Sidebar = () => {
    const location = useLocation(); 
    return (
        <div className="sidebar">
            <ul>
                <li className={location.pathname === "/orderHistory" ? "sidebar-active" : ""}>
                    <Link to="/orderHistory">Orders</Link>  
                </li>
                <li className={location.pathname === "/users" ? "sidebar-active" : ""}>
                    <Link to="/users">Users</Link>
                </li >
                <li className={location.pathname === "/productPage" ? "sidebar-active" : ""}>
                    <Link to="/productPage">Products</Link>
                </li>            
                <li className={location.pathname === "/artistPage" ? "sidebar-active" : ""}>
                    <Link to="/artistPage">Artists & Artwork</Link>
                </li>
                <li className={location.pathname === "/metrics" ? "sidebar-active" : ""}>
                    <Link to="/metrics">Metrics</Link>  
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;

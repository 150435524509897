import React, { useState,useEffect } from 'react';
import '../../App.css';
import './ArtistGalleryPopUp.css';
import {getAllArt} from '../../pages/artHelper.js';
import { ENDPOINT_PATH } from '../../config';
import { Modal, Button } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const ArtistGalleryPopUp = ({show, handleClose, onConfirm}) => {
  const [selectedArtist, setSelectedArtist] = useState(null);  
  const [selectedArtwork, setSelectedArtwork] = useState(null);
  const [artists, setArtists] = useState([]);
  const [allArtwork, setAllArtwork] = useState([]);
  const [artwork, setArtworkList] = useState([]);

  

	// Lock scroll 
	useEffect(() => {
		if (show) {
		  document.body.classList.add("modal-open");
      setSelectedArtist(null);
      setSelectedArtwork(null);
		} else {
		  document.body.classList.remove("modal-open");
		}
	  }, [show]); 

    // UseEffect to load artists and artwork
   useEffect(()=>{
      const fetchArtists = async () => {
        try {
          const response = await fetch(`${ENDPOINT_PATH}/api/get-artists`);
          const data = await response.json();
          setArtists(data.data); 
        } catch (error) {
          console.error("Error fetching artists:", error);
        }
      };
      const fetchAllArtwork = async () => {
      const data = await getAllArt();
      if (data && data.data)
        {
          setAllArtwork(data.data);
        }
      };
      fetchArtists();
      fetchAllArtwork();
    },[]) 

    const ViewArtists = () => {
      return (
      <div className="row artist-grid">
        {artists.length > 0 ? (
              artists.map((artist) => (
                <div key={artist.id} className="col-auto d-flex flex-column align-items-center">
                  <img 
                  src={artist.artistPictureURL}
                  className="placeholder"
                  alt =""
                  loading="lazy"
                  onClick={() => handleArtistClick(artist)}
                  />
                  <label>{`${artist.firstName} ${artist.lastName}`}</label>
                </div>
              ))
            ) : (
              <p>No artists found.</p>
            )}
        </div>
      );
    }

    const ViewArtwork = () => {
      return (
        <div>
          {artwork.length > 0 ? (
            <ImageList sx={{ width: "100%", height: "auto", overflow: "visible" }} gap={6} cols={4} rowHeight="auto">
            {artwork.map((item) => (
            <ImageListItem key={item.id} className="image-item">
              <img
                srcSet={`${item.artImageUrl}?w=300&h=300&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.artImageUrl}?w=300&h=300&fit=crop&auto=format`}
                alt={item.artworkName}
                loading="lazy"
                style={{ width: "100%", height: "auto" }}
                onClick={() => handleArtworkClick(item)}
              />
            </ImageListItem> ))}
          </ImageList>
          ) : ( 
            <p> No artwork found. </p>
          )}
        </div>
      );
    }

    const ViewConfirmation = () =>
      {
        return (
          <div className="confirmation-container">
            {/* Image on the left */}
            <img
              src={selectedArtwork.artImageUrl}
              alt={selectedArtwork.artworkName}
              loading="lazy"
              className="confirm-image"
            />
    
            {/* Artwork details on the right */}
            <div className="artwork-details">
              <div className="title-row">
                <h4>{selectedArtwork.artworkName}</h4>
                <label className="artist-name">by {selectedArtwork.artistName}</label>
              </div>
              <label>{selectedArtwork.artDescription}</label>
            </div>
          </div>
        );
      }

    const handleArtistClick = async (artist) => 
      {
        setSelectedArtist(artist);
        // filter artwork by the artist
        const filterdArtworkList = allArtwork.filter(art => art.artistDocID === artist.id)
        setArtworkList(filterdArtworkList);
      }

      const handleArtworkClick = (artwork) =>
        {
          setSelectedArtwork(artwork);
        }
	
	return (
		<Modal show={show} onHide={handleClose} centered size="lg">
    <Modal.Header closeButton className="modal-header">
      {selectedArtwork ? (
      <>
        <button className="icon-button" onClick={() => setSelectedArtwork(null)}>
          <ArrowBackIcon sx={{ fontSize: 30 }} />
        </button>
        <Modal.Title className="modal-title">Select this artwork?</Modal.Title>
      </>
    ) : selectedArtist ? (
      <>
      <button className="icon-button" onClick={() => setSelectedArtist(null)}>
        <ArrowBackIcon sx={{ fontSize: 30 }} />
      </button>
      <Modal.Title className="modal-title">{`${selectedArtist.firstName} ${selectedArtist.lastName}`}</Modal.Title>
      </>
    ) : (
      <Modal.Title className="modal-title">Artist Gallery</Modal.Title>
    )}
    </Modal.Header>
    <Modal.Body>
      <div>
        {selectedArtwork ? (
          <ViewConfirmation />
        ) : selectedArtist ? (
          <ViewArtwork/>
        ) : (
          <ViewArtists/>
        )}
      </div>
    </Modal.Body>
    <Modal.Footer>
    {selectedArtwork && (
    <>
      <Button variant="secondary" onClick={handleClose}>Cancel</Button>
      <Button variant="primary" onClick={() => onConfirm(selectedArtwork.artImageUrl)}>Confirm</Button>
    </>
  )}
    </Modal.Footer>
  </Modal>
   );
	};
  export default ArtistGalleryPopUp;
import { React, useRef, useEffect, useLayoutEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { ENDPOINT_PATH } from '../../config';



const ToolPreview = (props) => {
    const componentRef = useRef(null);

    // Value to keep track of the width of the component
    const [componentWidth, setComponentWidth] = useState(0);

    // Styles for the visualization
    const [rotationStyle, setRotationStyle] = useState({});
    const [frameContainerStyle, setFrameContainerStyle] = useState({});
    const [frameStyle, setFrameStyle] = useState({});
    const [imageContainerStyle, setImageContainerStyle] = useState({});
    const [firstMatboardStyle, setFirstMatboardStyle] = useState({});
    const [secondMatboardStyle, setSecondMatboardStyle] = useState({});
    const [thirdMatboardStyle, setThirdMatboardStyle] = useState({});
    const [doesFrameExist, setDoesFrameExist] = useState(null);



    // UseEffect to get the width of the component (runs after all DOM mutations so that
    //     width is sure to be measured after the component fully renders)
    useLayoutEffect(() => {
        if (componentRef.current) {
            setComponentWidth(componentRef.current.offsetWidth);
        }
    }, []);


    // UseEffect to calculate everything
    useEffect(() => {

        // THE MATH PART --------------------------------

        // The images we were given are measured from the outside. They're supposed to be measured from the inside. As a result, the
        //     aspect ratio is wrong, which is important because the aspect ratio of the inside of the frame NEEDS to be correct, since this
        //     will be the aspect ratio of the image we give them to print. If this aspect ratio is wrong, the image they print won't fit correctly
        //     into the frame when they build it. So the most important thing is to make the INSIDE aspect ratio correct - it's impossible to get all
        //     of the ratios correct without physically going in and manually editing each photo, but this is a compromise.
        //
        // Attempt at explaining the math:
        //
        //         What it's supposed to be:              The image we were given:                                The compromise:
        //
        //               outsideWidth                             outsideWidth2                    outsideWidth2*(insideWidth/insideWidth2)
        //                |  insideWidth                           |  insideWidth2                                  |  insideWidth2*(insideWidth/insideWidth2)=insideWidth
        //            ____v_____|______                        ____v_____|______                                  __v_______|______ 
        //           | _________v_____ |                      | _________v_____ |                                | _________v_____ |
        //           | |             | |                      | |             | |                                | |             | |
        //           | |             | |                      | |             | |                                | |             | |
        //           | |             | |<-- outsideHeight     | |             | |<-- outsideHeight2              | |             | |<-- outsideHeight*(insideHeight/insideHeight2)
        //           | |             | |                      | |             | |                                | |             | |
        //           | |             |<---- insideHeight      | |             |<---- insideHeight2               | |             |<---- insideHeight2*(insideHeight/insideHeight2)=insideHeight
        //           | |             | |                      | |             | |                                | |             | |
        //           | |             | |                      | |             | |                                | |             | |
        //           | |             | |                      | |             | |                                | |             | |
        //           | |_____________| |                      | |_____________| |                                | |_____________| |
        //           |_________________|                      |_________________|                                |_________________|
        //
        // Note: if there is a matboard, then the IW and IH increase by (2 * matboardThickness). I'm counting the matboard as part of what goes inside
        //     the frame, not as part of the frame.
        // Essentially, all we're doing is scaling the width of the whole thing by (what the width is supposed to be)/(what the width actually is),
        //     in order to get the image width to be exactly what it's supposed to be.
        // Likewise with the height, we're scaling it by (what the height is supposed to be)/(what the height actually is).

        const frameThickness    = props.frameThickness  ? props.frameThickness      : 0; // Set to 0 if undefined (it means that the NoFrame is selected)
        const matboardThickness = props.matboardNum > 0 ? props.matboards.thickness : 0; // Adding in matboard to calculation if it exists

        // insideWidth and insideHeight are given from frameSize
        const insideWidth  = props.frameSize.width;
        const insideHeight = props.frameSize.height;
        // Extrapolate outsideWidth and outsideHeight from this
        const outsideWidth  = insideWidth  + (2 * frameThickness);
        const outsideHeight = insideHeight + (2 * frameThickness);

        // outsideWidth2 and outsideHeight2 are given from frameImageSize (remember the images are measured from the outside)
        const outsideWidth2  = props.frameImageSize.width;
        const outsideHeight2 = props.frameImageSize.height;
        // Extrapolate insideWidth2 and insideHeight2 from this
        const insideWidth2  = outsideWidth2  - (2 * frameThickness);
        const insideHeight2 = outsideHeight2 - (2 * frameThickness);

        // Now we have to scale the frame image down to props.frameSize (so that insideWidth2 = insideWidth and insideHeight2 = insideHeight)
        const imageWidthScaler  = insideWidth  / insideWidth2;
        const imageHeightScaler = insideHeight / insideHeight2;
        const newInsideWidth    = insideWidth;  // insideWidth2 * imageWidthScaler = insideWidth
        const newInsideHeight   = insideHeight; // insideHeight2 * imageHeightScaler = insideHeight
        const newOutsideWidth   = outsideWidth2  * imageWidthScaler;
        const newOutsideHeight  = outsideHeight2 * imageHeightScaler;

        // Now we can find the width/height of the image inside the matboard by subtracting 2 * matboardThickness
        const imageWidth  = newInsideWidth  - (2 * matboardThickness);
        const imageHeight = newInsideHeight - (2 * matboardThickness);

        // console.log("--------------------------------");
        // console.log("imageWidthScaler = ", imageWidthScaler, "imageHeightScaler = ", imageHeightScaler);

        // console.log(`         What it's supposed to be:              The image we were given:                                The compromise:                                                                                  `);
        // console.log(`                                                                                                                                                                                                         `);
        // console.log(`               ${outsideWidth.toFixed(2)}                                  ${outsideWidth2.toFixed(2)}                                              ${newOutsideWidth.toFixed(2)}                        `);
        // console.log(`                |  ${insideWidth.toFixed(2)}                                 |    ${insideWidth2.toFixed(2)}                                        |     ${newInsideWidth.toFixed(2)}                   `);
        // console.log(`            ____v_____|______                        ____v_____|______                                  __v_______|______                                                                                `);
        // console.log(`           | _________v_____ |                      | _________v_____ |                                | _________v_____ |                                                                               `);
        // console.log(`           | |             | |                      | |             | |                                | |             | |                                                                               `);
        // console.log(`           | |             | |                      | |             | |                                | |             | |                                                                               `);
        // console.log(`           | |             | |<-- ${outsideHeight.toFixed(2)}             | |             | |<-- ${outsideHeight2.toFixed(2)}                       | |             | |<-- ${newOutsideHeight.toFixed(2)}`);
        // console.log(`           | |             | |                      | |             | |                                | |             | |                                                                               `);
        // console.log(`           | |             |<---- ${insideHeight.toFixed(2)}             | |             |<---- ${insideHeight2.toFixed(2)}                       | |             |<---- ${newInsideHeight.toFixed(2)}   `);
        // console.log(`           | |             | |                      | |             | |                                | |             | |                                                                               `);
        // console.log(`           | |             | |                      | |             | |                                | |             | |                                                                               `);
        // console.log(`           | |             | |                      | |             | |                                | |             | |                                                                               `);
        // console.log(`           | |_____________| |                      | |_____________| |                                | |_____________| |                                                                               `);
        // console.log(`           |_________________|                      |_________________|                                |_________________|                                                                               `);

        // With these new dimensions, we can find the sizes of all parts of the visualization
        // First we need to figure out how many pixels should correspond to one inch
        // This will be based on the width of the component compared to the newly-calculated width in inches of the irl frame,
        //     as well as whether we want all results to be the same size or not
        const componentWidthInches = props.frameOrientation === 'landscape' ? newOutsideHeight : newOutsideWidth;
        var pixelsPerInch = 0;

        localStorage.setItem('frameRescaled', JSON.stringify(false));
        localStorage.setItem('imageRescaled', JSON.stringify(false));

        // If we want the result to be the same size no matter what (such as in the shopping cart), sameSize will be true
        // In this case, pixelsPerInch will just be componentWidth/componentWidthInches
        if (props.sameSize) pixelsPerInch = componentWidth/componentWidthInches;

        // If we want the result to change size based on its irl size (such as in the vis tool), sameSize will be false
        // In this case, we do a little more math
        else {
            const componentWidthInches = props.frameOrientation === 'landscape' ? newOutsideHeight : newOutsideWidth;
            // We want to size everything relative to componentWidth
            // We want pixels per inch to get smaller and smaller as the size of the frame increases, instead of
            //     a pixel being equal to the same number of inches for all frames
            // This way, frames don't have to be proportional to each other, e.g. a frame that's 4x bigger than
            //     another frame won't actually be displayed 4x bigger on the screen
            // Which is ideal because otherwise the smaller frames would have to be tiny if we wanted the larger
            //     frames to be a reasonable size on the screen
            // Instead, we want smaller frames to only appear somewhat smaller than larger frames
            // Use the function 1/e^x, which intersects the y-axis before gently sloping down to an asymptote on the x-axis
            // Modify it so the slope is more ideal for this context
            // Graph in Desmos: https://www.desmos.com/calculator/kyon9re9kl
            const yIntercept = componentWidth/6; // A bit more than the number of pixels per inch we want for smallest frame size
            const horizontalAsymptote = componentWidth/40; // A bit more than the number of pixels per inch we want for largest frame size
            const numerator = yIntercept - horizontalAsymptote;
            pixelsPerInch = numerator/(Math.exp(componentWidthInches/10)) + horizontalAsymptote;
        }

        // Width/height of frame and image are simple
        const frameWidthPixels  = newOutsideWidth  * pixelsPerInch;
        const frameHeightPixels = newOutsideHeight * pixelsPerInch;
        const imageWidthPixels  = imageWidth       * pixelsPerInch;
        const imageHeightPixels = imageHeight      * pixelsPerInch;

        // console.log("frameWidthPixels = ", frameWidthPixels);
        // console.log("frameHeightPixels = ", frameHeightPixels);
        // console.log("imageWidthPixels = ", imageWidthPixels);
        // console.log("imageHeightPixels = ", imageHeightPixels);

        // Width/height of matboards is more complicated
        let firstMatboardPixels  = { width: 0, height: 0 };
        let secondMatboardPixels = { width: 0, height: 0 };
        let thirdMatboardPixels  = { width: 0, height: 0 };

        if (props.matboardNum > 0) {
            const firstMatboardWidthPixels  = newInsideWidth  * pixelsPerInch;
            const firstMatboardHeightPixels = newInsideHeight * pixelsPerInch;
            // Second/third matboards supposed to be 1/4 inch from previous one
            // Use this to find dimensions for second and third matboards
            const secondMatboardWidthPixels  = (newInsideWidth  - ((matboardThickness * 2) - (0.25 * 2))) * pixelsPerInch;
            const secondMatboardHeightPixels = (newInsideHeight - ((matboardThickness * 2) - (0.25 * 2))) * pixelsPerInch;
            const thirdMatboardWidthPixels   = (newInsideWidth  - ((matboardThickness * 2) - (0.25 * 4))) * pixelsPerInch;
            const thirdMatboardHeightPixels  = (newInsideHeight - ((matboardThickness * 2) - (0.25 * 4))) * pixelsPerInch;

            // console.log("firstMatboardWidthPixels = ", firstMatboardWidthPixels, "\tfirstMatboardHeightPixels = ", firstMatboardHeightPixels);
            // console.log("secondMatboardWidthPixels = ", secondMatboardWidthPixels, "\tsecondMatboardHeightPixels = ", secondMatboardHeightPixels);
            // console.log("thirdMatboardWidthPixels = ", thirdMatboardWidthPixels, "\tthirdMatboardHeightPixels = ", thirdMatboardHeightPixels);
            // console.log("--------------------------------");

            if (props.matboardNum === 1) {
                firstMatboardPixels = { width: firstMatboardWidthPixels, height: firstMatboardHeightPixels };
            }
            else if (props.matboardNum === 2) {
                secondMatboardPixels = { width: firstMatboardWidthPixels, height: firstMatboardHeightPixels };
                firstMatboardPixels  = { width: secondMatboardWidthPixels, height: secondMatboardHeightPixels };
            }
            else if (props.matboardNum === 3) {
                thirdMatboardPixels  = { width: firstMatboardWidthPixels, height: firstMatboardHeightPixels };
                secondMatboardPixels = { width: thirdMatboardWidthPixels, height: thirdMatboardHeightPixels };
                firstMatboardPixels  = { width: secondMatboardWidthPixels, height: secondMatboardHeightPixels };
            }
        }


        // STYLE DEFINITIONS (MORE MATH) -------------------------------

        // Define rotation style to be applied to entire surrounding div
        setRotationStyle({
            transform: props.frameOrientation === 'landscape' ? 'rotate(-90deg)' : 'none', // Rotate entire thing 90 deg LEFT if landscape
        });

        setFrameContainerStyle({
            width:  `${frameWidthPixels}px`,
            height: `${frameHeightPixels}px`,
        });

        setFrameStyle({
            width:  `100%`,
            height: `100%`,
        });

        // Find width and height of container based on percentages calculated in useEffect
        // Plus a small buffer so that even when the frame image isn't mathematically perfect, the container still fills the frame
        const bufferInInches = 1;
        const buffer = bufferInInches * pixelsPerInch;
        const containerWidth  = (props.frameOrientation === 'landscape' ? imageHeightPixels : imageWidthPixels) + buffer;
        const containerHeight = (props.frameOrientation === 'landscape' ? imageWidthPixels  : imageHeightPixels) + buffer;

        setImageContainerStyle({
            width: `${containerWidth}px`,
            height: `${containerHeight}px`,
            backgroundImage: props.uploadedImage !== "" ? `url('${props.uploadedImage}')` : 'none',
            backgroundSize: 'cover', /* Ensure the image fits within the div */
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center', /* Center the image */
            transform: props.frameOrientation === 'landscape' ? 'rotate(90deg)' : 'none', // Rotate image 90 deg RIGHT if landscape
        });

        // console.log("frameContainerStyle: ", frameContainerStyle, "\nimageContainerStyle: ", imageContainerStyle);

        // This includes a small buffer to account for images not being perfectly accurate
        setFirstMatboardStyle({
            width:  `${firstMatboardPixels.width  + buffer}px`,
            height: `${firstMatboardPixels.height + buffer}px`,
        });

        setSecondMatboardStyle({
            width:  `${secondMatboardPixels.width  + buffer}px`,
            height: `${secondMatboardPixels.height + buffer}px`,
        });

        setThirdMatboardStyle({
            width:  `${thirdMatboardPixels.width  + buffer}px`,
            height: `${thirdMatboardPixels.height + buffer}px`,
        });

        // console.log("firstMatboardStyle: ", firstMatboardStyle, "\nsecondMatboardStyle: ", secondMatboardStyle, "\nthirdMatboardStyle: ", thirdMatboardStyle);

        const checkFrame = async () => {
            const exists = await checkFrameExists(props.frameName);
            setDoesFrameExist(exists);
        };
        checkFrame();

    }, [componentWidth, props]);



    return (
        <div style={rotationStyle} ref={componentRef} className="d-flex justify-content-center align-items-center position-relative">
            {localStorage.getItem('frameRescaled') ? frameResizing(props, frameContainerStyle, frameStyle, doesFrameExist) : null/* Deals with frame sizing */}
            <div className="position-absolute z-2"> {/* Each matbpard function handles all sizing for matboards */}
                {props.matboardNum === 1 && <OneMatboard size = {props.frameImageSize} matboards={props.matboards} scaleTool={props.scaleTool} firstMatboardStyle={firstMatboardStyle} doesFrameExist = {doesFrameExist}/> }
                {props.matboardNum === 2 && <TwoMatboards size = {props.frameImageSize} matboards={props.matboards} scaleTool={props.scaleTool} firstMatboardStyle={firstMatboardStyle} secondMatboardStyle={secondMatboardStyle} doesFrameExist = {doesFrameExist}/> }
                {props.matboardNum === 3 && <ThreeMatboards size = {props.frameImageSize} matboards={props.matboards} scaleTool={props.scaleTool} firstMatboardStyle={firstMatboardStyle} secondMatboardStyle={secondMatboardStyle} thirdMatboardStyle={thirdMatboardStyle} doesFrameExist = {doesFrameExist}/> }
            </div>
            {localStorage.getItem('imageRescaled') ? imageResizing(props, imageContainerStyle, doesFrameExist) : null /* Deals with image sizing */}
        </div>
    );
};


window.addEventListener("storage", (event) => {
    if (event.key === "selectedImageId") {
        console.log("Updated Selected Image ID:", event.newValue);
        // Perform any updates (e.g., update UI or state)
    }
});

// Finds the needed scalar to get equal thickness of frames.
const getScalar = (props) => {
    const width = props.width, height = props.height;
    if (props.scaleTool === false)
    {
        return 1;
    }
    const selectedImageID = localStorage.getItem('selectedImageID');
    if (selectedImageID == 0) {
        const lookup = {
            "8_10": 2,
            "11_14": 2,
            "12_16": 2,
            "14_17": 2,
            "15_18": 2,
            "16_20": 2,
            "18_24": 2,
            "20_24": 2,
            "24_28": 2,
            "24_30": 2,
            "28_34": 2,
            "30_40": 2,
        };

        const key = `${width}_${height}`;
        return lookup[key] || 1;
    } else if ( selectedImageID == 1) {
        const lookup = {
            "8_10": .307/1,
            "11_14": 0.415/1,
            "12_16": 0.448/1,
            "14_17": 0.549/1,
            "15_18": 0.557/1,
            "16_20": 0.609/1,
            "18_24": 0.684/1,
            "20_24": 0.75/1,
            "24_28": 0.848/1,
            "24_30": 0.907/1,
            "28_34": 1/1,
            "30_40": 1/1,
        };
        const key = `${width}_${height}`;
        return lookup[key] || 1;
    } else if ( selectedImageID == 2) {
        const lookup = {
            "8_10": .307*1.5,
            "11_14": 0.415*1.5,
            "12_16": 0.448*1.5,
            "14_17": 0.549*1.5,
            "15_18": 0.557*1.5,
            "16_20": 0.609*1.5,
            "18_24": 0.684*1.5,
            "20_24": 0.75*1.5,
            "24_28": 0.848*1.5,
            "24_30": 0.907*1.5,
            "28_34": 1*1.5,
            "30_40": 1*1.5,
        };
        const key = `${width}_${height}`;
        return lookup[key] || 1;
    } else if ( selectedImageID == 3) {
        const lookup = {
            "8_10": .307*1.75,
            "11_14": 0.415*1.75,
            "12_16": 0.448*1.75,
            "14_17": 0.549*1.75,
            "15_18": 0.557*1.75,
            "16_20": 0.609*1.75,
            "18_24": 0.684*1.75,
            "20_24": 0.75*1.75,
            "24_28": 0.848*1.75,
            "24_30": 0.907*1.75,
            "28_34": 1*1.75,
            "30_40": 1*1.75,
        };
        const key = `${width}_${height}`;
        return lookup[key] || 1;
    } else if ( selectedImageID == 4) {
        const lookup = {
            "8_10": .307*.8,
            "11_14": 0.415*.8,
            "12_16": 0.448*.8,
            "14_17": 0.549*.8,
            "15_18": 0.557*.8,
            "16_20": 0.609*.8,
            "18_24": 0.684*.8,
            "20_24": 0.75*.8,
            "24_28": 0.848*.8,
            "24_30": 0.907*.8,
            "28_34": 1*.8,
            "30_40": 1*.8,
        };
        const key = `${width}_${height}`;
        return lookup[key] || 1;
    } else if ( selectedImageID == 5) {
        const lookup = {
            "8_10": .307*1.03,
            "11_14": 0.415*1.03,
            "12_16": 0.448*1.03,
            "14_17": 0.549*1.03,
            "15_18": 0.557*1.03,
            "16_20": 0.609*1.03,
            "18_24": 0.684*1.03,
            "20_24": 0.75*1.03,
            "24_28": 0.848*1.03,
            "24_30": 0.907*1.03,
            "28_34": 1*1.03,
            "30_40": 1*1.03,
        };
        const key = `${width}_${height}`;
        return lookup[key] || 1;
    }
};

const checkFrameExists = async (frameName) => {
    const response = await fetch(ENDPOINT_PATH + `/api/check-frame?frameName=${frameName}`)
    const data = await response.json();
    //console.log(`Frame URL: ${frameUrl}\nFrame name: ${frameName}\nExisting?: ${data}`);
    return data;
};

// Size the frames so that the thickness is the same amount in pixels so simulate the effect
// of being the same distance away from all frames when theyre on a wall.
const frameResizing = (props, frameContainerStyle, frameStyle, doesFrameExist) => {
    // Grab the size in pixels of the frame's width and height.
    const pixelWidth = parseFloat(frameContainerStyle.width), pixelHeight = parseFloat(frameContainerStyle.height);

    // toolScalar represents the value needed to multiply by the pixel sizes to match the thickest frame
    // in pixels to the thinnest frame in pixels.
    let toolScalar = getScalar({width: props.frameImageSize.width, height: props.frameImageSize.height, scaleTool: props.scaleTool});

    // Create an object with the current style, prepared to change the values.
    let updatedFrameContainerStyle = frameContainerStyle;

    // Assign the new width and height
    updatedFrameContainerStyle = {
        width: `${pixelWidth * toolScalar}px`,
        height: `${pixelHeight * toolScalar}px`,
    }

    // Add a conditional to stop multiple resizing from occuring
    localStorage.setItem('frameRescaled', JSON.stringify(true));

    // Check if the frame in the order still exists as a product, if not, dont return the div
    if (doesFrameExist == true) {
        return (
            <div style={updatedFrameContainerStyle} className="z-3 d-flex justify-content-center align-items-center">
                <img className='frameBox'
                style={{
                    ...frameStyle,
                    ...(props.frame === 'No_Frame.png'
                        ? {boxShadow: 'none'}
                        : {boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)"})
                }}
                src={props.frame}
                alt="frame_image"/>
            </div>
        );
    } else {
        return (
            <div style={updatedFrameContainerStyle} className="z-3 d-flex justify-content-center align-items-center">
            </div>
        );
    }
};

const imageResizing = (props, imageContainerStyle, doesFrameExist) => {
    // Grab the size of the inside image in pixels.
    const imageWidthPixels = parseFloat(imageContainerStyle.width), imageHeightPixels = parseFloat(imageContainerStyle.height);
    
    // Create the new object with the current image styles, ready to change width and height.
    let updatedImageContainerStyle = { ...imageContainerStyle};

    // toolScalar represents the value needed to multiply by the pixel sizes to match the thickest frame
    // in pixels to the thinnest frame in pixels.
    let toolScalar = getScalar({width: props.frameImageSize.width, height: props.frameImageSize.height, scaleTool: props.scaleTool});

    // Assign the new width and height values.
    updatedImageContainerStyle.width = `${imageWidthPixels * toolScalar}px`;
    updatedImageContainerStyle.height = `${imageHeightPixels * toolScalar}px`;

    // Add a conditional to stop multiple resizing from occuring
    localStorage.setItem('imageRescaled', JSON.stringify(true));
    if (doesFrameExist == true) {
        return (
            <div style={{
                ...updatedImageContainerStyle, 
                ...(props.frame === 'No_Frame.png'
                    ? {boxShadow: 'none'}
                    : {boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)"})
                }}
            className="z-2 d-flex justify-content-center align-items-center position-absolute"></div>
        );
    } else {
        return (
            <div></div>
        );
    }
};

const OneMatboard = (props) => {
    // Create the updated object, copy original properties.
    let updatedFirstMatboardStyle = {...props.firstMatboardStyle};

    // Get the pixel size of the matboard.
    const matboardwidthPixels = parseFloat(updatedFirstMatboardStyle.width), matboardHeightPixels = parseFloat(updatedFirstMatboardStyle.height);
    
    // Check for frameImageSize (stored in width and height) and assign the corresponding scalar.
    let frameScalar = getScalar({width: props.size.width, height: props.size.height, scaleTool: props.scaleTool});

    // Apply the new sizes
    updatedFirstMatboardStyle.width = `${matboardwidthPixels * frameScalar}px`;
    updatedFirstMatboardStyle.height = `${matboardHeightPixels * frameScalar}px`;

    if (props.doesFrameExist == true){
        return (
            <div className="d-flex justify-content-center align-items-center position-relative">
                <div className="position-absolute z-2">
                    <svg style={updatedFirstMatboardStyle}>
                        <rect width={5000} height={5000}  fill={props.matboards.colors[0]} />
                    </svg>
                </div>
            </div>
        );
    } else {
        return <div></div>
    }
};



const TwoMatboards = (props) => {
    // Create the updated object, copy original properties.
    let updatedFirstMatboardStyle = {...props.firstMatboardStyle};
    let updatedSecondMatboardStyle = {...props.secondMatboardStyle};

    // Get the pixel size of the matboard.
    const firstMatboardwidthPixels = parseFloat(updatedFirstMatboardStyle.width), firstMatboardHeightPixels = parseFloat(updatedFirstMatboardStyle.height);
    const secondMatboardwidthPixels = parseFloat(updatedSecondMatboardStyle.width), secondMatboardHeightPixels = parseFloat(updatedSecondMatboardStyle.height);

    // Check for frameImageSize (stored in width and height) and assign the corresponding scalar.
    let frameScalar = getScalar({width: props.size.width, height: props.size.height, scaleTool: props.scaleTool});

    // Apply the new sizes
    updatedFirstMatboardStyle.width = `${firstMatboardwidthPixels * frameScalar}px`;
    updatedFirstMatboardStyle.height = `${firstMatboardHeightPixels * frameScalar}px`;
    updatedSecondMatboardStyle.width = `${secondMatboardwidthPixels * frameScalar}px`;
    updatedSecondMatboardStyle.height = `${secondMatboardHeightPixels * frameScalar}px`;

    if (props.doesFrameExist == true){
        return (
            <div className="d-flex justify-content-center align-items-center position-relative">
                {/* First Layer */}
                <div className="position-absolute z-1">
                    <svg style={updatedFirstMatboardStyle}>
                        <rect width={5000} height={5000}  fill={props.matboards.colors[0]} />
                    </svg>
                </div>

                {/* Second Layer  -- Will have full fill */}
                <div className="position-absolute z-n1">
                    <svg style={updatedSecondMatboardStyle}>
                        <rect width={5000} height={5000}  fill={props.matboards.colors[1]} />
                    </svg>
                </div>
            </div>
        );
    } else {
        return <div></div>
    }
};



const ThreeMatboards = (props) => {
    // Create the updated object, copy original properties.
    let updatedFirstMatboardStyle = {...props.firstMatboardStyle};
    let updatedSecondMatboardStyle = {...props.secondMatboardStyle};
    let updatedThirdMatboardStyle = {...props.thirdMatboardStyle};

    // Get the pixel size of the matboard.
    const firstMatboardwidthPixels = parseFloat(updatedFirstMatboardStyle.width), firstMatboardHeightPixels = parseFloat(updatedFirstMatboardStyle.height);
    const secondMatboardwidthPixels = parseFloat(updatedSecondMatboardStyle.width), secondMatboardHeightPixels = parseFloat(updatedSecondMatboardStyle.height);
    const thirdMatboardwidthPixels = parseFloat(updatedThirdMatboardStyle.width), thirdMatboardHeightPixels = parseFloat(updatedThirdMatboardStyle.height);

    // Check for frameImageSize (stored in width and height) and assign the corresponding scalar.
    let frameScalar = getScalar({width: props.size.width, height: props.size.height, scaleTool: props.scaleTool});

    // Apply the new sizes
    updatedFirstMatboardStyle.width = `${firstMatboardwidthPixels * frameScalar}px`;
    updatedFirstMatboardStyle.height = `${firstMatboardHeightPixels * frameScalar}px`;
    updatedSecondMatboardStyle.width = `${secondMatboardwidthPixels * frameScalar}px`;
    updatedSecondMatboardStyle.height = `${secondMatboardHeightPixels * frameScalar}px`;
    updatedThirdMatboardStyle.width = `${thirdMatboardwidthPixels * frameScalar}px`;
    updatedThirdMatboardStyle.height = `${thirdMatboardHeightPixels * frameScalar}px`;

    if (props.doesFrameExist == true){
        return (
            <div className="d-flex justify-content-center align-items-center position-relative">
                {/* First Layer */}
                <div className="position-absolute z-1">
                    <svg style={updatedFirstMatboardStyle}>
                        <rect width={5000} height={5000}  fill={props.matboards.colors[0]} />
                    </svg>
                </div>

                {/* Second Layer */}
                <div className="position-absolute z-0">
                    <svg style={updatedSecondMatboardStyle}>
                        <rect width={5000} height={5000}  fill={props.matboards.colors[1]} />
                    </svg>
                </div>

                {/* Third Layer -- Will have full fill */}
                <div className="position-absolute z-n1">
                    <svg style={updatedThirdMatboardStyle}>
                        <rect width={5000} height={5000}  fill={props.matboards.colors[2]} />
                    </svg>
                </div>
            </div>
        );
    } else {
        return <div></div>
    }
};


export default ToolPreview;

import React, { useState, useEffect } from 'react';
import '../App.css';
import './artistGallery.css';
import { ENDPOINT_PATH } from '../config';

import Footer from '../components/Footer';

// Images
import productsImage from "../images/frame-home.jpg";

const openArtistProfilePage = (artistId) => {
    window.location.href = `artistProfilePage/${artistId}`;
};

const ArtistGallery = (props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [artists, setArtists] = useState([]); // Initialize with an empty array
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchArtists = async () => {
            try {
                const response = await fetch(`${ENDPOINT_PATH}/api/get-artists`);
        
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
        
                const contentType = response.headers.get('Content-Type');
                if (!contentType || !contentType.includes('application/json')) {
                    throw new Error('Expected JSON response, but received something else.');
                }
        
                const data = await response.json();
                
                if (data && data.data && Array.isArray(data.data)) {
                    setArtists(data.data);
                } else {
                    console.warn('No artists data found or invalid format.');
                    setArtists([]);  
                }
            } catch (error) {
                console.error("Error fetching artists:", error);
                setArtists([]);  
            } finally {
                setLoading(false);  // Ensure loading is set to false in all cases
            }
        };
        fetchArtists();
    }, []);

    const filteredArtists = artists.filter((artist) =>
        `${artist.firstName} ${artist.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log("Filtered Artists:", filteredArtists); 

    return (
        <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
            <div className="d-flex align-items-center ArtistHeaderImage SubHeader vstack">
                <div className="subFont text-center">artist alley</div>
                <div className="subHeaderFont text-center">browse the works of featured artists</div>
            </div>

            <div className="artist-gallery w-100">
                {/* Title */}
                <div className="d-flex flex-column align-items-center pt-5 sectionTitle-Light">
                    Browse Featured Artists
                </div>

                {/* Search Bar */}
                <div className="d-flex justify-content-center mt-3 w-100 px-3">
                    <input
                        type="text"
                        className="form-control search-bar w-100"
                        placeholder="Search artists..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {/* Loading Spinner */}
                {loading && (
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}

                {/* Artist Cards */}
                {!loading && (
                    <div className="row mt-5 w-100 mx-0">
                        {filteredArtists.map((artist) => (
                            <div key={artist.id} className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                                <div className="artist-card position-relative w-100" onClick={() => openArtistProfilePage(artist.id)}>
                                    <img
                                        src={artist.artistPictureURL}
                                        alt={`${artist.firstName} ${artist.lastName}`}
                                        className="img-fluid rounded w-100"
                                    />
                                    <div className="artist-name text-center mt-2">
                                        {`${artist.firstName} ${artist.lastName}`}
                                    </div>
                                    <div className="overlay d-flex flex-column justify-content-center align-items-center">
                                        <h5 className="text-white mb-2">{`${artist.firstName} ${artist.lastName}`}</h5>
                                        <p className="text-white text-center px-2">{artist.artistBio}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {filteredArtists.length === 0 && (
                            <div className="d-flex justify-content-center mt-5 text-muted">
                                No artists match your search.
                            </div>
                        )}
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
};

export default ArtistGallery;

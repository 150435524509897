import { useState, useEffect, useMemo, React } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TabContext from '@mui/lab/TabContext';
import Select from '@mui/material/Select';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Material UI Icons
import * as MaterialUIIcons from '@mui/icons-material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import './visualTool.css';
import { ENDPOINT_PATH } from '../../config';

// Components
import Loading from '../loading';
import ToolPreview from './toolPreview';
import Footer from '../Footer';
import ArtistGalleryPopUp from '../ArtistGalleryPopUp/ArtistGalleryPopUp';

// Hanger images
import sawtoothHangerFull from '../../images/Sawtooth_Hanger_full.jpeg';
import sawtoothHangerCloseup from '../../images/Sawtooth_Hanger_closeup.jpeg';
import securityHangerFull from '../../images/Security_Hanger_full.png';
import securityHangerCloseup from '../../images/Security_Hanger_closeup.png';
import strapHangerFull from '../../images/Strap_Hanger_full.jpeg';
import strapHangerCloseup from '../../images/Strap_Hanger_closeup.jpeg';
import nullImageVisTool from '../../images/nullPreview.png';
import testVisToolImage1 from '../../images/visToolPreview1.jpg';
import testVisToolImage2 from '../../images/visToolPreview2.jpg';
import testVisToolImage3 from '../../images/visToolPreview3.jpg';
import testVisToolImage4 from '../../images/visToolPreview4.jpg';
import testVisToolImage5 from '../../images/visToolPreview5.jpg';
import testVisToolImage6 from '../../images/visToolPreview6.jpg';


// Icons
const CropLandscapeRoundedIcon = MaterialUIIcons["CropLandscapeRounded"];
const CropPortraitRoundedIcon = MaterialUIIcons["CropPortraitRounded"];

// Preview Images
const previewImages = [
    { id: 0, src:nullImageVisTool },
    { id: 1, src:testVisToolImage1 },
    { id: 2, src:testVisToolImage2 },
    { id: 3, src:testVisToolImage4 },
    { id: 4, src:testVisToolImage5 },
    { id: 5, src:testVisToolImage6 },
];

// Values for no frame
const noFrame = process.env.PUBLIC_URL + 'No_Frame.png';
const noFrameName = "noSelectedFrame";

const VisualizationTool = (props) => {
    const navigate = useNavigate();
    
    // Get user
    const user = JSON.parse(localStorage.getItem('user'));

    // Try to fetch all cached values

    // Defined in ShoppingCart when edit button is clicked, used to determine whether an item is being edited or a new finalProduct is being made
    const finalProductID_JSON                = localStorage.getItem('FINAL_PRODUCT_ID');
    const finalProductID                     = finalProductID_JSON !== null ?                (finalProductID_JSON !== "undefined"                ? JSON.parse(finalProductID_JSON)                : undefined) : null;

    // The rest of the values associated with the vis tool
    const storedMedium_JSON                  = localStorage.getItem('MEDIUM');
    const storedMedium                       = storedMedium_JSON !== null ?                  (storedMedium_JSON !== "undefined"                  ? JSON.parse(storedMedium_JSON)                  : undefined) : null;
    const storedUploadedImage_JSON           = localStorage.getItem('UPLOADED_IMAGE');
    const storedUploadedImage                = storedUploadedImage_JSON !== null ?           (storedUploadedImage_JSON !== "undefined"           ? JSON.parse(storedUploadedImage_JSON)           : undefined) : null;
    const storedUploadedImageFileName_JSON   = localStorage.getItem('UPLOADED_IMAGE_FILE_NAME');
    const storedUploadedImageFileName        = storedUploadedImageFileName_JSON !== null ?   (storedUploadedImageFileName_JSON !== "undefined"   ? JSON.parse(storedUploadedImageFileName_JSON)   : undefined) : null;
    const storedImageSize_JSON               = localStorage.getItem('IMAGE_SIZE');
    const storedImageSize                    = storedImageSize_JSON !== null ?               (storedImageSize_JSON !== "undefined"               ? JSON.parse(storedImageSize_JSON)               : undefined) : null;
    const storedFrameOrientation_JSON        = localStorage.getItem('FRAME_ORIENTATION');
    const storedFrameOrientation             = storedFrameOrientation_JSON !== null ?        (storedFrameOrientation_JSON !== "undefined"        ? JSON.parse(storedFrameOrientation_JSON)        : undefined) : null;
    const storedFrameImage_JSON              = localStorage.getItem('FRAME_IMAGE');
    const storedFrameImage                   = storedFrameImage_JSON !== null ?              (storedFrameImage_JSON !== "undefined"              ? JSON.parse(storedFrameImage_JSON)              : undefined) : null;
    const storedSelectedFrame_JSON           = localStorage.getItem('SELECTED_FRAME');
    const storedSelectedFrame                = storedSelectedFrame_JSON !== null ?           (storedSelectedFrame_JSON !== "undefined"           ? JSON.parse(storedSelectedFrame_JSON)           : undefined) : null;
    const storedDisplayName_JSON             = localStorage.getItem('DISPLAY_NAME');
    const storedDisplayName                  = storedDisplayName_JSON !== null ?             (storedDisplayName_JSON !== "undefined"             ? JSON.parse(storedDisplayName_JSON)             : undefined) : null;
    const storedFrameThickness_JSON          = localStorage.getItem('FRAME_THICKNESS');
    const storedFrameThickness               = storedFrameThickness_JSON !== null ?          (storedFrameThickness_JSON !== "undefined"          ? JSON.parse(storedFrameThickness_JSON)          : undefined) : null;
    const storedFrameThicknessAvailable_JSON = localStorage.getItem('FRAME_THICKNESS_AVAILABLE');
    const storedFrameThicknessAvailable      = storedFrameThicknessAvailable_JSON !== null ? (storedFrameThicknessAvailable_JSON !== "undefined" ? JSON.parse(storedFrameThicknessAvailable_JSON) : undefined) : null;
    const storedFrameType_JSON               = localStorage.getItem('FRAME_TYPE');
    const storedFrameType                    = storedFrameType_JSON !== null ?               (storedFrameType_JSON !== "undefined"               ? JSON.parse(storedFrameType_JSON)               : undefined) : null;
    const storedFrameSize_JSON               = localStorage.getItem('FRAME_SIZE');
    const storedFrameSize                    = storedFrameSize_JSON !== null ?               (storedFrameSize_JSON !== "undefined"               ? JSON.parse(storedFrameSize_JSON)               : undefined) : null;
    const storedFrameImageSize_JSON          = localStorage.getItem('FRAME_IMAGE_SIZE');
    const storedFrameImageSize               = storedFrameImageSize_JSON !== null ?          (storedFrameImageSize_JSON !== "undefined"          ? JSON.parse(storedFrameImageSize_JSON)          : undefined) : null;
    const storedMatboardNum_JSON             = localStorage.getItem('MATBOARD_NUM');
    const storedMatboardNum                  = storedMatboardNum_JSON !== null ?             (storedMatboardNum_JSON !== "undefined"             ? JSON.parse(storedMatboardNum_JSON)             : undefined) : null;
    const storedMatboardThickness_JSON       = localStorage.getItem('MATBOARD_THICKNESS');
    const storedMatboardThickness            = storedMatboardThickness_JSON !== null ?       (storedMatboardThickness_JSON !== "undefined"       ? JSON.parse(storedMatboardThickness_JSON)       : undefined) : null;
    const storedSelectedMatboards_JSON       = localStorage.getItem('SELECTED_MATBOARDS');
    const storedSelectedMatboards            = storedSelectedMatboards_JSON !== null ?       (storedSelectedMatboards_JSON !== "undefined"       ? JSON.parse(storedSelectedMatboards_JSON)       : undefined) : null;
    const storedGlassType_JSON               = localStorage.getItem('GLASS_TYPE');
    const storedGlassType                    = storedGlassType_JSON !== null ?               (storedGlassType_JSON !== "undefined"               ? JSON.parse(storedGlassType_JSON)               : undefined) : null;
    const storedHangingStyle_JSON            = localStorage.getItem('HANGING_STYLE');
    const storedHangingStyle                 = storedHangingStyle_JSON !== null ?            (storedHangingStyle_JSON !== "undefined"            ? JSON.parse(storedHangingStyle_JSON)            : undefined) : null;

    // console.log("finalProductID                = ", finalProductID               );
    // console.log("storedMedium                  = ", storedMedium                 );
    // console.log("storedUploadedImage           = ", storedUploadedImage          );
    // console.log("storedUploadedImageFileName   = ", storedUploadedImageFileName  );
    // console.log("storedImageSize               = ", storedImageSize              );
    // console.log("storedFrameOrientation        = ", storedFrameOrientation       );
    // console.log("storedFrameImage              = ", storedFrameImage             );
    // console.log("storedSelectedFrame           = ", storedSelectedFrame          );
    // console.log("storedDisplayName             = ", storedDisplayName            );
    // console.log("storedFrameThickness          = ", storedFrameThickness         );
    // console.log("storedFrameThicknessAvailable = ", storedFrameThicknessAvailable);
    // console.log("storedFrameType               = ", storedFrameType              );
    // console.log("storedFrameSize               = ", storedFrameSize              );
    // console.log("storedFrameImageSize          = ", storedFrameImageSize         );
    // console.log("storedMatboardNum             = ", storedMatboardNum            );
    // console.log("storedMatboardThickness       = ", storedMatboardThickness      );
    // console.log("storedSelectedMatboards       = ", storedSelectedMatboards      );
    // console.log("storedGlassType               = ", storedGlassType              );
    // console.log("storedHangingStyle            = ", storedHangingStyle           );

    // Loading values
    const [loadingMediums, setLoadingMediums] = useState(true);
    const [loadingImageSizes, setLoadingImageSizes] = useState(true);
    const [loadingFrames, setLoadingFrames] = useState(true);
    const [loadingMatboards, setLoadingMatboards] = useState(true);
    const [loadingMatboardThicknesses, setLoadingMatboardThicknesses] = useState(true);
    const [loadingGlassTypes, setLoadingGlassTypes] = useState(true);
    const [loadingHangingStyles, setLoadingHangingStyles] = useState(true);
    let loading = loadingMediums || loadingImageSizes || loadingFrames || loadingMatboards || loadingMatboardThicknesses || loadingGlassTypes || loadingHangingStyles;
    
    // Lists/values pulled from the database
    const [mediums, setMediums] = useState([]);
    const [mediumMuiIcons, setMediumMuiIcons] = useState([]);
    const [imageSizes, setImageSizes] = useState([]);
    const [maxFrameSize, setMaxFrameSize] = useState(); // Used to disable user from making frame too large with matboards
    const [frames, setFrames] = useState([]);
    const [matboardList, setMatboardList] = useState([]);
    const [matboardThicknesses, setMatboardThicknesses] = useState([]);
    const [glassTypes, setGlassTypes] = useState([]);
    const [hangingStyles, setHangingStyles] = useState([]);
    
    // Medium values
    const [medium, setMedium] = useState(storedMedium ? storedMedium : ''); // Default = ''

    // Image values
    const [lastUploadedImage, setLastUploadedImage] = useState(''); // Used to handle when a user uploads an image but then later deselects a medium
    const [uploadedImage, setUploadedImage] = useState(storedUploadedImage ?? ''); // Default = ''
    const [uploadedImageFileName, setUploadedImageFileName] = useState(storedUploadedImageFileName ?? "No image uploaded"); // Default = "No image uploaded"
    const [imageSize, setImageSize] = useState(); // Default set later
    
    // Frame values
    const [frameOrientation, setFrameOrientation] = useState(storedFrameOrientation ?? 'portrait'); // Default = 'portrait'
    const [frameImage, setFrameImage] = useState(storedFrameImage ?? noFrame); // Default = noFrame
    const [selectedFrame, setSelectedFrame] = useState(); // Default set later
    const [displayName, setDisplayName] = useState(); // Default set later
    const [frameThickness, setFrameThickness] = useState(); // Default set later
    const [frameThicknessAvailable, setFrameThicknessAvailable] = useState(); // Default set later
    const [frameType, setFrameType] = useState(); // Default set later
    const [frameSize, setFrameSize] = useState(); // Default set later
    const [frameImageSize, setFrameImageSize] = useState(); // Default set later
    // frameImageSize can differ from frameSize when matboards are involved

    // Matboard values
    const [matboardNum, setMatboardNum] = useState(storedMatboardNum ?? 0); // Default = 0
    const [matboardThickness, setMatboardThickness] = useState(); // Default set later
    const [selectedMatboards, setSelectedMatboards] = useState(); // Default set later
    
    // Glass values
    const [glassType, setGlassType] = useState(storedGlassType ?? 'None'); // Default = 'None'
    
    // Hanging style values
    const [hangingStyle, setHangingStyle] = useState(storedHangingStyle ?? 'None'); // Default = 'None'

    // Used to disable and enable the sections of the tool
    const [matboardEnabled, setMatboardEnabled] = useState(false);
    const [accessoriesEnabled, setAccessoriesEnabled] = useState(false);
    const [glassEnabled, setGlassEnabled] = useState(false);
    const [hangingEnabled, setHangingEnabled] = useState(false);
    const [reviewEnabled, setReviewEnabled] = useState(false);
    const [frameThicknessButtonArray, setFrameThicknessButtonArray] = useState([true, true, true, true]);
    
    // Values for tabs and continue/back buttons
    const [currentStep, setCurrentStep] = useState('image');
    const [frameContinueButton, setFrameContinueButton] = useState('matboard');
    const [accessoryBackButton, setAccessoryBackButton] = useState('matboard');
    const [accessoryContinueButton, setAccessoryContinueButton] = useState("matboard");
    const [reviewBackButton, setReviewBackButton] = useState("accessories");

    // Price values
    const [mediumPrice, setMediumPrice] = useState(0);
    const [framePrice, setFramePrice] = useState(0);
    const [matboardPrices, setMatboardPrices] = useState([0, 0, 0]);
    const [glassPrice, setGlassPrice] = useState(0);
    const [hangPrice, setHangPrice] = useState(0);

    // Vis Preview Tool
    const [selectedPreview, setSelectedPreview] = useState();

    // Artist Gallery
    const [modalShow, setModalShow] = useState(false);

    // set image to selected artwork image from gallery
    const handleConfirm = (artworkUrl) => 
        {
            setUploadedImage(artworkUrl);
            window.localStorage.setItem('UPLOADED_IMAGE', JSON.stringify(artworkUrl));
            if (medium === "") {
                setMedium(mediums[0]);
                window.localStorage.setItem('MEDIUM', JSON.stringify(mediums[0]));
            }
            setModalShow(false)
        }


    // ----------------------------- Helper Functions

    // Helper method to find the next largest frame that can fit in the given dimensions (used for
    //     find the price when when matboard is added or matboard thickness is updated so frame price
    //     needs to be increased to the price for the smallest frame that can contain the image + matboard)
    const findNextLargestFrame = useMemo(() => {
        // Find the first frame that can fit the given dimensions
        return (dimensions) => {
            const nextLargestFrame = imageSizes.find(frame => frame.width >= dimensions.width && frame.height >= dimensions.height);
            return({ width: nextLargestFrame.width, height: nextLargestFrame.height });
        }
    }, [imageSizes]);


    // Helper method to split string of the format "width_height" into an object of the format
    //     {width: width, height: height}
    const splitStringIntoDimensions = (str) => {
        const [wStr, hStr] = str.split('_');
        const w  = parseFloat(wStr,  10);
        const h = parseFloat(hStr, 10);
        return {width: w, height: h};
    }

    const mergeDimensionsIntoString = (dimensions) => {
        return dimensions.width.toString().concat("_", dimensions.height.toString());
    }

    const handleImageUpload = async (event) => {
        const uploadedFile = event.target.files[0];

        setUploadedImageFileName(uploadedFile.name);
        window.localStorage.setItem('UPLOADED_IMAGE_FILE_NAME', JSON.stringify(uploadedFile.name));

        // Upload the file to Google Cloud Storage
        if (uploadedFile) {
            try {
                const formData = new FormData();
                formData.append('file', uploadedFile);
                formData.append('folder', 'uploadedImages/');

                const uploadImageRes = await fetch(ENDPOINT_PATH + '/api/upload-image', {
                    method: 'POST',
                    body: formData
                });

                if (!uploadImageRes.ok) {
                    throw new Error('Error uploading image: ' + uploadImageRes.statusText);
                }

                // Get the response for the upload-image API call (which is the url of the file on Google Cloud Storage)
                const uploadImageResData = await uploadImageRes.json();
                setUploadedImage(uploadImageResData);
                window.localStorage.setItem('UPLOADED_IMAGE', JSON.stringify(uploadImageResData));

                // If no medium has been selected yet, select the first medium by default
                if (medium === "") {
                    setMedium(mediums[0]);
                    window.localStorage.setItem('MEDIUM', JSON.stringify(mediums[0]));
                }

            } catch (error) {
                console.error(error);
            }
        }
        else {
            console.error("Error uploading file to frontend");
        }
    }

    const handleChangeCurrentStep = (event, newStep) => {
        setCurrentStep(newStep);
    };

    const handleChangeFrameOrientation = (event, newFrameOrientation) => {
        if (newFrameOrientation !== null && newFrameOrientation !== frameOrientation) {
            setFrameOrientation(newFrameOrientation);
            window.localStorage.setItem('FRAME_ORIENTATION', JSON.stringify(newFrameOrientation));
        }
    };

    const handleChangeMedium = (event, newMedium) => {
        // If medium has been unselected
        if (newMedium === null) {
            setMedium("");
            window.localStorage.setItem('MEDIUM', JSON.stringify(""));

            setLastUploadedImage(uploadedImage);

            setUploadedImage("");
            window.localStorage.setItem('UPLOADED_IMAGE', JSON.stringify(""));
        }
        // If medium was not chosen and now one is being chosen
        else if (medium === '' && newMedium !== '') {
            setMedium(newMedium);
            window.localStorage.setItem('MEDIUM', JSON.stringify(newMedium));

            setUploadedImage(lastUploadedImage);
            window.localStorage.setItem('UPLOADED_IMAGE', JSON.stringify(lastUploadedImage));
        }
        // If a medium was chosen and now a different one is being chosen
        else if (newMedium !== medium) {
            setMedium(newMedium);
            window.localStorage.setItem('MEDIUM', JSON.stringify(newMedium));
        }
    };

    const handleChangeImageSize = (event, newImageSizeStr) => {
        const newImageSize = splitStringIntoDimensions(newImageSizeStr);

        setImageSize(newImageSize);
        window.localStorage.setItem('IMAGE_SIZE', JSON.stringify(newImageSize));

        var frameWidthWithMatboard  = parseInt(newImageSize.width  + (matboardThickness * 2), 10);
        var frameHeightWithMatboard = parseInt(newImageSize.height + (matboardThickness * 2), 10);
        // Changing imageSize could cause the frame to become too big if you previously had a
        //     matboard thickness selected that if combined with this new image thickness
        //     would cause the frame to be too big, then changed to 0 matboards, then selected
        //     this frame thickness, then selected any number of matboards again
        // If this would end up being the case, change matboardThickness to the next smallest
        //     thickness that would not make the frame too big
        var i = matboardThicknesses.length - 1;
        // Go backwards through valid matboard thicknesses
        // (This while loop will only ever start if the edge case described above is happening)
        while (i >= 0 && (frameWidthWithMatboard > maxFrameSize.width || frameHeightWithMatboard > maxFrameSize.height)) {
            if (matboardThicknesses[i] >= matboardThickness) {
                // Keep going till we get to the one lower than the current matboard thickness
                // (It is impossible for the current matboard thickness to be the smallest one,
                //     because if it were, it would be impossible for the user to even select
                //     this image size)
                i--;
                continue;
            }
            frameWidthWithMatboard  = parseInt(newImageSize.width  + (matboardThicknesses[i] * 2), 10);
            frameHeightWithMatboard = parseInt(newImageSize.height + (matboardThicknesses[i] * 2), 10);

            setMatboardThickness(matboardThicknesses[i]);
            window.localStorage.setItem('MATBOARD_THICKNESS', JSON.stringify(matboardThicknesses[i]));

            i--;
        }

        // Handle resizing the frame if matboards are involved
        if (matboardNum > 0) {
            const newFrameWidth  = parseInt(newImageSize.width  + (matboardThickness * 2), 10);
            const newFrameHeight = parseInt(newImageSize.height + (matboardThickness * 2), 10);

            setFrameSize({ width: newFrameWidth, height: newFrameHeight });
            window.localStorage.setItem('FRAME_SIZE', JSON.stringify({ width: newFrameWidth, height: newFrameHeight }));

            // Since there's matboards, the frame size is not standard, so
            //     the frame image needs to be the next biggest frame
            const newFrameImageSize = findNextLargestFrame({ width: newFrameWidth, height: newFrameHeight });
            setFrameImageSize(newFrameImageSize);
            window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify(newFrameImageSize));

            // If sawtooth hanger was chosen and new size is greater than 16x20, set hanging style to none (sawtooth hanger can
            //     only be used on frames 16x20 or smaller)
            if (hangingStyle === "Sawtooth" && (newFrameWidth > 16 || newFrameHeight > 20)) {
                setHangingStyle("None");
                window.localStorage.setItem('HANGING_STYLE', JSON.stringify("None"));
            }
        }
        // Otherwise, frameSize and frameImageSize just equal imageSize
        else {
            setFrameSize({ width: newImageSize.width, height: newImageSize.height });
            window.localStorage.setItem('FRAME_SIZE', JSON.stringify({ width: newImageSize.width, height: newImageSize.height }));

            setFrameImageSize({ width: newImageSize.width, height: newImageSize.height });
            window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify({ width: newImageSize.width, height: newImageSize.height }));

            // If sawtooth hanger was chosen and new size is greater than 16x20, set hanging style to none (sawtooth hanger can
            //     only be used on frames 16x20 or smaller)
            if (hangingStyle === "Sawtooth" && (newImageSize.width > 16 || newImageSize.height > 20)) {
                setHangingStyle("None");
                window.localStorage.setItem('HANGING_STYLE', JSON.stringify("None"));
            }
        }
    }

    const handleChangeMatboardNum = (event, newMatboardNum) => {
        // If we're adding a new matboard, we need to update the frameSize
        if (matboardNum === 0 && newMatboardNum > 0) {
            const newFrameWidth  = imageSize.width  + (2 * matboardThickness);
            const newFrameHeight = imageSize.height + (2 * matboardThickness);

            setFrameSize({ width: newFrameWidth, height: newFrameHeight });
            window.localStorage.setItem('FRAME_SIZE', JSON.stringify({ width: newFrameWidth, height: newFrameHeight }));

            // Since there's matboards, the frame size is not standard, so
            //     the frame image needs to be the next biggest frame
            const newFrameImageSize = findNextLargestFrame({ width: newFrameWidth, height: newFrameHeight });
            setFrameImageSize(newFrameImageSize);
            window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify(newFrameImageSize));

            // If sawtooth hanger was chosen and new size is greater than 16x20, set hanging style to none (sawtooth hanger can
            //     only be used on frames 16x20 or smaller)
            if (hangingStyle === "Sawtooth" && (newFrameWidth > 16 || newFrameHeight > 20)) {
                setHangingStyle("None");
                window.localStorage.setItem('HANGING_STYLE', JSON.stringify("None"));
            }
        }

        // If we're removing all matboards, there's no matboards so frameSize and frameImageSize just equal imageSize
        if (matboardNum > 0 && newMatboardNum === 0) {
            setFrameSize({ width: imageSize.width, height: imageSize.height });
            window.localStorage.setItem('FRAME_SIZE', JSON.stringify({ width: imageSize.width, height: imageSize.height }));

            setFrameImageSize({ width: imageSize.width, height: imageSize.height });
            window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify({ width: imageSize.width, height: imageSize.height }));
        }

        setMatboardNum(newMatboardNum);
        window.localStorage.setItem('MATBOARD_NUM', JSON.stringify(newMatboardNum));
    }

    const handleChangeMatboardThickness = (event, newMatboardThickness) => {
        // If we're changing the matboard thickness, we need to update the frameSize
        if (matboardThickness !== newMatboardThickness) {
            const newFrameWidth  = imageSize.width  + (2 * newMatboardThickness);
            const newFrameHeight = imageSize.height + (2 * newMatboardThickness);

            setFrameSize({ width: newFrameWidth, height: newFrameHeight });
            window.localStorage.setItem('FRAME_SIZE', JSON.stringify({ width: newFrameWidth, height: newFrameHeight }));

            const newFrameImageSize = findNextLargestFrame({ width: newFrameWidth, height: newFrameHeight });
            setFrameImageSize(newFrameImageSize);
            window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify(newFrameImageSize));

            // If sawtooth hanger was chosen and new size is greater than 16x20, set hanging style to none (sawtooth hanger can
            //     only be used on frames 16x20 or smaller)
            if (hangingStyle === "Sawtooth" && (newFrameWidth > 16 || newFrameHeight > 20)) {
                setHangingStyle("None");
                window.localStorage.setItem('HANGING_STYLE', JSON.stringify("None"));
            }
        }

        setMatboardThickness(newMatboardThickness);
        window.localStorage.setItem('MATBOARD_THICKNESS', JSON.stringify(newMatboardThickness));
    }

    const handleChangeSelectedMatboards = (event, newMatboardInternalName, indexToChange) => {
        // Find the chosen matboard
        for (var i = 0; i < matboardList.length; i++) {
            if (matboardList[i].internalName === newMatboardInternalName) {
                // Copy selectedMatboards and replace the given index with the given value
                const updatedMatboards = [...selectedMatboards];
                updatedMatboards[indexToChange] = matboardList[i];

                setSelectedMatboards(updatedMatboards);
                window.localStorage.setItem('SELECTED_MATBOARDS', JSON.stringify(updatedMatboards));

                break;
            }
        }
    }

    const handleAddToCart = async (event) => {
        // Create title for final product
        var title = "";
        // Start with medium if applicable
        if (medium !== "") title += `${imageSize.width}" x ${imageSize.height}" ${medium}`;
        // If no medium, add frame size instead
        else title += `${frameSize.width}" x ${frameSize.height}" `;
        // If frame, add frame name
        if (selectedFrame !== noFrameName) {
            if (medium !== "") title += ` with `;
            title += `${displayName} frame`;
            // If matboards, add matboards
            if (matboardNum === 1) title += ` and 1 matboard`;
            if (matboardNum > 1)   title += ` and ${matboardNum} matboards`;
        }

        try {
            // Make final product object
            const finalProduct = {
                medium: medium,
                uploadedImage: uploadedImage,
                uploadedImageFileName: uploadedImageFileName,
                imageSize: imageSize,
                frameOrientation: frameOrientation,
                frameImage: frameImage,
                selectedFrame: selectedFrame,
                displayName: displayName,
                frameThickness: frameThickness,
                frameThicknessAvailable: frameThicknessAvailable,
                frameType: frameType,
                frameSize: frameSize,
                frameImageSize: frameImageSize,
                matboardNum: matboardNum,
                matboardThickness: matboardThickness,
                selectedMatboards: selectedMatboards,
                glassType: glassType,
                hangingStyle: hangingStyle,
                mediumPrice: mediumPrice,
                framePrice: framePrice,
                matboardPrices: matboardPrices,
                glassPrice: glassPrice,
                hangPrice: hangPrice,
                title: title
            };

            // Add final product to Final-Products collection (or update it if it already exists)
            const finalProductRes = await fetch(ENDPOINT_PATH + '/api/create-final-product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    finalProductID: finalProductID,
                    finalProduct: finalProduct
                }),
            });

            if (!finalProductRes.ok) {
                throw new Error('Error creating final product: ' + finalProductRes.statusText);
            }

            // Get the response for the create-final-product API call
            const finalProductResData = await finalProductRes.json();

            // Add the created final product to the user's shopping cart (ONLY if we're creating a new finalProduct)
            if (!finalProductID) {
                const shoppingCartRes = await fetch(ENDPOINT_PATH + '/api/add-to-shopping-cart', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userID: user.uid,
                        finalProductID: finalProductResData
                    }),
                });

                if (!shoppingCartRes.ok) {
                    throw new Error('Error adding final product to shopping cart: ' + shoppingCartRes.statusText);
                }
            }

            console.log("Successfully added the final product to the shopping cart");

            // Remove all cached values from cache
            window.localStorage.removeItem('FINAL_PRODUCT_ID');
            window.localStorage.removeItem('MEDIUM');
            window.localStorage.removeItem('UPLOADED_FILE');
            window.localStorage.removeItem('UPLOADED_IMAGE');
            window.localStorage.removeItem('UPLOADED_IMAGE_FILE_NAME');
            window.localStorage.removeItem('IMAGE_SIZE');
            window.localStorage.removeItem('FRAME_ORIENTATION');
            window.localStorage.removeItem('FRAME_IMAGE');
            window.localStorage.removeItem('FRAME_THICKNESS_AVAILABLE');
            window.localStorage.removeItem('SELECTED_FRAME');
            window.localStorage.removeItem('DISPLAY_NAME');
            window.localStorage.removeItem('FRAME_THICKNESS');
            window.localStorage.removeItem('FRAME_TYPE');
            window.localStorage.removeItem('FRAME_SIZE');
            window.localStorage.removeItem('FRAME_IMAGE_SIZE');
            window.localStorage.removeItem('SELECTED_MATBOARDS');
            window.localStorage.removeItem('MATBOARD_NUM');
            window.localStorage.removeItem('MATBOARD_THICKNESS');
            window.localStorage.removeItem('GLASS_TYPE');
            window.localStorage.removeItem('HANGING_STYLE');

            // Go to checkout page
            navigate('/checkout');

        } catch (error) {
            console.error(error);
        }
    }

    // ----------------------------- UseEffects to handle tabs and continue/back buttons

    // UseEffect to set the initial values for the tabs on load and to handle appropriate changes when frameType is updated
    useEffect(() => {
        if (frameType === "standard") {
            // Enable matboards
            setMatboardEnabled(true);

            // Enable glass
            setGlassEnabled(true);

            // Enable hangers
            setHangingEnabled(true);

            // Enable review
            setReviewEnabled(true);
        }
        else if (frameType === "floater") {
            // Disable matboards
            setMatboardEnabled(false);

            // Disable glass
            setGlassEnabled(false);

            // Enable hangers
            setHangingEnabled(true);

            // Enable review
            setReviewEnabled(false);
        }
        else if (frameType === "") { // No frame
            // Disable matboards
            setMatboardEnabled(false);

            // Disable glass
            setGlassEnabled(false);

            // Enable hangers if medium is Canvas, disable otherwise
            if (medium === "Canvas") setHangingEnabled(true);
            else setHangingEnabled(false);

            // Enable review if medium is chosen, disable otherwise
            if (medium !== "") setReviewEnabled(true);
            else setReviewEnabled(false);
        }

    }, [frameType, medium]);


    // UseEffect to handle appropriate changes when medium changes
    useEffect(() => {
        if (!loading) {
            if (medium === "Photo Print") {
                // Photo prints can't have floater frames
                if (frameType === "floater") {
                    // Set the frame type to standard and choose the first available standard frame
                    setFrameType("standard");
                    window.localStorage.setItem('FRAME_TYPE', JSON.stringify("standard"));
                    for (var i = 0; i < frames.length; i++) {
                        if (frames[i].frameType === "standard") {
                            setSelectedFrame(frames[i].frameName);
                            window.localStorage.setItem('SELECTED_FRAME', JSON.stringify(frames[i].frameName));

                            setDisplayName(frames[i].displayName);
                            window.localStorage.setItem('DISPLAY_NAME', JSON.stringify(frames[i].displayName));

                            setFrameThicknessAvailable(frames[i].frameThicknessAvailable);
                            window.localStorage.setItem('FRAME_THICKNESS_AVAILABLE', JSON.stringify(frames[i].frameThicknessAvailable));

                            setFrameThickness(frames[i].frameThicknessAvailable[0]);
                            window.localStorage.setItem('FRAME_THICKNESS', JSON.stringify(frames[i].frameThicknessAvailable[0]));

                            break;
                        }
                    }
                }
                // Enable matboards if frame is chosen, otherwise disable matboards
                if (frameType !== '') setMatboardEnabled(true);
                else setMatboardEnabled(false);

                // Enable glass if frame is chosen, otherwise disable glass
                if (frameType !== '') setGlassEnabled(true);
                else setGlassEnabled(false);

                // Enable hangers if frame is chosen, otherwise disable hangers
                if (frameType !== '') setHangingEnabled(true);
                else setHangingEnabled(false);

                // Enable review
                setReviewEnabled(true);
            }

            else if (medium === "Canvas") {
                // Disable matboards
                setMatboardEnabled(false);

                // Remove matboard if there is one
                // No matboard means frameSize and frameImageSize just equal imageSize
                setFrameSize({ width: imageSize.width, height: imageSize.height });
                window.localStorage.setItem('FRAME_SIZE', JSON.stringify({ width: imageSize.width, height: imageSize.height }));
                setFrameImageSize({ width: imageSize.width, height: imageSize.height });
                window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify({ width: imageSize.width, height: imageSize.height }));
                setMatboardNum(0);
                window.localStorage.setItem('MATBOARD_NUM', JSON.stringify(0));

                // Disable glass
                setGlassEnabled(false);

                // Enable hangers
                setHangingEnabled(true);

                // Enable review
                setReviewEnabled(true)
            }

            else if (medium === "") { // No image uploaded
                // Disable matboards
                setMatboardEnabled(false);

                // Disable glass
                setGlassEnabled(false);

                // Disable hangers unless frame is chosen
                if (frameType !== '') setHangingEnabled(false);

                // Enable review only if frame is chosen
                if (frameType !== '') setReviewEnabled(true);
                else setReviewEnabled(false);
            }
        }

    }, [loading, medium, frameType, frames, imageSize, findNextLargestFrame]);


    // UseEffect to change continue/back buttons based on what's enabled/disabled
    useEffect(() => {
        if (!loading) {
            // Set frame continue button
            if (matboardEnabled) setFrameContinueButton('matboard');
            else if (accessoriesEnabled) setFrameContinueButton('accessories');
            else if (reviewEnabled) setFrameContinueButton('review');
            else setFrameContinueButton('');

            // Set accessory back button
            if (matboardEnabled) setAccessoryBackButton('matboard');
            else setAccessoryBackButton('frame');

            // Set accessory continue button
            if (reviewEnabled) setAccessoryContinueButton('review');
            else setAccessoryContinueButton('');

            // Set review back button
            if (accessoriesEnabled) setReviewBackButton('accessories');
            else if (matboardEnabled) setReviewBackButton('matboard');
            else setReviewBackButton('frame');
        }

    }, [loading, matboardEnabled, glassEnabled, accessoriesEnabled, reviewEnabled]);


    // UseEffect to set accessoriesEnabled
    useEffect(() => {
        setAccessoriesEnabled(glassEnabled || hangingEnabled);
    }, [glassEnabled, hangingEnabled]);


    // ----------------------------- UseEffects to get the forms and tool working

    // UseEffect to get the list of valid mediums
    useEffect(() => {
        const getMediums = () => {
            fetch(ENDPOINT_PATH + `/api/mediums`)
            .then(response => response.json())
            .then(data => {
                // Extract the mediums from the objects
                const mediumsArray = data.map(medium => medium.mediumName);
                setMediums(mediumsArray);

                // Extract the MUI icons from the objects
                const muiIconsArray = data.map(medium => medium.muiIcon);
                setMediumMuiIcons(muiIconsArray);

                setLoadingMediums(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingMediums(false);
            });
        }

        // If matboard thicknesses array is empty, fill it
        if (mediums.length === 0) {
            getMediums();
        }
        else {
            setLoadingMediums(false);
        }

    }, [mediums.length]);


    // UseEffect to get the list of valid image sizes
    useEffect(() => {
        const getImageSizes = () => {
            fetch(ENDPOINT_PATH + `/api/image-sizes`)
            .then(response => response.json())
            .then(data => {
                // Sort image sizes by area (width * height)
                const sortedSizes = data.sort((a, b) => a.width * a.height - b.width * b.height);

                setImageSizes(sortedSizes);

                if (storedImageSize) setImageSize(storedImageSize);
                else {
                    setImageSize({ width: sortedSizes[0].width, height: sortedSizes[0].height }); // Default = { width: sortedSizes[0].width, height: sortedSizes[0].height }
                    window.localStorage.setItem('IMAGE_SIZE', JSON.stringify({ width: sortedSizes[0].width, height: sortedSizes[0].height }));
                }

                if (storedFrameSize) setFrameSize(storedFrameSize);
                else {
                    setFrameSize({ width: sortedSizes[0].width, height: sortedSizes[0].height }); // Default = { width: sortedSizes[0].width, height: sortedSizes[0].height }
                    window.localStorage.setItem('FRAME_SIZE', JSON.stringify({ width: sortedSizes[0].width, height: sortedSizes[0].height }));
                }

                if (storedFrameImageSize) setFrameImageSize(storedFrameImageSize);
                else {
                    setFrameImageSize({ width: sortedSizes[0].width, height: sortedSizes[0].height });  // Default = { width: sortedSizes[0].width, height: sortedSizes[0].height }
                    window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify({ width: sortedSizes[0].width, height: sortedSizes[0].height }));
                }

                setMaxFrameSize({ width: sortedSizes[sortedSizes.length - 1].width, height: sortedSizes[sortedSizes.length - 1].height });

                setLoadingImageSizes(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingImageSizes(false);
            });
        }

        // If image sizes array is empty, fill it
        if (imageSizes.length === 0) {
            getImageSizes();
        }
        else {
            setLoadingImageSizes(false);
        }

    }, [imageSizes.length, storedImageSize, storedFrameSize, storedFrameImageSize]);


    // UseEffect to get the list of frames
    useEffect(() => {
        const getFrames = () => {
            fetch(ENDPOINT_PATH + `/api/frames`)
            .then(response => response.json())
            .then(data => {
                // Find the noFrame frame
                for (var i = 0; i < data.length; i++) {
                    if (data[i].frameName === noFrameName) {
                        // If there's no cached frame values, set to default values (the noFrame values)
                        if (storedSelectedFrame) setSelectedFrame(storedSelectedFrame);
                        else {
                            setSelectedFrame(data[i].frameName);
                            window.localStorage.setItem('SELECTED_FRAME', JSON.stringify(data[i].frameName));
                        }
                        
                        if (storedDisplayName) setDisplayName(storedDisplayName);
                        else {
                            setDisplayName(data[i].displayName);
                            window.localStorage.setItem('DISPLAY_NAME', JSON.stringify(data[i].displayName));
                        }

                        if (storedFrameThicknessAvailable) setFrameThicknessAvailable(storedFrameThicknessAvailable);
                        else {
                            setFrameThicknessAvailable(data[i].frameThicknessAvailable);
                            window.localStorage.setItem('FRAME_THICKNESS_AVAILABLE', JSON.stringify(data[i].frameThicknessAvailable));
                        }

                        if (storedFrameType) setFrameType(storedFrameType);
                        else {
                            setFrameType(data[i].frameType);
                            window.localStorage.setItem('FRAME_TYPE', JSON.stringify(data[i].frameType));
                        }

                        if (storedFrameThickness) setFrameThickness(storedFrameThickness);

                        // Move the noFrame to the front of the list
                        const noFrame = data[i];
                        delete data[i];
                        setFrames([noFrame].concat(data));

                        break;
                    }
                }
                setLoadingFrames(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingFrames(false);
            });
        }

        // If frame icons array is empty, fill it
        if (frames.length === 0)  {
            getFrames();
        }
        else {
            setLoadingFrames(false);
        }

    }, [frames.length, storedSelectedFrame, storedDisplayName, storedFrameThicknessAvailable, storedFrameType, storedFrameThickness]);


    // UseEffect to get the list of matboards
    useEffect(() => {
        const getMatboardList = () => {
            fetch(ENDPOINT_PATH + `/api/matboards`)
            .then(response => response.json())
            .then(data => {
                setMatboardList(data);

                if (storedSelectedMatboards) setSelectedMatboards(storedSelectedMatboards);
                else {
                    setSelectedMatboards([data[0], data[1], data[2]]); // Default = [data[0], data[1], data[2]]
                    window.localStorage.setItem('SELECTED_MATBOARDS', JSON.stringify([data[0], data[1], data[2]]));
                }

                setLoadingMatboards(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingMatboards(false);
            });
        }

        // If matboard array is empty, fill it
        if (matboardList.length === 0) {
            getMatboardList();
        }
        else {
            setLoadingMatboards(false);
        }

    }, [matboardList.length, storedSelectedMatboards]);


    // UseEffect to get the list of valid matboard thicknesses
    useEffect(() => {
        const getMatboardThicknesses = () => {
            fetch(ENDPOINT_PATH + `/api/matboard-thicknesses`)
            .then(response => response.json())
            .then(data => {
                // Sort matboard thicknesses
                const sortedThicknesses = data.sort((a, b) => a - b);
                setMatboardThicknesses(sortedThicknesses);

                if (storedMatboardThickness) setMatboardThickness(storedMatboardThickness);
                else {
                    setMatboardThickness(sortedThicknesses[0]); // Default = sortedThicknesses[0]
                    window.localStorage.setItem('MATBOARD_THICKNESS', JSON.stringify(sortedThicknesses[0]));
                }

                setLoadingMatboardThicknesses(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingMatboardThicknesses(false);
            });
        }

        // If matboard thicknesses array is empty, fill it
        if (matboardThicknesses.length === 0) {
            getMatboardThicknesses();
        }
        else {
            setLoadingMatboardThicknesses(false);
        }

    }, [matboardThicknesses.length, storedMatboardThickness]);


    // UseEffect to get the list of valid glass types
    useEffect(() => {
        const getGlassTypes = () => {
            // First add the "None" option
            var glassTypesList = ["None"];

            // Then fetch the rest of the types
            fetch(ENDPOINT_PATH + `/api/glass-types`)
            .then(response => response.json())
            .then(data => {
                // Add all the fetched glass types to the list of glass types
                setGlassTypes(glassTypesList.concat(data));
                setLoadingGlassTypes(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingGlassTypes(false);
            });
        }

        // If matboard thicknesses array is empty, fill it
        if (glassTypes.length === 0) {
            getGlassTypes();
        }
        else {
            setLoadingGlassTypes(false);
        }

    }, [glassTypes.length]);


    // UseEffect to get the list of valid hanging styles
    useEffect(() => {
        const getHangingStyles = () => {
            // First add the "None" option
            var hangingStylesList = ["None"];

            // Then fetch the rest of the styles
            fetch(ENDPOINT_PATH + `/api/hanging-styles`)
            .then(response => response.json())
            .then(data => {
                // Add all the fetched hanging styles to the list of hanging styles
                setHangingStyles(hangingStylesList.concat(data));
                setLoadingHangingStyles(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingHangingStyles(false);
            });
        }

        // If matboard thicknesses array is empty, fill it
        if (hangingStyles.length === 0) {
            getHangingStyles();
        }
        else {
            setLoadingHangingStyles(false);
        }

    }, [hangingStyles.length]);


    // UseEffect to update the frame image
    useEffect(() => {
        const updateFrameImage = () => {
            // If the selected frame is the noFrame, set the frame image to the noFrame image
            if (selectedFrame === noFrameName) {
                setFrameImage(noFrame);
                window.localStorage.setItem('FRAME_IMAGE', JSON.stringify(noFrame));
            }
            // Else, fetch the frame image from the database given the current parameters
            else {
                // Fetch the image
                fetch(ENDPOINT_PATH + `/api/frame-image?frameType=` + encodeURIComponent(frameType) + `&frameName=` + encodeURIComponent(selectedFrame)
                + `&frameSize=` + encodeURIComponent(mergeDimensionsIntoString(frameImageSize)) + `&frameThickness=` + encodeURIComponent(frameThickness))
                .then(response => response.json())
                .then(data => {
                    if (data === false) {
                        setFrameImage(noFrame);
                        window.localStorage.setItem('DISPLAY_NAME', JSON.stringify("No frame"));
                        window.localStorage.setItem('SELECTED_FRAME', JSON.stringify("noSelectedFrame"));
                        window.localStorage.setItem('FRAME_TYPE', JSON.stringify(""));
                        window.localStorage.setItem('FRAME_THICKNESS', undefined);
                        window.localStorage.setItem('FRAME_THICKNESS_AVAILABLE', JSON.stringify([]));
                        window.localStorage.setItem('FRAME_IMAGE', JSON.stringify(noFrame));
                    } else {
                        setFrameImage(data);
                        window.localStorage.setItem('FRAME_IMAGE', JSON.stringify(data));
                    }
                })
                .catch(e => { console.log(e); });
            }
        }

        if (!loading) updateFrameImage();

    }, [selectedFrame, frameType, frameImageSize, frameThickness, loading]);
    

    // UseEffect for the change of the thickness form when frameThicknessAvailable changes
    useEffect(() => {
        const findFrameThicknessButtonValues = () => {
            // When frameThicknessAvailable changes, check if the selected frame matches the selected frame thickness
            // If not, then update the selected frame to be the first option of the new thickness
            // Set the values of the thickness buttons
            let tempFrameThicknessButtonArray = [false, false, false, false];

            if (frameThicknessAvailable.includes(1)) tempFrameThicknessButtonArray[0] = true;
            else tempFrameThicknessButtonArray[0] = false;

            if (frameThicknessAvailable.includes(1.25)) tempFrameThicknessButtonArray[1] = true;
            else tempFrameThicknessButtonArray[1] = false;

            if (frameThicknessAvailable.includes(1.5)) tempFrameThicknessButtonArray[2] = true;
            else tempFrameThicknessButtonArray[2] = false;

            if (frameThicknessAvailable.includes(2)) tempFrameThicknessButtonArray[3] = true;
            else tempFrameThicknessButtonArray[3] = false;

            setFrameThicknessButtonArray(tempFrameThicknessButtonArray);
        }

        if (!loading) findFrameThicknessButtonValues();
        
    }, [frameThicknessAvailable, loading]);


    // UseEffect to remove matboards when the NoFrame is selected
    useEffect(() => {
        if (!loading) {
            if (selectedFrame === noFrameName) {
                // Set matboardNum to 0
                setMatboardNum(0);

                // Adjust frameSize and FrameImageSize accordingly
                setFrameSize({ width: imageSize.width, height: imageSize.height });
                window.localStorage.setItem('FRAME_SIZE', JSON.stringify({ width: imageSize.width, height: imageSize.height }));

                setFrameImageSize({ width: imageSize.width, height: imageSize.height });
                window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify({ width: imageSize.width, height: imageSize.height }));
            }
        }
    }, [selectedFrame, imageSize]);


    // --------------------------------- UseEffects to Display Product Pricing ---------------------------------

    // UseEffect to update the price of the medium when the frame or size changes
    useEffect(() => {
        const findMediumPrice = () => {
            if (medium === "" || uploadedImage === "" || loading) {
                setMediumPrice(0);
            }
            else {
                fetch(ENDPOINT_PATH + `/api/medium-price?medium=` + encodeURIComponent(medium) + `&imageSize=` + encodeURIComponent(mergeDimensionsIntoString(imageSize)))
                .then(response => response.json())
                .then(data => { setMediumPrice(data); })
                .catch(e => { console.log(e); });
            }
        }

        findMediumPrice();

    }, [uploadedImage, loading, medium, imageSizes, imageSize]);


    // UseEffect to update the price of the frame when the frame or size changes
    useEffect(() => {
        const findFramePrice = () => {
            if (selectedFrame === noFrameName || loading) {
                setFramePrice(0);
            }
            else {
                fetch(ENDPOINT_PATH + `/api/frame-price?frameName=` + encodeURIComponent(selectedFrame) + `&frameSize=` + encodeURIComponent(mergeDimensionsIntoString(frameSize)) + `&imageSize=` + encodeURIComponent(mergeDimensionsIntoString(imageSize)))
                .then(response => response.json())
                .then(data => { 
                    if (data === false) {
                        setFramePrice(0);
                    } else {
                        setFramePrice(data);
                    }
                })
                .catch(e => { console.log(e); });
            }
        }
        findFramePrice();

    }, [loading, selectedFrame, frameSize, imageSize]);


    // UseEffect to find the prices of each style of matboard when the frame size or selected matboards change
    useEffect(() => {
        const findMatboardPrices = () => {
            if (matboardNum === 0 || loading) {
                setMatboardPrices([0, 0, 0]);
            }
            else {
                for (var i = 0; i < matboardNum; i++) {
                    (function(index) {
                        fetch(ENDPOINT_PATH + `/api/matboard-price?frameSize=` + encodeURIComponent(mergeDimensionsIntoString(frameImageSize)) + '&matboardStyle=' + encodeURIComponent(selectedMatboards[i].style))
                        .then(response => response.json())
                        .then(data => {
                            setMatboardPrices(prevMatboardPrices => {
                                const newMatboardPrices = [...prevMatboardPrices];
                                newMatboardPrices[index] = data;
                                return newMatboardPrices;
                            });
                        })
                        .catch(e => { console.log(e); });
                    })(i);
                }
            }
        }
        findMatboardPrices();

    }, [matboardNum, loading, frameImageSize, selectedMatboards]);


    // UseEffect to update the price of the glass when type or size changes
    useEffect(() => {
        const findGlassPrice = () => {
            if (glassType === "None" || loading) {
                setGlassPrice(0);
            }
            else {
                fetch(ENDPOINT_PATH + `/api/glass-price?glassType=` + encodeURIComponent(glassType)
                + `&frameSize=` + encodeURIComponent(mergeDimensionsIntoString(frameImageSize)))
                .then(response => response.json())
                .then(data => { setGlassPrice(data); })
                .catch(e => { console.log(e); });
            }
        }
        findGlassPrice();

    }, [glassType, loading, frameImageSize]);


    // UseEffect to update the price of the hanging when hanging style changes
    useEffect(() => {
        const findHangingPrice = () => {
            if (hangingStyle === "None" || loading) {
                setHangPrice(0);
            }
            else {
                fetch(ENDPOINT_PATH + `/api/hanging-price?hangingStyle=` + encodeURIComponent(hangingStyle))
                .then(response => response.json())
                .then(data => { setHangPrice(data); })
                .catch(e => { console.log(e); });
            }
        }
        findHangingPrice();

    }, [hangingStyle, loading]);

    useEffect(() => {
        // Retrieve from localStorage on mount
        let storedID;
        if (localStorage.getItem("selectedImageID") !== null) {
            storedID = parseInt(localStorage.getItem("selectedImageID"))
            const storedImage = previewImages.find(image => image.id === storedID)
            setSelectedPreview(storedImage?.src);
            console.log('exists');
        } else {
            storedID = parseInt(localStorage.setItem("selectedImageID", 0)) // Sets default to 0
            const storedImage = previewImages.find(image => image.id === 0)
            setSelectedPreview(storedImage?.src);
        }
      }, []);


    // Render loading state until data is fetched
    if (loading) {
        return <Loading/>;
    }

    // This returns a style that changes where the frame is situated on the vis tool
    const getStyleForPreview = (ID) => {
        console.log('Id is ', ID);
        let style;
        if (ID == 0) {
            style = {
                left: '50%',
                top: '0%',
                transform: 'Translate(-50%)',
            }
        } else if (ID == 1) {
            style = {
                left: '34%',
                top: '10%'
            }
        } else if (ID == 2) {
            style = {
                left: '55%',
                top: '2%'
            }
        } else if (ID == 3) {
            style = {
                left: '10%',
                top: '3%'
            }
        } else if (ID == 4) {
            style = {
                left: '47%',
                top: '20%',
                transform: 'Translate(-50%)',
            }
        } else if (ID == 5) {
            style = {
                left: '50%',
                top: '12%',
                transform: 'Translate(-50%)',
            }
        }
        return style;
    };

    return (
        <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
            <div className="d-flex align-items-center visToolHeaderImage SmallestHeader vstack mb-4"/>
    
            <div>
                <div className="row pt-3">
                    {/* Visualization for the tool */}
                    <div className="d-flex flex-column align-items-center justify-content-center col-sm-7 p-5">
                        <div className='backgroundContainer'>
                            <img src={selectedPreview} className='backgroundImage' style={{boxShadow: localStorage.getItem('selectedImageID') != 0 ? '10px 10px 20px rgba(0, 0, 0, 0.5)' : 'none'}}/*alt="Selected Frame" className="selected-image"*/ />
                            <div className="toolPreviewVisTool" style={getStyleForPreview(localStorage.getItem('selectedImageID'))}>
                            <ToolPreview
                                uploadedImage={uploadedImage}
                                frameOrientation={frameOrientation}
                                frame={frameImage}
                                matboards={{thickness: matboardThickness, colors: [selectedMatboards[0].hexColor,
                                                                                selectedMatboards[1].hexColor,
                                                                                selectedMatboards[2].hexColor]}}
                                frameThickness={frameThickness}
                                frameSize={frameSize}
                                frameImageSize={frameImageSize}
                                matboardNum={matboardNum}
                                sameSize={false}
                                scaleTool={true}
                                frameName={selectedFrame}
                            />
                            </div>
                        
                            {/* TabPanel Buttons for the tool */}
                            <div className='buttonWrapper'>
                                {previewImages.map((image) => (
                                    <button
                                        key={image.id}
                                        className={`tabPanelButton ${selectedPreview === image.src ? "active" : ""}`}
                                        onClick={() => {
                                            setSelectedPreview(image.src);
                                            localStorage.setItem('selectedImageID', image.id);
                                        }}
                                    >
                                    <img src={image.src} className="previewToolButtonImage"></img></button>
                                ))}
                            </div>

                        </div>
                    </div>
                    



                    {/* Tabs for the tool */}
                    <div className="col-sm-4 pe-2">

                        <Box className="pt-4">
                            <TabContext value={currentStep}>
                                <div className="d-flex justify-content-center">
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList
                                            onChange={handleChangeCurrentStep}
                                            aria-label="tabs"
                                            sx={{ maxWidth: { xs: 360, sm: 620 } }}
                                            className=""
                                            variant="scrollable"
                                            scrollButtons="auto"
                                        >
                                            <Tab value='image' label="image" key="image"/>
                                            <Tab value='frame' label="frames" key="frames"/>
                                            <Tab value='matboard' label="matboards" key="matboards" disabled={!matboardEnabled}/>
                                            <Tab value='accessories' label="accessories" key="accessories" disabled={!accessoriesEnabled}/>
                                            <Tab value='review' label="review" key="review" disabled={!reviewEnabled}/>
                                        </TabList>
                                    </Box>
                                </div>

                                {/* UPLOAD IMAGE --- Allow image to be uploaded then select the orientation, medium, and size options */}
                                <TabPanel className="tab" value='image'>
                                    <div>
                                        {/* File select */}
                                        <div className="row pt-2">
                                            <label>choose image:</label>
                                            <div className="d-flex py-2">
                                                <input className="form-control imageUploadBar" type="file" id="formFile" accept="image/*" onChange={handleImageUpload} hidden/>
                                                <label htmlFor="formFile">
                                                    <button className="btn button-dark px-2 py-1" onClick={(e) => {document.getElementById('formFile').click();}}>Upload file</button>
                                                </label>
                                                <label className="py-2 ps-2">{uploadedImageFileName}</label>
                                            </div>
                                        </div>

                                        {/*Select from artist gallery*/}
                                        <div className="row pt-2">
                                        <label>choose from gallery:</label>
                                        <div className="d-flex py-2">
                                            <button className="btn button-dark px-2 py-1" onClick={() => setModalShow(true)}>
                                                browse artist gallery</button>
                                                <ArtistGalleryPopUp show={modalShow} handleClose={() => setModalShow(false)} onConfirm={handleConfirm} />
                                            </div>
                                        </div>


                                        {/* Orientation select */}
                                        <div className="row pt-2">
                                            <label htmlFor="orientation">orientation:</label>
                                            <div className="d-flex py-2">
                                                <ToggleButtonGroup
                                                    orientation="horizontal"
                                                    value={frameOrientation}
                                                    id="orientation"
                                                    exclusive
                                                    onChange={handleChangeFrameOrientation}
                                                >
                                                    <ToggleButton className="p-3 d-flex justify-content-start" value="portrait" aria-label="portrait">
                                                        <CropPortraitRoundedIcon className="materialIcon"/>
                                                        <div className="ps-2">portrait</div>
                                                    </ToggleButton>
                                                    <ToggleButton className="p-3 d-flex justify-content-start" value="landscape" aria-label="landscape">
                                                        <CropLandscapeRoundedIcon className="materialIcon"/>
                                                        <div className="ps-2">landscape</div>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        </div>

                                        {/* Medium select */}
                                        <div className="row pt-2">
                                            <label htmlFor="image-size">medium:</label>
                                            <div className="d-flex py-2">
                                                <ToggleButtonGroup
                                                    orientation="horizontal"
                                                    value={medium}
                                                    id="medium"
                                                    exclusive
                                                    onChange={handleChangeMedium}
                                                >
                                                {/* Dynamic medium buttons */}
                                                {
                                                    mediums.map((medium, index) => {
                                                        // Get the icon component from the Material UI icons object
                                                        const IconComponent = MaterialUIIcons[mediumMuiIcons[index]];
                                                        return (
                                                            <ToggleButton className="p-3 d-flex justify-content-start" value={medium} aria-label={medium} key={`medium ${medium}`}>
                                                                <IconComponent className="materialIcon"/>
                                                                <div className="ps-2">{medium}</div>
                                                            </ToggleButton>
                                                        );
                                                    })
                                                }
                                                </ToggleButtonGroup>
                                            </div>
                                        </div>
                                        
                                        {/* Size select */}
                                        <div className="pt-2 d-flex">
                                            <div>
                                                <label htmlFor="image-size">image dimensions:</label>
                                                <div className="col py-2">
                                                    <FormControl>
                                                        <Select
                                                        labelId="image-size"
                                                        id="image-size"
                                                        value={`${imageSize.width}_${imageSize.height}`}
                                                        onChange={(e) => { handleChangeImageSize(e, e.target.value); }}
                                                        MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                                        >
                                                            {/* Dynamic image size list */}
                                                            {
                                                                // If selecting a particular image size would make the overall size
                                                                //     of the frame larger than the max frame size (taking the matboard
                                                                //     thickness into account) then don't let them select it
                                                                imageSizes.filter(size => Math.round(size.width + ((matboardNum > 0 ? matboardThickness : 0) * 2)) <= maxFrameSize.width && Math.round(size.height + ((matboardNum > 0 ? matboardThickness : 0) * 2)) <= maxFrameSize.height)
                                                                .map((validSize, index) => {
                                                                    return(
                                                                        <MenuItem value = {mergeDimensionsIntoString(validSize)} key = {`image-size ${validSize.width}" x ${validSize.height}"`}>
                                                                            {validSize.width}" x {validSize.height}"
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Display price */}
                                        {(medium === "Photo Print") && <div className="d-flex justify-content-end font-3">photo print: ${mediumPrice.toFixed(2)}</div> }
                                        {(medium === "Canvas") && <div className="d-flex justify-content-end font-3">canvas: ${mediumPrice.toFixed(2)}</div> }

                                        {/* Continue button */}
                                        <div className="py-4 pe-2 d-flex justify-content-end">
                                            <button className="btn button-dark px-4 py-2" onClick={(e) => handleChangeCurrentStep(e, 'frame')}>continue</button>
                                        </div>

                                    </div>
                                </TabPanel>




                                {/* FRAMES --- Generate a list of the frames available for the options selected */}
                                <TabPanel className="tab" value='frame'>
                                    <div className="pt-2">frame:</div>
                                        {/* Display frame name */}
                                        <div className="pt-2 pb-2">{displayName}</div>

                                        {/* Frame select */}
                                        <div className="d-flex flex-wrap">
                                            {/* Dynamic frame list */}
                                            {
                                                frames.map(frame => {
                                                    // For a Photo Print, only non-floater frames are allowed
                                                    // For a Canvas, all frames are allowed
                                                    if (medium === 'Photo Print' ? frame.frameType !== 'floater' : true) {
                                                        return (
                                                            <div className="pe-1 pt-1" key={`frame-icons ${frame.frameName}`}>
                                                                <button className={`btn button-dark frame-grid ${selectedFrame === frame.frameName && 'active'}`} onClick={(e) => {
                                                                        setSelectedFrame(frame.frameName);
                                                                        window.localStorage.setItem('SELECTED_FRAME', JSON.stringify(frame.frameName));

                                                                        setDisplayName(frame.displayName);
                                                                        window.localStorage.setItem('DISPLAY_NAME', JSON.stringify(frame.displayName));

                                                                        setFrameType(frame.frameType);
                                                                        window.localStorage.setItem('FRAME_TYPE', JSON.stringify(frame.frameType));

                                                                        setFrameThicknessAvailable(frame.frameThicknessAvailable);
                                                                        window.localStorage.setItem('FRAME_THICKNESS_AVAILABLE', JSON.stringify(frame.frameThicknessAvailable));

                                                                        setFrameThickness(frame.frameThicknessAvailable[0]);
                                                                        window.localStorage.setItem('FRAME_THICKNESS', JSON.stringify(frame.frameThicknessAvailable[0]));
                                                                    }}>
                                                                    <div className="m-n1">
                                                                        <img src={frame.iconUrl} alt={frame.frameName} className="frame-icons"/>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        );
                                                    }
                                                    else return <div key={`frame-icons ${frame.frameName}`}></div>;
                                                })
                                            }
                                        </div>

                                    {/* Frame thickness select */}
                                    {/* Frame thickness buttons are active or disabled based on whether they're available for the selected frame */}
                                    <div>
                                        <div className="row">
                                            <div className="pt-4">
                                                <label className="pe-3 pb-1" htmlFor="frame-thickness">frame thickness:</label>
                                                <div className="d-flex flex-wrap" id="frame-thickness">
                                                    <div className="pe-1 pt-1">
                                                        <button className={`btn button-dark frame-grid ${frameThickness === 1 && 'active'}`} onClick={(e) => { 
                                                                setFrameThickness(1);
                                                                window.localStorage.setItem('FRAME_THICKNESS', JSON.stringify(1));
                                                            }}
                                                            disabled={frameThicknessButtonArray[0] ? false : true}>
                                                            <div className="m-n1 p-1"> 1" </div>
                                                        </button>
                                                    </div>
                                                    <div className="pe-1 pt-1">
                                                        <button className={`btn button-dark frame-grid ${frameThickness === 1.25 && 'active'}`} onClick={(e) => { 
                                                                setFrameThickness(1.25);
                                                                window.localStorage.setItem('FRAME_THICKNESS', JSON.stringify(1.25));
                                                            }}
                                                            disabled={frameThicknessButtonArray[1] ? false : true}>
                                                            <div className="m-n1 p-1"> 1.25" </div>
                                                        </button>
                                                    </div>
                                                    <div className="pe-1 pt-1">
                                                        <button className={`btn button-dark frame-grid ${frameThickness === 1.5 && 'active'}`} onClick={(e) => { 
                                                                setFrameThickness(1.5);
                                                                window.localStorage.setItem('FRAME_THICKNESS', JSON.stringify(1.5));
                                                            }}
                                                        disabled={frameThicknessButtonArray[2] ? false : true}>
                                                            <div className="m-n1 p-1"> 1.5" </div>
                                                        </button>
                                                    </div>
                                                    <div className="pe-1 pt-1">
                                                        <button className={`btn button-dark frame-grid ${frameThickness === 2 && 'active'}`} onClick={(e) => { 
                                                                setFrameThickness(2);
                                                                window.localStorage.setItem('FRAME_THICKNESS', JSON.stringify(2));
                                                            }}
                                                        disabled={frameThicknessButtonArray[3] ? false : true}>
                                                            <div className="m-n1 p-1"> 2" </div>
                                                        </button>
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </div>                                        
                                    </div>


                                    {/* Display price */}
                                    <div className="d-flex justify-content-end font-3">frame: ${framePrice.toFixed(2)}</div>

                                    {/* Back/continue buttons */}
                                    <div className="py-4 pe-2 d-flex justify-content-between">
                                        <button className="btn button-dark px-4 py-2" onClick={(e) => handleChangeCurrentStep(e, 'image')}>back</button>
                                        <button className={"btn button-dark px-4 py-2"} onClick={(e) => handleChangeCurrentStep(e, frameContinueButton)}>continue</button>
                                    </div>
                                </TabPanel>




                                {/* MATBOARDS ---  Generate the list of available matboards plus addition selection grids based off number of matboards selected*/}
                                <TabPanel className="tab" value='matboard'>
                                    {/* Matboard num select */}
                                    <div>
                                        <div className="pt-2 d-flex">
                                            <div>
                                                <label htmlFor="matboard-number">number of matboards:</label>
                                                <div className='col py-2 matboardFormLength-Number'>
                                                    <FormControl>
                                                        <Select
                                                            labelId="matboard-number"
                                                            id="matboard-number"
                                                            value={matboardNum}
                                                            onChange={(e) => { handleChangeMatboardNum(e, e.target.value); }}
                                                            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                                        >
                                                            <MenuItem value={0} key={"matboard-number 0"}>0 matboards</MenuItem>
                                                            {/* Make sure that we can even add matboards at all given the current image size
                                                                If we can't even add the thinnest matboard without making the frame too large,
                                                                don't show option to choose more than 0 matboards */}
                                                            {      (Math.round(imageSize.width  + (2 * matboardThicknesses[0]))) < maxFrameSize.width
                                                                && (Math.round(imageSize.height + (2 * matboardThicknesses[0]))) < maxFrameSize.height
                                                                && <MenuItem value={1} key={"matboard-number 1"}>1 matboard</MenuItem> }
                                                            {      (Math.round(imageSize.width  + (2 * matboardThicknesses[0]))) < maxFrameSize.width
                                                                && (Math.round(imageSize.height + (2 * matboardThicknesses[0]))) < maxFrameSize.height
                                                                && <MenuItem value={2} key={"matboard-number 2"}>2 matboards</MenuItem> }
                                                            {      (Math.round(imageSize.width  + (2 * matboardThicknesses[0]))) < maxFrameSize.width
                                                                && (Math.round(imageSize.height + (2 * matboardThicknesses[0]))) < maxFrameSize.height
                                                                && <MenuItem value={3} key={"matboard-number 3"}>3 matboards</MenuItem> }
                                                                   
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Matboard thickness select */}
                                        {/* Only show the matboard thickness selection if the user has selected at least 1 matboard */}
                                        {
                                            matboardNum !== 0 && (
                                                <div className="pt-2 d-flex">
                                                    <div>
                                                        <label htmlFor="matboard-thickness">matboard thickness:</label>
                                                        <div className='col py-2'>
                                                            <FormControl className='w50'>
                                                                <Select
                                                                    labelId="matboard-thickness"
                                                                    id="matboard-thickness"
                                                                    value={matboardThickness}
                                                                    onChange={(e) => { handleChangeMatboardThickness(e, e.target.value); }}
                                                                    MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                                                >
                                                                    {/* Dynamic matboard thickness list */}
                                                                    {
                                                                        // If selecting a particular matboard thickness would make the overall size
                                                                        //     of the frame larger than the max frame size then don't let them select it
                                                                        matboardThicknesses.filter(thickness => Math.round(imageSize.width + (thickness * 2)) <= maxFrameSize.width && Math.round(imageSize.height + (thickness * 2)) <= maxFrameSize.height)
                                                                        .map(validThickness => {
                                                                            return(
                                                                                <MenuItem value = {validThickness} key = {`matboard-thickness ${validThickness}`}> {`${validThickness}"`} </MenuItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        {/* Matboard select */}
                                        <div className="row pt-2">
                                            <div>matboards:</div>
                                            {/* If more than one matboard selected generate the additional select forms for the user to select the other colors*/}
                                            {
                                                // Dynamic matboard select dropdowns
                                                // Map through an array generated based on matboardNum
                                                (Array.from({ length: matboardNum }, (_, index) => index + 1)).map((layerNumber) => {
                                                    const layerIndex = layerNumber - 1;
                                                    return(
                                                        <div className="d-flex pt-2" key={`matboard-options-dropdown ${layerNumber}`}>
                                                            {
                                                                matboardNum >= layerNumber && (
                                                                    <>
                                                                        <div className="ps-4">
                                                                            <div className="d-flex">Layer {layerNumber}:</div>
                                                                            <div className='col py-2'>
                                                                                <FormControl>
                                                                                    <Select
                                                                                        value={selectedMatboards[layerIndex].internalName}
                                                                                        onChange={(e) => {
                                                                                            handleChangeSelectedMatboards(e, e.target.value, layerIndex);
                                                                                        }}
                                                                                        MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                                                                    >
                                                                                        {matboardList.map(matboard => {
                                                                                            return (
                                                                                                <MenuItem className="d-flex justify-content-between" value={matboard.internalName} key={`matboard-options ${matboard.internalName}`}>
                                                                                                    <div className="row">
                                                                                                        <div className="col col-sm-auto">
                                                                                                            <svg width={50} height={50}>
                                                                                                                <circle stroke="black" strokeWidth="1" cx={25} cy={25} r={20} fill={matboard.hexColor} />
                                                                                                            </svg> 
                                                                                                        </div>
                                                                                                        <div className="col col-sm-auto d-flex align-items-center font-1-5">
                                                                                                            {matboard.displayName} - {matboard.style}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </MenuItem>
                                                                                            );                                          
                                                                                        })}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    {/* Display prices */}
                                    {
                                        (Array.from({ length: matboardNum }, (_, index) => index + 1)).map((layerNumber) => {
                                            const layerIndex = layerNumber - 1;
                                            return(
                                                <div key={`matboard-prices-display ${layerNumber}`}>
                                                    {
                                                        matboardNum >= layerNumber && (
                                                            <div className="d-flex justify-content-end font-3">layer {layerNumber}: ${matboardPrices[layerIndex].toFixed(2)}</div>
                                                        )
                                                    }
                                                </div>
                                            );
                                        })
                                    }

                                    {/* Back/continue buttons */}
                                    <div className="py-4 pe-2 d-flex justify-content-between">
                                        <button className="btn button-dark px-4 py-2" onClick={(e) => handleChangeCurrentStep(e, 'frame')}>back</button>
                                        <button className={"btn button-dark px-4 py-2"} onClick={(e) => handleChangeCurrentStep(e, 'accessories')}>continue</button>
                                    </div>

                                </TabPanel>



                                {/* ACCESSORIES --- Glass and hanging styles combined*/}
                                <TabPanel className="tab" value='accessories'>
                                    <div className="row d-flex">

                                        {/* Glass type select */}
                                        <label className="pt-2" htmlFor="glass-type">glass type:</label>
                                        <FormControl className='w20' sx={{ m: 1, minWidth: 200 }}>
                                            <Select
                                            labelId="glass-types"
                                            id="glass-types"
                                            value={glassType}
                                            disabled={!glassEnabled}
                                            onChange={(e) => {
                                                setGlassType(e.target.value);
                                                window.localStorage.setItem('GLASS_TYPE', JSON.stringify(e.target.value));
                                            }}
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                            >
                                            {/* Dynamic glass type dropdown list */}
                                            {
                                                glassTypes.map(glassType => {
                                                    return (
                                                        <MenuItem className="d-flex justify-content-between" value={glassType} key={`glass-types ${glassType}`}>
                                                            <div className="row">
                                                                <div className="col col-sm-auto d-flex align-items-center font-1-5">{glassType}</div>
                                                            </div>
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                            </Select>
                                        </FormControl>

                                        {/* Hanging style select */}
                                        <label className="pt-2" htmlFor="hanging-style">hanging style:</label>
                                        <FormControl className='w20' sx={{ m: 1, minWidth: 200 }}>
                                            <Select
                                            labelId="hanging-styles"
                                            id="hanging-styles"
                                            value={hangingStyle}
                                            disabled={!hangingEnabled}
                                            onChange={(e) => {
                                                setHangingStyle(e.target.value);
                                                window.localStorage.setItem('HANGING_STYLE', JSON.stringify(e.target.value));
                                            }}
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                            >
                                            {/* Dynamic hanging style dropdown list */}
                                            {
                                                hangingStyles.map(hangingStyle => {
                                                    // Sawtooth hangers are only available for 16x20 frames or smaller
                                                    const canHaveSawtooth = frameSize.width <= 16 && frameSize.height <= 20;
                                                    if (hangingStyle === "Sawtooth" ? canHaveSawtooth : true) {
                                                        return (
                                                            <MenuItem className="d-flex justify-content-between" value={hangingStyle} key={`hanging-styles ${hangingStyle}`}>
                                                                <div className="row">
                                                                    {
                                                                        hangingStyle !== "None" &&
                                                                        <div className="col col-sm-auto">
                                                                            <img className="hangingStyleCloseupImage" src={
                                                                                                hangingStyle === "Sawtooth" ? sawtoothHangerCloseup
                                                                                                : hangingStyle === "Security" ? securityHangerCloseup
                                                                                                : hangingStyle === "Strap" ? strapHangerCloseup
                                                                                                : noFrame
                                                                                            } alt={`${hangingStyle} hanger`}/>
                                                                        </div>
                                                                    }
                                                                    <div className="col col-sm-auto d-flex align-items-center font-1-5">{hangingStyle}</div>
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    }
                                                    else {
                                                        return <div key={`hanging-styles ${hangingStyle}`}></div>
                                                    }
                                                })
                                            }
                                            </Select>
                                        </FormControl>
                                        {/* Hanging style image */}
                                        {
                                            hangingStyle !== "None" &&
                                            <div className="py-2 ps-2 pe-2 row justify-content-center">
                                                <img className="hangingStyleFullImage" src={
                                                                                            hangingStyle === "Sawtooth" ? sawtoothHangerFull
                                                                                            : hangingStyle === "Security" ? securityHangerFull
                                                                                            : hangingStyle === "Strap" ? strapHangerFull
                                                                                            : "noFrame"
                                                                                        } alt={`${hangingStyle} hanger`}/>
                                            </div>
                                        }
                                    </div>

                                    {/* Display price */}
                                    <div className="d-flex justify-content-end font-3">glass: ${glassPrice.toFixed(2)}</div>
                                    <div className="d-flex justify-content-end font-3">hanging: ${hangPrice.toFixed(2)}</div>

                                    {/* Back/continue buttons */}
                                    <div className="py-4 pe-2 d-flex justify-content-between">
                                        <button className="btn button-dark px-4 py-2" onClick={(e) => handleChangeCurrentStep(e, accessoryBackButton)}>back</button>
                                        <button className={"btn button-dark px-4 py-2"} onClick={(e) => handleChangeCurrentStep(e, accessoryContinueButton)}>continue</button>
                                    </div>
                                </TabPanel>



                                {/* REVIEW --- Display only the parts of the frame that have real values */}
                                <TabPanel className="tab" value='review'>
                                <div className="review-panel">
                                    {/* Photo Print Section */}
                                    {(medium === "Photo Print" && mediumPrice !== 0) && (
                                    <div className="product-section">
                                        <div className="section-title">Photo Print</div>
                                        <div className="detail-row">
                                        <div className="detail-label">Dimensions:</div>
                                        <div className="detail-value">{`${imageSize.width}" × ${imageSize.height}"`}</div>
                                        <div className="price-value">${mediumPrice.toFixed(2)}</div>
                                        </div>
                                    </div>
                                    )}

                                    {/* Canvas Section */}
                                    {(medium === "Canvas" && mediumPrice !== 0) && (
                                    <div className="product-section">
                                        <div className="section-title">Canvas</div>
                                        <div className="detail-row">
                                        <div className="detail-label">Dimensions:</div>
                                        <div className="detail-value">{`${imageSize.width}" × ${imageSize.height}"`}</div>
                                        <div className="price-value">${mediumPrice.toFixed(2)}</div>
                                        </div>
                                    </div>
                                    )}

                                    {/* Frame Section */}
                                    {selectedFrame !== noFrameName && (
                                    <div className="product-section">
                                        <div className="section-title">Frame</div>
                                        <div className="detail-row">
                                        <div className="detail-label">Size:</div>
                                        <div className="detail-value">{`${frameSize.width}" × ${frameSize.height}"`}</div>
                                        <div className="price-value">${framePrice.toFixed(2)}</div>
                                        </div>
                                        <div className="detail-row">
                                        <div className="detail-label">Name:</div>
                                        <div className="detail-value">{displayName}</div>
                                        <div className="price-value"></div>
                                        </div>
                                        <div className="detail-row">
                                        <div className="detail-label">Thickness:</div>
                                        <div className="detail-value">{frameThickness}"</div>
                                        <div className="price-value"></div>
                                        </div>
                                    </div>
                                    )}

                                    {/* Matboard Section */}
                                    {matboardNum !== 0 && (
                                    <div className="product-section">
                                        <div className="section-title">Matboard</div>
                                        {matboardNum >= 1 && (
                                        <div className="detail-row">
                                            <div className="detail-label">Layer 1:</div>
                                            <div className="detail-value">{selectedMatboards[0].displayName}</div>
                                            <div className="price-value">${matboardPrices[0].toFixed(2)}</div>
                                        </div>
                                        )}
                                        {matboardNum >= 2 && (
                                        <div className="detail-row">
                                            <div className="detail-label">Layer 2:</div>
                                            <div className="detail-value">{selectedMatboards[1].displayName}</div>
                                            <div className="price-value">${matboardPrices[1].toFixed(2)}</div>
                                        </div>
                                        )}
                                        {matboardNum >= 3 && (
                                        <div className="detail-row">
                                            <div className="detail-label">Layer 3:</div>
                                            <div className="detail-value">{selectedMatboards[2].displayName}</div>
                                            <div className="price-value">${matboardPrices[2].toFixed(2)}</div>
                                        </div>
                                        )}
                                        <div className="detail-row">
                                        <div className="detail-label">Thickness:</div>
                                        <div className="detail-value">{matboardThickness}"</div>
                                        <div className="price-value"></div>
                                        </div>
                                    </div>
                                    )}

                                    {/* Accessories Section */}
                                    {(glassType !== "None" || hangingStyle !== "None") && (
                                    <div className="product-section">
                                        <div className="section-title">Accessories</div>
                                        {glassType !== "None" && (
                                        <div className="detail-row">
                                            <div className="detail-label">Glass:</div>
                                            <div className="detail-value">{glassType}</div>
                                            <div className="price-value">${glassPrice.toFixed(2)}</div>
                                        </div>
                                        )}
                                        {hangingStyle !== "None" && (
                                        <div className="detail-row">
                                            <div className="detail-label">Hanging:</div>
                                            <div className="detail-value">{hangingStyle}</div>
                                            <div className="price-value">${hangPrice.toFixed(2)}</div>
                                        </div>
                                        )}
                                    </div>
                                    )}

                                    {/* Total Price */}
                                    <div className="total-section">
                                    <div className="total-price">
                                        <div className="total-label">Total</div>
                                        <div className="total-value">
                                        ${(mediumPrice + framePrice + matboardPrices[0] + matboardPrices[1] + matboardPrices[2] + glassPrice + hangPrice).toFixed(2)}
                                        </div>
                                    </div>
                                    </div>

                                    {/* Back/add to cart buttons */}
                                    <div className="button-container">
                                    <button 
                                        className="back-button"
                                        onClick={(e) => handleChangeCurrentStep(e, reviewBackButton)}
                                    >
                                        Back
                                    </button>
                                    <button 
                                        className="cart-button"
                                        onClick={(e) => handleAddToCart()}
                                    >
                                        {!finalProductID ? "Add to Cart" : "Update Cart"}
                                    </button>
                                    </div>
                                </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </div>
            </div>

            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>

            <div className="wrapper">
                <div className="d-flex justify-content-center pt-5 sectionTitle-Light text-center">want unique frames?</div>
                <div className="d-flex justify-content-center pt-3 sectionFont-Light text-center">You can contact us about more specific frames if you can't find the 
                right size for you here. </div>
                <div className="d-flex justify-content-center sectionFont-Light pt-5">
                <a href="/order-form">
                    <button className="d-flex btn btn-outline-dark btn-lg">Custom Orders</button>
                </a>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default VisualizationTool;
import React, { useState, useEffect } from "react";
import { Table} from 'react-bootstrap';
import Sidebar from '../components/SideBar';
import logoImage from '../images/Logo-Base-basic.png';
import './users.css';
import '../App.css';
import { getAllUserInformation } from './usersHelper';

const AdminNavbar = () => {
  return (
    <div className="navWrapper">
      <div className="AdminNav">
        <div>
          <a href="/" className="NavLink">
            <div className="AdminLogo d-flex justify-content-end">
              <img src={logoImage} alt="logo" className="NavLogo" />
            </div>
            <div className="AdminLogoText font-2 ps-2 fontLogo">mycustomframe</div>
          </a>
        </div>
      </div>
    </div>
  );
};

const UserPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const itemsPerPage = 8;
  
  useEffect(() => {
    getAllUserInformation().then(users => {
      setUsers(users);
    });
  }, []);

  const filteredUsers = (users || []).filter((user) =>
    [user.firstName, user.lastName, user.email, user.type]
      .map(field => field?.toLowerCase() || "")
      .some(value => value.includes(searchQuery.toLowerCase()))
  );
  
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <AdminNavbar />
      <hr className="admin-divider" />
      <div className="user-page">
        <Sidebar />
        <div className="user-content">
          <div className="header">
            <input
              type="text"
              className="search-box"
              placeholder="Search User..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <div className="user-table-container">
                <Table striped bordered hover className="user-table">
                  <thead>
                    <tr>
                      <th>FIRST NAME</th>
                      <th>LAST NAME</th>
                      <th>EMAIL</th>
                      <th>TYPE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedUsers.map((user, index) => (
                    <tr key={index}>
                    <td style={{ color: user.type === 'admin' ? '#ff4172' : 'inherit' }}>{user.firstName}</td>
                    <td style={{ color: user.type === 'admin' ? '#ff4172' : 'inherit' }}>{user.lastName}</td>
                    <td style={{ color: user.type === 'admin' ? '#ff4172' : 'inherit' }}>{user.email}</td>
                    <td style={{ color: user.type === 'admin' ? '#ff4172' : 'inherit' }}>{user.type}</td>
                    </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="pagination">
                <button
                  className="page-btn"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <span>
                  {`Showing ${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
                    currentPage * itemsPerPage,
                    filteredUsers.length
                  )} of ${filteredUsers.length}`}
                </span>
                <button
                  className="page-btn"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div> 
        </div>
      </div>
    </div>
  );
};

export default UserPage;

import { React } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import logoImage from '../../images/Logo-Base-basic.png';


const VerifyEmail = (props) => {

    const handleClose = () => {
        props.handleClose();

        // Reload page
        window.location.reload();
    }
    return (
        <div>
            <div className="popup">
                <div className="verify-popup-inner row">
                    <div className="col">
                        <div className="verifyEmailWrapper">
                            <div className="verify-close-btn" onClick={handleClose}>
                                <CloseIcon />
                            </div>
                            <div className="verifyEmailIcon">
                                <img src={logoImage} alt='logo' />
                            </div>
                            <div className="verifyEmailText">
                                <h1>
                                    Please verify your email
                                </h1>
                                <h6>
                                    A link to verify your account has been sent. Please check your email inbox and spam folder
                                    for a link from MyCustomFrame
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerifyEmail;
import React, { useState } from 'react';
import './App.css';
import Navbar from './components/NavBar';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// Pages
import Home from './pages/home';
import About from './pages/about';
import ArtistGallery from './pages/artistGallery';
import OrderForm from './pages/orderForm';
import VisualizationTool from './components/visualizationTool/visTool';
import Account from './pages/account';
import Checkout from './pages/checkout';
import CheckoutSuccess from './pages/checkoutSuccess';
import CheckoutFail from './pages/checkoutFail';
import ArtistPage from './pages/artistPage';
import Metrics from './pages/metrics';
import Users from './pages/users';
import Product from './pages/productPage';
import Orders from './pages/orderHistory';
import ArtistProfilePage from './pages/artistProfilePage';

function App() {
  const [scrollLock, setScrollLock] = useState(false);

  return (
    <Router>
      <RenderNavbar scrollLock={scrollLock} setScrollLock={setScrollLock} />
    </Router>
  );
}

function RenderNavbar({ scrollLock, setScrollLock }) {
  const location = useLocation();
  const hideNavbar = ['/artistPage', '/users','/metrics','/orderHistory','/productPage'];
  const includesNavbar = !hideNavbar.includes(location.pathname);

  return (
    <>
      {includesNavbar && (
        <Navbar
          toggleScrollLock={() => {
            setScrollLock(!scrollLock);
            console.log("scrollLock = ", !scrollLock);
          }}
        />
      )}

      <Routes>
        <Route path='/' element={<Home scrollLock={scrollLock} />} />
        <Route path='/about' element={<About scrollLock={scrollLock} />} />
        <Route path='/order-form' element={<OrderForm scrollLock={scrollLock} />} />
        <Route path='/artist-gallery' element={<ArtistGallery scrollLock={scrollLock} />} />
        <Route path='/visualizationTool' element={<VisualizationTool scrollLock={scrollLock} />} />
        <Route path='/account' element={<Account scrollLock={scrollLock} />} />
        <Route path='/checkout' element={<Checkout scrollLock={scrollLock} />} />
        <Route path='/checkoutSuccess' element={<CheckoutSuccess scrollLock={scrollLock} />} />
        <Route path='/checkoutFail' element={<CheckoutFail scrollLock={scrollLock} />} />
        <Route path='/artistPage' element={<ArtistPage scrollLock={scrollLock} />} />
        <Route path='/metrics' element={<Metrics scrollLock={scrollLock} />} />
        <Route path='/users' element={<Users scrollLock={scrollLock} />} />
        <Route path='/productPage' element={<Product scrollLock={scrollLock} />} />
        <Route path='/orderHistory' element={<Orders scrollLock={scrollLock} />} />
        <Route path='/artistProfilePage/:artistId' element={<ArtistProfilePage scrollLock={scrollLock} />} />
      </Routes>
    </>
  );
}

export default App;
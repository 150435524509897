import { ENDPOINT_PATH } from "../config";

// Helper function to add an admin order
/**
 * @param {String} userID // Customer Identifier
 * @param {string} finalProductID // Final Product Identifier
 * @param {Document} finalProductDoc // Document holding the specifics of the final product
 * @param {Number} quantity // Integer of the amount of this final product
 */
const addAdminOrder = async (userID, finalProductID, finalProductDoc, quantity, address1, city, postalCode, state) => {

    const orderData = new FormData();
    orderData.append('userID', userID);
    orderData.append('finalProductID', finalProductID);
    orderData.append('finalProductDoc', finalProductDoc);
    orderData.append('quantity', quantity);
    orderData.append('address1', address1);
    orderData.append('city', city);
    orderData.append('postalCode', postalCode);
    orderData.append('state', state);

    fetch(ENDPOINT_PATH + `/api/add-admin-order`, {
        method: 'POST',
        body: orderData
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Order added to admin view: ", data);
    })
    .catch(e => { console.error(e); });
}
export { addAdminOrder };


// Helper function to update the status of an order.
/**
 * @param {String} adminOrderID // Identifier for the order we are updating
 * @param {string} newStatus // New status to be changed to
 */
const updateOrderStatus = async (adminOrderID, newStatus) => {

    fetch(ENDPOINT_PATH + `/api/update-order-status?adminOrderID=${adminOrderID}&newStatus=${newStatus}`, {
        method: 'PATCH',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Updated order status in admin view: ", data);
    })
    .catch(e => { console.error(e); });
}
export { updateOrderStatus };


// Helper function to delete an order from the admin view.
/**
 * @param {String} adminOrderID // Identifier for the order we are deleting
 */
const deleteAdminOrder = async (adminOrderID) => {

    fetch(ENDPOINT_PATH + `/api/delete-admin-order?adminOrderID=${adminOrderID}`, {
        method: 'DELETE',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Delete order from admin view: ", data);
    })
    .catch(e => { console.error(e); });
}
export { deleteAdminOrder };


// Helper function to get all orders from the Admin-Orders collection
/**
 * @param {String} status // string to search by
 * @param {string} startDate // yyyy-mm-dd
 * @param {string} endDate // yyyy-mm-dd
 */
const getAdminOrders = async (status, startDate, endDate) => {
    const params = new URLSearchParams();

    if (status) params.append('status', status);
    if (startDate) params.append('startDate', startDate);
    if (endDate) params.append('endDate', endDate);
    return fetch(ENDPOINT_PATH + `/api/get-admin-orders?${params.toString()}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Admin orders fetched successfully: ", data);
        return data;
    })
    .catch(e => { console.error(e); });
}
export { getAdminOrders };


// Helper function to get a users order history
/**
 * @param {String} userID // string of the users identifier
 */
const getUserOrderHistory = async (userID) => {
    return fetch(ENDPOINT_PATH + `/api/get-user-order-history?userID=${userID}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("User order history fetched successfully: ", data);
        return data;
    })
    .catch(e => { console.error(e); });
}
export { getUserOrderHistory };
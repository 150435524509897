import { ENDPOINT_PATH } from "../config";

// Helper function to update medium price
/**
 * @param {String} frameName String for the name of the frame
 * @param {string} frameType String for the type of frame
 * @param {Number} thickness Integer for the frame thickness
 */
const delFrameProduct = async (frameName) => {
    fetch(ENDPOINT_PATH + `/api/delete-frame-product?frameName=${frameName}`, {
        method: 'DELETE',
    })
    .then(response => response.json())
    .then(data => { 
        console.log(data);
    })
    .catch(e => { console.error(e); });
}
export { delFrameProduct };


/**
 * @param {String} pricePerFoot
 * @param {String} frameType
 * @param {String} frameName
 * @param {String} frameDisplayName
 * @param {File} visToolIcon
 * @param {Array} frameThicknessAvailable
 * @param {Object} frameFiles
 */
const addFrame = async (frameName, frameDisplayName, pricePerFoot, frameType, visToolIcon, frameThicknessAvailable, frameFiles) => {
    const frameFormData = new FormData();
    frameFormData.append('frameName', frameName);
    frameFormData.append('frameDisplayName', frameDisplayName);
    frameFormData.append('pricePerFoot', pricePerFoot);
    frameFormData.append('frameType', frameType);
    frameFormData.append('visToolIcon', visToolIcon);
    
    
    frameThicknessAvailable.forEach((thickness) => {
        frameFormData.append('frameThicknessAvailable[]', thickness);
    });

    // fileKey should come in the form of 'thickness-size', ex: '1-8_10'
    for (const [fileKey, file] of Object.entries(frameFiles)) {
        frameFormData.append(fileKey, file);
    }

    fetch(ENDPOINT_PATH + `/api/add-frame-product`, {
        method: 'POST',
        body: frameFormData
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Frame added: ", data);
    })
    .catch(e => { console.error(e); });        
}
export { addFrame };
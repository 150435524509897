import { React, useState, useEffect } from 'react';
import checkmark from '../images/checkmark.png';
import { ENDPOINT_PATH } from '../config';

/* This version is currently only styled on desktop versions */

const CheckoutSuccess = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const verifyPayment = async () => {
            const urlParameters = new URLSearchParams(window.location.search);
            const sessionID = urlParameters.get('sessionID');
            
            if (!sessionID) {
                setError('No session ID found.');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(ENDPOINT_PATH + `/api/check-session?sessionID=${sessionID}`);
                const session = await response.json();

                if (session.paymentStatus === 'paid') {

                    console.log(localStorage.getItem('metricAdded'));
                    if (localStorage.getItem('metricAdded') === 'true') {
                        console.log('Metric has already been added');
                        return;
                    }

                    // Get the shopping cart data of current user
                    const userData = localStorage.getItem('user');
                    let userID;
                    if (userData) {
                        const userObj = JSON.parse(userData);
                        userID = userObj.uid;
                    }

                    // Get the current user's shopping cart
                    const cart = await fetch(ENDPOINT_PATH + `/api/shopping-cart?userID=${userID}`);
                    const cartData = await cart.json();

                    // Create a map containing the purchased frames
                    const finalProductsPromise = cartData.finalProducts.map(async (finalProduct) => {
                        const finalProductID = finalProduct.finalProduct._path.segments[1];
                        
                        const response = await fetch(ENDPOINT_PATH + `/api/final-product?finalProductID=${finalProductID}`);
                        const finalProductData = await response.json();

                        return {
                            finalProductID,
                            finalProductDoc: finalProductData,
                            quantity: parseInt(finalProduct.quantity, 10)
                        }
                    });

                    const userHistoryFinalProductsPromise = cartData.finalProducts.map(async (finalProduct) => {
                        const finalProductID = finalProduct.finalProduct._path.segments[1];

                        return {
                            finalProductID,
                            quantity: parseInt(finalProduct.quantity, 10)
                        }
                    });

                    const finalProducts = await Promise.all(finalProductsPromise);
                    const userHistoryFinalProducts = await Promise.all(userHistoryFinalProductsPromise);

                    // Run through each final product in the user's cart.
                    for (const finalProducts of cartData.finalProducts) {
                        // Get the final products data
                        const finalProductID = finalProducts.finalProduct._path.segments[1];
                        const finalProduct = await fetch(ENDPOINT_PATH + `/api/final-product?finalProductID=${finalProductID}`);
                        const finalProductData = await finalProduct.json();

                        // Api to handle metrics of products
                        fetch(ENDPOINT_PATH + `/api/add-purchase-metric`, {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                finalProductDoc: finalProductData,
                                quantity: parseInt(finalProducts.quantity, 10)
                            })
                        }).catch(error => console.error("Error adding purchase metric in checkout: ", error));

                        localStorage.setItem('metricAdded', true);
                    }



                    // API to put the final product in a orders page for Admin
                    fetch(ENDPOINT_PATH + `/api/add-admin-order`, {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            userID: userID,
                            finalProducts: finalProducts,
                            address1: localStorage.getItem('ADDRESS_LINE_1'),
                            city: localStorage.getItem('CITY'),
                            postalCode: localStorage.getItem('POSTAL_CODE'),
                            state: localStorage.getItem('STATE'),
                        })
                    }).catch(error => console.error("Error adding to admin order history in checkout: ", error));


                    // API to put the final product in the users order history
                    fetch(ENDPOINT_PATH + `/api/add-user-order-history`, {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            userID: userID,
                            finalProducts: userHistoryFinalProducts,
                            totalPayment: session.totalPayment,
                        })
                    }).catch(error => console.error("Error adding to user order history in checkout: ", error));



                } else {
                    setError('Payment not successful.');
                }
            } catch (error) {
                console.error('Error verifying payment. ', error);
                setError('Error verifying payment');
            } finally {
                setLoading(false);
            }
        };

        verifyPayment(); // Call the function once after the component is mounted
    }, []); // Empty dependency array ensures this runs only once

    return (
        <div className="popup">
            <div className="checkout-popup-inner row">
                <div className="col pb-5">
                    <div className="checkout-successFail-wrapper">
                        <div className="checkout-successFail-icon">
                            <img src={checkmark} height="60" width="60" alt="checkmark icon"/>
                        </div>
                        <div className="checkout-successFail-text">
                            <h2>
                                Thank you for your order!
                            </h2>
                            <h6>
                                Your order has been successfully completed. The MyCustomFrame team will
                                reach out to you shortly regarding your order.
                            </h6>
                        </div>
                        <div className="text-center pt-2">
                            <a href="/">
                                <button className="btn button-dark">Back to MyCustomFrame</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutSuccess;

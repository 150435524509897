import Footer from '../components/Footer';
import './artistProfilePage.css';
import '../App.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getArtistData } from './artistHelper';

const ARTIST_GALLERY_BACK = "Back to Artist Alley";
const ARTWORK_TITLE = "Artwork";
const NUM_OF_ARTWORKS_TITLE = "Artworks";

const DarkerNavBar = () => {
    return (<div className="DarkerNavBar"></div>)
}

const BackButton = () => {
    return (
        <a href = "/artist-gallery">
            <div className="BackButton">&lt; {ARTIST_GALLERY_BACK}</div>
        </a>
    )
}

const ArtistName = ({ name }) => {
    return (
        <div className="ArtistName">{name}</div>
    )
}

const ArtistDescription = ({ description }) => {
    return (
        <div className="ArtistDescription">{description}</div>
    )
}

const ArtistProfilePicture = ({ pfpFile }) => {
    return (
        <div className="ArtistProfilePictureContainer">
            <img src={pfpFile} className="ArtistProfilePicture" lt="Profile picture of the artist" />
        </div>
    )
}

const ArtistBio = ({ name, description, pfpFile }) => {
    return (
        <div>
            <ArtistName name={name}/>
            <div className="ArtistBioContent">
                <ArtistDescription description={description}/>
                <ArtistProfilePicture pfpFile={pfpFile}/>
            </div>
        </div>
    )
}

const ArtworkTitle = () => {
    return (
        <div className="ArtworkTitle">{ARTWORK_TITLE}</div>
    )
}

const NumberOfArtworks = ({ numberOfArtworks }) => {
    return (
        <div className="NumberOfArtworksTitle">{numberOfArtworks} {NUM_OF_ARTWORKS_TITLE}</div>
    )
}

const ArtWorkCubicle = ({ artFile, artName, openArtworkProfile }) => {
    return (
        <div className="ArtWorkCubicle rounded" onClick={openArtworkProfile}>
            <img className="ArtPiece img-fluid rounded" src={artFile} alt="Artwork Image" />
            <div className="ArtInfo">
                <div>{artName}</div>
            </div>
            <div className="overlay d-flex flex-column justify-content-center align-items-center">
                <h5 className="text-white mb-2">View Art Piece</h5>
                <p className="text-white text-center px-2">➡️</p>
            </div>
        </div>
    )
}

const ArtWorkGrid = ({ artWorks, artWorkCubicleOnClick }) => {
    return (
        <div className="ArtWorkGrid">
            {artWorks.map((artWork) => {
                return <ArtWorkCubicle artFile={artWork.artImageUrl} artName={artWork.artworkName} openArtworkProfile={() => artWorkCubicleOnClick(artWork)}/>
            })}
        </div>
    )
}

const ArtWorkDisplay = ({ artWorksArray = [], artWorkGridOnClick }) => {
    return (
        <div className="ArtWorkDisplay">
            <ArtworkTitle/>
            <NumberOfArtworks numberOfArtworks={artWorksArray.length}/>
            <ArtWorkGrid artWorks={artWorksArray} artWorkCubicleOnClick={artWorkGridOnClick}/>
        </div>
    )
}

const ArtWorkProfile = ({ isOpen, onClose, artworkFocus }) => {
    if (!isOpen) return null;

    return (
        <div className="ArtWorkProfile" onClick={onClose}>
            <div className="overlay" onClick={(e) => e.stopPropagation()}>
                <img className="ArtImage" src={artworkFocus.artImageUrl}/>
                <div className="vertical-line"/>
                <ArtworkInfo artwork={artworkFocus}/>
            </div>
        </div>
    )
}

const ArtworkInfo = ({ artwork }) => {
    return (
        <div className="ArtworkInfo">
            <div>{artwork.artworkName}</div>
            <div>By {artwork.artistName}</div>
            <div>Description<hr/> <div className="ArtworkSubInfo">{artwork.artDescription}</div></div>
            <div>Category<hr/> <div className="ArtworkSubInfo">{artwork.category}</div></div>
        </div>
    )
}

const ArtistProfilePage = (props) => {
    const [artistName, setArtistName] = useState('ARTIST NAME');
    const [profileDescription, setProfileDescription] = useState('Lorem ipsum dolor sit amet.');
    const [artistProfilePicture, setArtistProfilePicture] = useState('');
    const [artworkObjects, setArtworkObjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [artworkProfileOpen, setArtworkProfileOpen] = useState(false);
    const [artworkProfileFocus, setArtworkProfileFocus] = useState(null);

    const { artistId } = useParams();

    useEffect(() => {
        getArtistData(artistId).then(data => {
            const response = data.data;
            setArtistName(response.firstName + " " + response.lastName);
            setProfileDescription(response.artistBio);
            setArtistProfilePicture(response.artistPictureURL);
            setArtworkObjects(response.artArray);
            setLoading(false);
        });
    }, []);

    return (
        
        <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
            <DarkerNavBar/>
            <BackButton/>
            
            {loading && (
                <div className="d-flex justify-content-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {!loading && (
                <div>
                    <ArtistBio name={artistName} description={profileDescription} pfpFile={artistProfilePicture}/>
                    <hr/>
                    <ArtWorkDisplay artWorksArray={artworkObjects} artWorkGridOnClick={(artwork) => {
                                setArtworkProfileFocus(artwork);
                                setArtworkProfileOpen(true);
                            }
                        }
                    />
                    <Footer/>
                </div>
            )}

            <ArtWorkProfile isOpen={artworkProfileOpen} onClose={() => setArtworkProfileOpen(false)} artworkFocus={artworkProfileFocus}/>
        </div>
    );
}; 

export default ArtistProfilePage;
import React, { useState, useEffect, useRef  } from "react";
import Sidebar from '../components/SideBar';
import '../App.css';
import './productPage.css';
import Loading from "../components/loading";
import logoImage from '../images/Logo-Base-basic.png';
import { Table, Modal, Button} from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ENDPOINT_PATH } from '../config';
import { updateFramePrice, updateMatboardPrice, updateMediumPrice, updateGlassPrice, updateHangingPrice, 
        getPricePerFoot, getMediumPrices, getGlassPrices, getMatboardPrices } from "./updatePriceHelper";
import { addFrame,delFrameProduct } from "./productsHelper";

const AdminNavbar = () => {
  return (
    <div className="navWrapper">
      <div className="AdminNav">
        <div>
          <a href="/" className="NavLink">
            <div className="AdminLogo d-flex justify-content-end">
              <img src={logoImage} alt='logo' className="NavLogo" />
            </div>
            <div className="AdminLogoText font-2 ps-2 fontLogo">mycustomframe</div>
          </a>
        </div>
      </div>
    </div>
  );
};

const ProductPage = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState({ frames: [], matboards: [], glass: [], hanging: [] });
  const [imageSizeList, setImageSizeList] = useState([])
  const [pricing, setPricing] = useState({ matboards: {}, mediums: {}, glass: {}});
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const responses = await Promise.all([
          fetch(`${ENDPOINT_PATH}/api/frames`).then(res => res.json()),
          fetch(`${ENDPOINT_PATH}/api/mediums`).then(res => res.json()),
          fetch(`${ENDPOINT_PATH}/api/matboards`).then(res => res.json()),
          fetch(`${ENDPOINT_PATH}/api/hanging-styles`).then(res => res.json()),
          fetch(`${ENDPOINT_PATH}/api/glass-types`).then(res => res.json()),
        ]);
    
        const [framesData, mediumsData, matboardsData, hangingStylesData, glassTypesData] = responses;
        
        const framesWithPrices = await Promise.all(
          framesData.filter(frame => frame.frameName !== 'noSelectedFrame').map(async (frame) => {
            const priceData = await getPricePerFoot(frame.frameName); 
            return {
              productID: frame.frameName,
              price: priceData?.pricePerFoot ?? 0, 
              ...frame,
            };
          })
        );

        const hangingWithPrices = await Promise.all(
          hangingStylesData.map(async(hanging) => {
            const response = await fetch(ENDPOINT_PATH + `/api/hanging-price?hangingStyle=` + encodeURIComponent(hanging));
            const hangingPriceData = await response.json();        
            return {
              displayName: hanging,
              price: hangingPriceData
            }
          })
        );

        setProducts({
          frames: framesWithPrices,
          mediums: mediumsData.map(medium => ({
            displayName: medium.mediumName,
          })),
          matboards: matboardsData.map(matboard => ({
            productID: matboard.internalName,
            ...matboard,
          })),
          glass: glassTypesData.map(glassType => ({
            displayName: glassType,
          })),
          hanging: hangingWithPrices
        });

        setLoading(false);

      } catch (error) {
        setLoading(false);
        console.error('Error fetching products:', error);
      }
    };

    const fetchImageSizes = async () => 
      {
        try {
          const response = await fetch(`${ENDPOINT_PATH}/api/image-sizes`);
          const data = await response.json();
        
          const sortedSizes = data.sort((a, b) => {
            if (a.width !== b.width) {
              return a.width - b.width;
            }
            // If width is the same, compare height
            return a.height - b.height;
          });

          setImageSizeList(sortedSizes);

        } catch (error){
          console.error('Error fetching products price:', error);
        }
      };

    const fetchProductPrices = async () =>
      {
        const matboardsPriceData = await getMatboardPrices();
        const mediumsPriceData = await getMediumPrices();
        const glassPriceData = await getGlassPrices();
        
        setPricing({
        matboards: matboardsPriceData.prices,
        mediums: mediumsPriceData.prices,
        glass: glassPriceData.prices
        })
      }

    fetchProducts();
    fetchImageSizes();
    fetchProductPrices();
  }, []); 

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeCategory, setActiveCategory] = useState('frames');
  
  const itemsPerPage = 10;

  // Modal states
  const [showProductModal, setShowProductModal] = useState(false);
  const [showMatboardPrices, setShowMatboardPrices] = useState(false);
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [priceChanges, setPriceChanges] = useState({});
  const inputRef = useRef({});
  

  // Product categories
  const categories = ['frames', 'matboards', 'mediums', 'glass', 'hanging'];

const filteredProducts = products[activeCategory]?.filter(product =>
    product?.displayName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product?.productID?.toLowerCase().includes(searchQuery.toLowerCase())
) || [];

  // Calculate total pages
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  // Paginate filtered products
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const [expandedRow, setExpandedRow] = useState(null);
  const toggleSizeChart = (productName) => {
    if (!isEditingPrice) {
      setExpandedRow(expandedRow === productName ? null : productName); // Toggle row expansion
    }
  };

  const handleEditPricing = () =>
    { 
      setIsEditingPrice(true)
      setPriceChanges({});
    };

    const handlePriceChange = (e, size) => {
      const newPrice = e.target.value;

        if (inputRef.current[size]) {
          inputRef.current[size].value = newPrice;
        }
      
    };

    const handleBlur = (product, size) => {
      let newPrice = parseFloat(inputRef.current[size].value); // Get the raw value from the ref
      
      if (isNaN(newPrice) || newPrice < 0) {
        newPrice = 0; 
      }

     // Update with parsed value
      if (inputRef.current[size]) {
        inputRef.current[size].value = newPrice.toFixed(2); 
      }
    
      // Track changes
      if (!priceChanges[product]) {
        priceChanges[product] = {}; 
      }
      priceChanges[product][size] = parseFloat(newPrice.toFixed(2));
    };
    

    const handleSave = (product) => {
      if (pricing[activeCategory] && pricing[activeCategory][product] && Object.entries(priceChanges).length > 0) {
        setPricing((prevPricing) => {
          const updatedState = { ...prevPricing };
          updatedState[activeCategory][product] = {
            ...updatedState[activeCategory][product], // Retain existing pricing details for the product
            ...priceChanges[product], // Apply price changes
          };
          return updatedState;
        });
      }

      // Update price in database
      if (Object.entries(priceChanges).length > 0) {
      
        Object.entries(priceChanges[product]).forEach(([frameSize, price]) => {
          
          switch (activeCategory) {
            case 'matboards':
              updateMatboardPrice(frameSize, product, price);
              break;
            case 'mediums':
              updateMediumPrice(product, frameSize, price);
              break;
            case 'glass':
              updateGlassPrice(frameSize, product, price);
              break;
            default:
              console.log("Unknown category");
              break;
          }
        });
      }
    
      setIsEditingPrice(false);
      inputRef.current = {}; // Reset the input reference
      setPriceChanges({}); // Clear any stored price changes
    };
    
    const handleCancel = () => {
      setIsEditingPrice(false);
    };


  const handleDeleteProduct = (product) => {
    if (window.confirm(`Are you sure you want to delete ${product.productID}?`)) {
      delFrameProduct(product.productID);
      setProducts((prevProducts) => {
        const updatedCategory = prevProducts[activeCategory].filter(
          (p) => p.productID !== product.productID
        );
        return { ...prevProducts, [activeCategory]: updatedCategory };
      });
    }
  };

  const handleImageUpload = (e, setImageState) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageState(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  
  const ProductModal = ({ 
      handleImageUpload, 
      onProductSaved 
    }) => {
      // Available frame sizes
      const FRAME_SIZES = imageSizeList.map(size => size.string);
  
      // Product State
      const [productName, setProductName] = useState('');
      const [productID, setProductID] = useState('');
      const [uploadedImage, setUploadedImage] = useState(null);
      const [multipleFiles, setMultipleFiles] = useState({});
  
      // Frame-related States
      const [frameType, setFrameType] = useState('standard');
      const [frameThicknesses, setFrameThicknesses] = useState([]);
      const [pricePerFoot, setPricePerFoot] = useState('');
  
      // Validation State
      const [errors, setErrors] = useState({});
      const [isLoading, setIsLoading] = useState(false);
  
      // Thickness options
      const thicknessOptions = ['1','1.25', '1.5', '2'];
  
      // Convert base64 to File object
      const base64ToFile = (base64Str, filename) => {
        try {
          const arr = base64Str.split(',');
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          
          return new File([u8arr], filename, { type: mime });
        } catch (error) {
          console.error('Error converting base64 to file:', error);
          return null;
        }
      };
  
      
      // Reset form method
        const resetForm = () => {
          setProductName('');
          setProductID('');
          setUploadedImage(null);
          setMultipleFiles({});
          setFrameType('standard');
          setFrameThicknesses([]);
          setPricePerFoot('');
          setErrors({});
  
        // Initialize multipleFiles with null for all sizes
        const initialFiles = {};
        FRAME_SIZES.forEach(size => {
          initialFiles[size] = null;
        });
        setMultipleFiles(initialFiles);
      };

      const onClose = () => {
        resetForm(); // Reset form fields
        setShowProductModal(false); // Close modal
      };
  
      // Validate form
      const validateForm = () => {
        const newErrors = {};
  
        if (!productName) newErrors.productName = 'Product name is required';
        if (!productID) newErrors.productID = 'Product ID is required';
        if (!uploadedImage) newErrors.image = 'Product image is required';
        if (frameThicknesses.length === 0) newErrors.frameThicknesses = 'Select at least one thickness';

        // Validate price
        const parsedPrice = parseFloat(pricePerFoot);
        if (isNaN(parsedPrice) || parsedPrice <= 0) {
          newErrors.pricePerFoot = 'Price per foot must be a valid positive number';
        }
        else {
        setPricePerFoot(parsedPrice); 
        }
  
        // Validate thickness-size file uploads
        FRAME_SIZES.forEach(size => {
          frameThicknesses.forEach(thickness => {
            const key = `${thickness}-${size}`;
            if (!multipleFiles[key]) {
              newErrors.thicknessFiles = `Please upload files for all size-thickness combinations`;
            }
          });
        });
  
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
  
      // Handle file upload for sizes
      const handleFileUpload = (e, size, thickness) => {
        const file = e.target.files[0];
        setMultipleFiles(prev => ({
          ...prev,
          [`${thickness}-${size}`]: file
        }));
      };
  
      // Handle thickness selection
      const handleThicknessChange = (thickness) => {
        setFrameThicknesses(prev => 
          prev.includes(thickness)
            ? prev.filter(t => t !== thickness)
            : [...prev, thickness]
        );
      };
  
      // Save product
      const handleSave = async (e) => {
        e.preventDefault();

        // Validate form
        if (!validateForm()) {
          console.log("Error Validating Form")
          return;
        }
  
        setIsLoading(true);
  
        try {
          const availableThickness = frameThicknesses.map(thickness => Number(thickness))
          
          // Prepare product data
          const productData = {
            productID: productID,
            displayName: productName,
            iconUrl: uploadedImage,
            frameType: frameType,
            frameThicknessAvailable: availableThickness,
            price: parseFloat(pricePerFoot)
          };
  
          // Convert base64 image to File if needed
          let visToolIcon = null;
          if (uploadedImage && uploadedImage.startsWith('data:')) {
            visToolIcon = base64ToFile(uploadedImage, `${productID}_icon.png`);
          }

          // Add frame using the existing addFrame function
          await addFrame(
            productID,               // frameName
            productName,             // frameDisplayName
            pricePerFoot,            // pricePerFoot
            frameType,               // frameType
            visToolIcon,             // visToolIcon
            availableThickness,      // frameThicknessAvailable
            multipleFiles            // frameFiles
          );
  
          // Notify parent component
          onProductSaved(productData);
          
          // Close modal
          onClose();
        } catch (error) {
          console.error('Error saving product:', error);
          alert('Failed to save product. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };
  
      return (
        <Modal show={true} onHide={onClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>{'New Product'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {/* Full-width Product Form */}
              <div className="col-12">
                <form onSubmit={handleSave}>
                  {/* Product Name */}
                  <div className="form-group mb-3">
                    <label>Product Name <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.productName ? 'is-invalid' : ''}`}
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                    {errors.productName && <div className="invalid-feedback">{errors.productName}</div>}
                  </div>
  
                  {/* Product ID */}
                  <div className="form-group mb-3">
                    <label>Product ID <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.productID ? 'is-invalid' : ''}`}
                      value={productID}
                      onChange={(e) => setProductID(e.target.value)}
                    />
                    {errors.productID && <div className="invalid-feedback">{errors.productID}</div>}
                  </div>

                  {/* Frame Type */}
                  <div className="form-group mb-3">
                    <label>Frame Type</label>
                    <select
                      className="form-control"
                      value={frameType}
                      onChange={(e) => setFrameType(e.target.value)}
                    >
                      <option value="standard">Standard</option>
                      <option value="floater">Floater</option>
                    </select>
                  </div>
  
                  {/* Frame Thickness */}
                  <div className="form-group mb-3">
                    <label>Frame Thickness <span className="text-danger">*</span></label>
                    <div>
                      {thicknessOptions.map((thickness) => (
                        <div key={thickness} className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`thickness-${thickness}`}
                            value={thickness}
                            checked={frameThicknesses.includes(thickness)}
                            onChange={() => handleThicknessChange(thickness)}
                          />
                          <label 
                            className="form-check-label" 
                            htmlFor={`thickness-${thickness}`}
                          >
                            {thickness} mm
                          </label>
                        </div>
                      ))}
                      {errors.frameThicknesses && <div className="text-danger">{errors.frameThicknesses}</div>}
                    </div>
                  </div>

                  {/*Vis Tool Icon */}
                  <div className="form-group mb-3">
                    <label>Vis Tool Icon</label>
                    <input 
                      type="file" 
                      accept="image/*"
                      className="form-control form-control-sm" 
                      onChange={(e) => handleImageUpload(e, setUploadedImage)}
                    />
                  </div>
  
                  {/* Thickness-Size File Uploads */}
                  {frameThicknesses.length > 0 && (
                    <div className="form-group mb-3">
                      <label>Size-Thickness Image Uploads<span className="text-danger">*</span></label>
                      {FRAME_SIZES.map(size => (
                        <div key={size} className="mb-3">
                        <h6><strong style={{ color: '#ff4172' }}>{size} Size</strong>
                        </h6>       
                          {frameThicknesses.map(thickness => (
                            <div 
                              key={`${thickness}-${size}`} 
                              className="d-flex align-items-center mb-2"
                            >
                              <span className="m-2">{thickness} mm:</span>
                              <input 
                                type="file" 
                                className="form-control form-control-sm" 
                                onChange={(e) => handleFileUpload(e, size, thickness)}
                              />
                              {multipleFiles[`${size}-${thickness}`] && (
                                <small className="ms-2">
                                  {multipleFiles[`${thickness}-${size}`].length} file(s) selected
                                </small>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                      {errors.thicknessFiles && <div className="text-danger">{errors.thicknessFiles}</div>}
                    </div>
                  )}
  
                  {/* Price per Foot */}
                  <div className="form-group mb-3">
                    <label>Price per Foot <span className="text-danger">*</span></label>
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <input
                        type="number"
                        className={`form-control ${errors.pricePerFoot ? 'is-invalid' : ''}`}
                        placeholder="0.00"
                        value={pricePerFoot}
                        onChange={(e) => setPricePerFoot(e.target.value)}
                        step="0.01"
                      />
                      {errors.pricePerFoot && <div className="invalid-feedback">{errors.pricePerFoot}</div>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button 
              variant="primary" 
              onClick={handleSave} 
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save Product'}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };
  
  const FramesTable = () => {
    const [isEditing, setIsEditing] = useState(null);
    const [priceChange, setPriceChange] = useState({});

      const handlePriceChange = (productID, newPrice) => {
        const parsedPrice = parseFloat(newPrice);
        if (parsedPrice >= 0) {
          setPriceChange({ [productID]: parsedPrice}); 
        }
      };
      const handleSave = (productID) =>
        {
          setProducts((prevProducts) => {
            const updatedFrames = prevProducts.frames.map((product) => {
              if (product.productID === productID) {
                return { ...product, price: priceChange[productID] };
              }
              return product; 
            });

          return { ...prevProducts, frames: updatedFrames };
        });

          // Update in database 
          updateFramePrice(productID, priceChange[productID]);

          setIsEditing(null);
          setPriceChange({}); 
        }

      const handleCancel = () =>
        {
          setPriceChange({});
          setIsEditing(null);
        }

    return (
      <Table striped bordered hover className="product-table" >
        <thead>
          <tr>
            <th style={{ width: "5%" }}>PREVIEW</th>
            <th style={{ width: "10%" }}>PRODUCT ID</th>
            <th style={{ width: "15%" }}>PRODUCT NAME</th> 
            <th style={{ width: "15%" }}>FRAME TYPE</th>
            <th style={{ width: "15%" }}>THICKNESS</th>
            <th style={{ width: "15%" }}>PRICE PER FOOT</th>
            <th style={{ width: "10%" }}>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {paginatedProducts.map((product) => (
            <tr key={product.productID}>
              <td>
                <img 
                  src={product.iconUrl} 
                  alt={product.displayName} 
                  className="product-image" 
                  loading="lazy"
                  style={{ width: '50px', height: '50px', objectFit: 'cover', 
                  border: '1px solid #ddd',backgroundColor: 'white',  }}
                />
              </td>
              <td>{product.productID}</td>
              <td>{product.displayName}</td>
              <td>{product.frameType}</td>
              <td>{product.frameThicknessAvailable.join(', ')}</td>
              {isEditing === product.productID ? (
                  <td key={product.productID}>
                    <input
                    type="number" 
                    className="price-input"
                    value= {priceChange[product.productID] ?? product.price}
                    onChange={(e) => handlePriceChange(product.productID,e.target.value)}
                    />
                  </td>
                ) :
                (<td>${(product.price)}</td>)}
              <td>
              <div className="d-flex justify-content-center align-items-center gap-2">
                    {isEditing === product.productID ? (
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <Button variant="primary" size="sm" onClick={() => handleSave(product.productID)}>Save</Button>
                        <Button variant="secondary" size="sm" onClick={() => handleCancel()}>Cancel</Button>
                      </div>
                    ) : (
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <button className="icon-button" onClick={() => {
                          setIsEditing(product.productID); 
                          setPriceChange({ [product.productID]: product.price }); 
                        }}>
                        <EditIcon style={{ color: 'black' }} />
                      </button>
                      <button className="icon-button" onClick={() => handleDeleteProduct(product)}>
                        <DeleteIcon className="delete-icon" />
                      </button>
                  </div>)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
    </Table>
    );
  };

  const MatboardTable = () => {
    return (
      <Table striped bordered hover className="product-table" >
        <thead>
          <tr>
            <th style={{ width: "1%" }}>PREVIEW</th>
            <th style={{ width: "1%" }}>PRODUCT ID</th>
            <th style={{ width: "5%" }}>PRODUCT NAME</th> 
            <th style={{ width: "5%" }}>MATBOARD STYLE</th>
          </tr>
        </thead>
        <tbody>
          {paginatedProducts.map((product) => (
            <tr key={product.productID}>
              <td>
              <div className="d-flex justify-content-center align-items-center gap-2"
                style={{
                  width: 50,
                  height: 50,
                  backgroundColor: product.hexColor, // Use the hex color for the square
                  border: "1px solid #000",
                  borderRadius: 4,
                  marginLeft: '40px'
                }}/>
              </td>
              <td>{product.productID}</td>
              <td>{product.displayName}</td>
              <td>{product.style}</td>
            </tr>
          ))}
        </tbody>
    </Table>
    );
  };

  const ProductbySizeTable = ({products = paginatedProducts}) => {
    return (
      <table className="by-size-table" >
        <thead>
          <tr>
            <th style={{ width: "1%" }}></th>
            <th style={{ width: "5%" }}>PRODUCT NAME</th> 
            <th style={{ width: "5%",textAlign: "right" }}>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <React.Fragment key={product.displayName}>
              {/* Main Row */}
              <tr className={expandedRow === product.displayName ? "expanded-row" : ""}>
                <td className="expand-arrow" onClick={() => {
                    if (!isEditingPrice) {toggleSizeChart(product.displayName);
                    }}}>
                  <span className="expand-icon">{expandedRow === product.displayName ? "▼" : "▶"}</span>
                </td>
                <td>{product.displayName}</td>
                <td>
                  <div className="d-flex justify-content-end gap-2" style={{marginRight: "30px"}}>
                  <button
                    className="icon-button"
                    onClick={() => {
                      if (expandedRow !== product.displayName) {
                        setExpandedRow(product.displayName);
                      }
                      handleEditPricing();  
                    }}
                  >
                    <EditIcon style={{ color: "black" }} />
                  </button>
                  </div>
                </td>
              </tr>

              {/* Expanded Row with Size Chart */}
              {expandedRow === product.displayName && (
                <tr className="dropdown-content">
                  <td colSpan="3">
                    <table className={`size-chart ${isEditingPrice ? "small" : "large"}`}>
                    <thead>
                        <tr>
                          {imageSizeList.map((size) => (
                            <th key={size.string}>{size.width} x {size.height}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                      {imageSizeList.map((size) => (
                          isEditingPrice ? (
                            <td key={size.string}>
                              <input 
                                type="text" 
                                className="price-input"
                                defaultValue={(pricing[activeCategory][product.displayName]?.[size.string] ?? 0).toFixed(2)}
                                onChange={(e) => handlePriceChange(e,size.string)}
                                onBlur={(e) => handleBlur(product.displayName, size.string)}
                                  ref={(el) => {
                                    if (el) {
                                      inputRef.current[size.string] = el; // Store reference uniquely
                                    } 
                                  }}
                              />
                            </td>
                          ) : (
                            <td key={size.string}>
                              ${(pricing[activeCategory][product.displayName]?.[size.string] || 0).toFixed(2)}
                            </td>
                          )
                        ))}
                        </tr>
                      </tbody>
                    </table>
                    {isEditingPrice && (
                    <div className="d-flex justify-content-center gap-2 mb-3">
                      <Button variant="primary" size="sm" onClick={() => handleSave(product.displayName)}>Save</Button>
                      <Button variant="secondary" size="sm" onClick={handleCancel}>Cancel</Button>
                    </div>
                  )}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
    </table>
    );
  };

  const HangingTable = () =>
    {
      const [isEditing, setIsEditing] = useState(null);
      const [priceChange, setPriceChange] = useState({});

      const handlePriceChange = (productName, newPrice) => {
        const parsedPrice = parseFloat(newPrice);
        if (parsedPrice >= 0) {
          setPriceChange({ [productName]: parsedPrice}); 
        }
      };
      const handleSave = (productName) =>
        {
          setProducts((prevProducts) => {
            const updatedHanging = prevProducts.hanging.map((product) => {
              if (product.displayName === productName) {
                return { ...product, price: priceChange[productName] };
              }
              return product; 
            });

          return { ...prevProducts, hanging: updatedHanging };
        });

          // Update in database 
          updateHangingPrice(productName, priceChange[productName]);

          setIsEditing(null);
          setPriceChange({}); 
        }

      const handleCancel = () =>
        {
          setPriceChange({});
          setIsEditing(null);
        }
      return (
        <Table striped bordered hover className="product-table hanging" >
          <thead>
            <tr>
            
              <th style={{ width: "5%" }}>PRODUCT NAME</th> 
              <th style={{ width: "5%" }}>PRICE</th>
              <th style={{ width: "5%" }}>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {paginatedProducts.map((product) => (
              <tr key={product.displayName}>
                <td>{product.displayName}</td>
                {isEditing === product.displayName ? (
                  <td key={product.displayName}>
                    <input
                    type="number" 
                    className="price-input"
                    value= {priceChange[product.displayName] ?? product.price}
                    onChange={(e) => handlePriceChange(product.displayName,e.target.value)}
                    />
                  </td>
                ) :
                (<td>${(product.price).toFixed(2)}</td>)}
                <td>
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    {isEditing === product.displayName ? (
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <Button variant="primary" size="sm" onClick={() => handleSave(product.displayName)}>Save</Button>
                        <Button variant="secondary" size="sm" onClick={() => handleCancel()}>Cancel</Button>
                      </div>
                    ) : (
                    <button className="icon-button" onClick={() => {
                        setIsEditing(product.displayName); 
                        setPriceChange({ [product.displayName]: product.price }); 
                      }}>
                      <EditIcon style={{ color: 'black' }} />
                    </button>)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
      </Table>
      );
    };


  const ProductTable = ({handleDeleteProduct}) =>
    {
      // Matboard Style Display Names for pricing
      const matboardStyle = Object.keys(pricing['matboards']).map(key => ({
        displayName: key
      }));

      if (loading) {
        return <Loading/>;
      }
      else {
          if (activeCategory === 'frames') {
            return <FramesTable handleDeleteProduct={handleDeleteProduct} />;
          }
          if (activeCategory === 'matboards' && showMatboardPrices === false) {
            return <MatboardTable />;
          }
          else if (activeCategory === 'matboards' && showMatboardPrices === true)
            {
              return <ProductbySizeTable products={matboardStyle}/>
            }
          if (activeCategory === 'mediums' || activeCategory === 'glass') {
            return <ProductbySizeTable />;
          }
          if (activeCategory === 'hanging')
            {
              return <HangingTable/>
            }
          return null;
      }
    };

  const handleProductSaved = (productData) => {
    setProducts((prevProducts) => {
      const updatedCategory = [productData, ...prevProducts[activeCategory]];
      return { ...prevProducts, [activeCategory]: updatedCategory };
    });
  };

  return (
    <div>
      <AdminNavbar />
      <hr className="admin-divider" />
      <div className="product-page">
        <Sidebar />
        <div className="product-content">
          <div className="header">
            <div className="category-boxes">
              {categories.map((category) => (
                <div 
                  key={category}
                  className={`category-box ${activeCategory === category ? 'active' : ''}`}
                  onClick={() => {
                    setActiveCategory(category);
                    setShowMatboardPrices(false);
                    setIsEditingPrice(false);
                    setCurrentPage(1);
                  }}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </div>
              ))}
            </div>

            {(activeCategory === 'frames' || activeCategory === 'matboards') && (
            <div className="header-btns"> 
              <input
                type="text"
                className="search-box"
                placeholder="Search by name or ID..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
              
              {activeCategory === 'frames' && (
              <button className="btn btn-primary"
                onClick={() => {
                  setShowProductModal(true);
                }}
                > Add New Product </button>)}

              {activeCategory === 'matboards' && showMatboardPrices === false ? (
                <button className="btn btn-primary"
                  onClick={() => setShowMatboardPrices(true)}>
                  Edit Price List
                </button>
              ) : activeCategory === 'matboards' && showMatboardPrices === true ? (
                <button className="btn btn-primary"
                  onClick={() => setShowMatboardPrices(false)}>
                  Back to Matboards
                </button>
              ) : null}
              </div>)}
          </div>

          <div className="product-table-container">
          <ProductTable 
            handleDeleteProduct={handleDeleteProduct} 
          />
          </div>

          {/* Pagination Controls */}
          {totalPages > 1 && !showMatboardPrices && (
            <div className="pagination">
              <button
                className="page-btn"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <span>
                {`Showing ${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
                  currentPage * itemsPerPage,
                  filteredProducts.length
                )} of ${filteredProducts.length}`}
              </span>
              <button
                className="page-btn"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Modal for Product */}
      {showProductModal && (
        <ProductModal 
          handleImageUpload={handleImageUpload}
          onProductSaved={handleProductSaved}
        />
      )}
    </div>
  );
};

export default ProductPage;
import React from 'react';

// Components
import ArtGallery from '../components/homeArtistGal';
import Footer from '../components/Footer';

// Images
import productsImage from '../images/frame-home.jpg';


const Home = (props) => {

  return (
    <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
      <div className="d-flex align-items-center HomeHeaderImage SplashHeader vstack gap-3">
        <div className="splashFont text-shadow">build</div>
        <div className="text-center splashFontSub text-shadow">a unique frame for any occasion</div>
        <div className="d-flex justify-content-center pt-3">
          <a href="/visualizationTool">
            <button className="btn btn-outline-light btn-lg button-padding clear-button">
              <div className="font-3 text-shadow">start building</div>
            </button>
          </a>
        </div>
      </div>


      <div className="wrapper">
        <div className="pt-5 sectionTitle-Light text-center">what we do</div>
        <div className="pt-4 sectionFont-Light text-center">MyCustomFrame is a family owned company located in Oviedo, FL. Our mission is to help our customers
        preserve their memories and display them in a unique and beautiful way using picture frames. From conservative framing, to shadow boxes, custom printed
        canvas, portraits and more, our team of professional framers are ready to assist you personalize your living space.
        </div>
      </div>

      <div className='section-Dark'>
        <div className="row">
          <div className="col-6 ">
            <div className="artistHomeImage head-text">
              <a href="/visualizationTool">
                <img src={productsImage} alt="Products"/>
              </a>
              <div className="text-on-image">
                <div className='sectionTitle-Dark row text-shadow'>browse our frames</div>
                <div className='sectionFont-Dark row text-shadow'>create a beautiful custom frame with our endless options</div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* <ArtGallery/> */}

      <div className="wrapper">
        <div className="d-flex justify-content-center pt-5 sectionTitle-Light text-center">want unique frames?</div>
        <div className="d-flex justify-content-center pt-3 sectionFont-Light text-center">You can contact us about more specific frames if you can't find the 
        right size for you here. </div>
        <div className="d-flex justify-content-center sectionFont-Light pt-5">
          <a href="/order-form">
            <button className="d-flex btn btn-outline-dark btn-lg">Custom Orders</button>
          </a>
        </div>
      </div>

      <Footer />

    </div>
  );
};

export default Home;

import { ENDPOINT_PATH } from "../config";

// Helper function to call mediums report
/**
 * @param {String} productID
 * @param {string} startDate // yyyy-mm-dd
 * @param {string} endDate // yyyy-mm-dd
 */
const getMediumReport = async (productID, startDate, endDate) => {
    return fetch(ENDPOINT_PATH + `/api/get-mediums-report?productID=${encodeURIComponent(productID)}&startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Mediums metrics: ", data);
        return data;
    })
    .catch(e => { console.error(e); });        
}

export { getMediumReport };

// Helper function to call glass report
/**
 * @param {String} productID
 * @param {string} startDate // yyyy-mm-dd
 * @param {string} endDate // yyyy-mm-dd
 */
const getGlassReport = async (productID, startDate, endDate) => {
    return fetch(ENDPOINT_PATH + `/api/get-glass-report?productID=${encodeURIComponent(productID)}&startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Glass metrics: ", data);
        return data;
    })
    .catch(e => { console.error(e); });        
}

export { getGlassReport };


// Helper function to call hanging report
/**
 * @param {String} productID
 * @param {string} startDate // yyyy-mm-dd
 * @param {string} endDate // yyyy-mm-dd
 */
const getHangingReport = async (productID, startDate, endDate) => {
    return fetch(ENDPOINT_PATH + `/api/get-hanging-report?productID=${encodeURIComponent(productID)}&startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Hanging metrics: ", data);
        return data;
    })
    .catch(e => { console.error(e); });        
}

export { getHangingReport };


// Helper function to call matboard report
/**
 * @param {String} productID
 * @param {string} startDate // yyyy-mm-dd
 * @param {string} endDate // yyyy-mm-dd
 */
const getMatboardReport = async (productID, startDate, endDate) => {
    return fetch(ENDPOINT_PATH + `/api/get-matboard-report?productID=${encodeURIComponent(productID)}&startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        console.log("Matboard metrics: ", data);
        return data;
    })
    .catch(e => { console.error(e); });        
}

export { getMatboardReport };

// Helper function to call matboard report
/**
 * @param {String} artistID
 * @param {string} startDate // yyyy-mm-dd
 * @param {string} endDate // yyyy-mm-dd
 */
const getArtReport = async (artistID, startDate, endDate) => {
    if (startDate && endDate) {
        return fetch(ENDPOINT_PATH + `/api/get-art-report?artistID=${artistID}&startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data => { 
            console.log("Art metrics: ", data);
            return data;
        })
        .catch(e => { console.error(e); });  
    } else {
        return fetch(ENDPOINT_PATH + `/api/get-art-report?artistID=${artistID}`, {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data => { 
            console.log("Art metrics: ", data);
            return data;
        })
        .catch(e => { console.error(e); }); 
    }
}

export { getArtReport };



// Helper function to call frames report
/**
 * @param {String} productID
 * @param {Number} thickness
 * @param {string} startDate // yyyy-mm-dd
 * @param {string} endDate // yyyy-mm-dd
 */
const getFrameReport = async (productID, thickness, startDate, endDate) => {
    if (thickness != null) { // Call API with thickness
         return fetch(ENDPOINT_PATH + `/api/get-frame-report?productID=${encodeURIComponent(productID)}&thickness=${thickness}&startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data => { 
            console.log("Frame metrics: ", data);
            return data;
        })
        .catch(e => { console.error(e); }); 
    } else { // Call API without thickness
        return fetch(ENDPOINT_PATH + `/api/get-frame-report?productID=${encodeURIComponent(productID)}&startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data => { 
            console.log("Frame metrics: ", data);
            return data;
        })
        .catch(e => { console.error(e); }); 
    }
    
}

export { getFrameReport };

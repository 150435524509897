import { ENDPOINT_PATH } from "../config";

// Helper function to update medium price
/**
 * @param {String} medium // String for the type of medium
 * @param {string} imageSize String for the image size 'x_y'
 * @param {Number} newPrice // Integer for the new price
 */
const updateMediumPrice = async (medium, imageSize, newPrice) => {
    fetch(ENDPOINT_PATH + `/api/update-medium-price?medium=${medium}&imageSize=${imageSize}&newPrice=${newPrice}`, {
        method: 'PATCH',
    })
    .then(response => response.json())
    .then(data => { 
        console.log(data);
    })
    .catch(e => { console.error(e); });
}
export { updateMediumPrice };


// Helper function to update matboard price
/**
 * @param {String} frameSize // String for the frame size 'x_y'
 * @param {string} matboardStyle // String for the type of matboard
 * @param {Number} newPrice // Integer for the new price
 */
const updateMatboardPrice = async (frameSize, matboardStyle, newPrice) => {
    fetch(ENDPOINT_PATH + `/api/update-matboard-price?frameSize=${frameSize}&matboardStyle=${matboardStyle}&newPrice=${newPrice}`, {
        method: 'PATCH',
    })
    .then(response => response.json())
    .then(data => { 
        console.log(data);
    })
    .catch(e => { console.error(e); });
}
export { updateMatboardPrice };


// Helper function to update matboard price
/**
 * @param {String} frameSize // string for the frame size 'x_y'
 * @param {string} glassType // string for the type of glass
 * @param {Number} newPrice // Integer for the new price
 */
const updateGlassPrice = async (frameSize, glassType, newPrice) => {
    fetch(ENDPOINT_PATH + `/api/update-glass-price?frameSize=${frameSize}&glassType=${glassType}&newPrice=${newPrice}`, {
        method: 'PATCH',
    })
    .then(response => response.json())
    .then(data => { 
        console.log(data);
    })
    .catch(e => { console.error(e); });
}
export { updateGlassPrice };


// Helper function to update matboard price
/**
 * @param {string} hangingStyle // string for the type of hanger
 * @param {Number} newPrice // Integer for the new price
 */
const updateHangingPrice = async (hangingStyle, newPrice) => {
    fetch(ENDPOINT_PATH + `/api/update-hanging-price?hangingStyle=${hangingStyle}&newPrice=${newPrice}`, {
        method: 'PATCH',
    })
    .then(response => response.json())
    .then(data => { 
        console.log(data);
    })
    .catch(e => { console.error(e); });
}
export { updateHangingPrice };


// Helper function to update matboard price
/**
 * @param {string} frameName // string for the frame name
 * @param {Number} newPricePerFoot // Integer for the new price per foot
 */
const updateFramePrice = async (frameName, newPricePerFoot) => {
    fetch(ENDPOINT_PATH + `/api/update-frame-price?frameName=${frameName}&newPricePerFoot=${newPricePerFoot}`, {
        method: 'PATCH',
    })
    .then(response => response.json())
    .then(data => { 
        console.log(data);
    })
    .catch(e => { console.error(e); });
}
export { updateFramePrice };


// Helper function to update matboard price
/**
 * @param {string} frameName // string for the frame name
 */
const getPricePerFoot = async (frameName) => {
    return fetch(ENDPOINT_PATH + `/api/get-price-per-foot?frameName=${frameName}`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        //console.log(data);
        return data;
    })
    .catch(e => { console.error(e); });
}
export { getPricePerFoot };


// Helper function to get all medium prices
const getMediumPrices = async () => {
    return fetch(ENDPOINT_PATH + `/api/get-all-medium-prices`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        //console.log(data);
        return data;
    })
    .catch(e => { console.error(e); });
}
export { getMediumPrices };

// Helper function to get all matboard prices
const getMatboardPrices = async () => {
    return fetch(ENDPOINT_PATH + `/api/get-all-matboard-prices`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        //console.log(data);
        return data;
    })
    .catch(e => { console.error(e); });
}
export { getMatboardPrices };

// Helper function to get all glass prices
const getGlassPrices = async () => {
    return fetch(ENDPOINT_PATH + `/api/get-all-glass-prices`, {
        method: 'GET',
    })
    .then(response => response.json())
    .then(data => { 
        //console.log(data);
        return data;
    })
    .catch(e => { console.error(e); });
}
export { getGlassPrices };
import { ENDPOINT_PATH } from '../config';

const getAllUserInformation = async () => {
    return fetch(ENDPOINT_PATH + '/api/get-all-users')
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error status ${response.status}`);
            } else {
                return response.json();
            }
        })
        .then(users => {
            return users.map(doc => ({
                firstName: doc.firstName ? doc.firstName : 'N/A',
                lastName: doc.lastName ? doc.lastName : 'N/A',
                email: doc.email ? doc.email : 'N/A',
                type: doc.type ? doc.type : 'N/A',
            }))
        })
        .catch(error => {
            console.error("Error fetching all user information: ", error);
            return null;
        });
}

export { getAllUserInformation };